import { useState } from 'react'
import { axiosInstance } from '../services/webClient'
import { JSONTree } from 'react-json-tree'

function Admin() {
  const [data, setData] = useState([])

  async function fetchPendingTransfers() {
    await axiosInstance
      .get(`/test/userToEventsTransfersToExecute`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }, //TODO: MOOSE - DO THIS FOR ALL AXIOS CALLS
      })
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  async function fetchPendingPermitSignatures() {
    await axiosInstance
      .get(`/test/userToEventsSignatureToExecute`)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  async function fetchUserToEvents() {
    await axiosInstance
      .get(`/test/userToEvents`)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  async function permitTransfersJob() {
    await axiosInstance
      .post(`/test/backgroundjob/permittransfers`)
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  async function transferFundsJob() {
    await axiosInstance
      .post('/test/backgroundjob/transferfunds')
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const theme = {
    scheme: 'monokai',
    author: 'wimer hazenberg (http://www.monokai.nl)',
    base00: '#272822',
    base01: '#383830',
    base02: '#49483e',
    base03: '#75715e',
    base04: '#a59f85',
    base05: '#f8f8f2',
    base06: '#f5f4f1',
    base07: '#f9f8f5',
    base08: '#f92672',
    base09: '#fd971f',
    base0A: '#f4bf75',
    base0B: '#a6e22e',
    base0C: '#a1efe4',
    base0D: '#66d9ef',
    base0E: '#ae81ff',
    base0F: '#cc6633',
  }
  return (
    <>
      <h2>Jobs:</h2>
      <button style={{ margin: '7.5px' }} onClick={permitTransfersJob}>
        permit transfers
      </button>
      <button style={{ margin: '7.5px' }} onClick={transferFundsJob}>
        transfer funds
      </button>

      <h2>Data:</h2>
      <button
        style={{ margin: '7.5px' }}
        onClick={fetchPendingPermitSignatures}
      >
        Pending Permit Signatures
      </button>
      <button style={{ margin: '7.5px' }} onClick={fetchPendingTransfers}>
        Pending Transfers
      </button>
      <button style={{ margin: '7.5px' }} onClick={fetchUserToEvents}>
        Users + Event
      </button>
      <br />
      <JSONTree
        shouldExpandNodeInitially={() => false}
        data={data}
        theme={theme}
        labelRenderer={([key]) => <em style={{ textAlign: 'left' }}>{key}</em>}
        valueRenderer={(raw) => <em>{raw}</em>}
        expandActionRoot={true}
        hideRoot={true}
        getItemString={() => {}}
        style={{
          padding: '100px',
        }}
      />
    </>
  )
}
export default Admin
