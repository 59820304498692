import { BACKEND_URL } from '../utils/utils'

async function hasEventTipped(eventId) {
  if (eventId === null || eventId === undefined) return
  return await fetch(BACKEND_URL + '/event/' + eventId + '/hasEventTipped', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  })
    .then(async (res) => {
      const { hasEventTipped } = await res.json()
      return hasEventTipped
    })
    .catch((err) => {
      console.error(err)
    })
}

export async function hasEventPaidOut(event) {
  const allOptIns = event.userToEvents.filter(
    (ute) => ute.optedIn && ute.isInvitee
  )
  if (allOptIns.length === 0) return false
  const allOptInsHavePaidOut = allOptIns.every(
    (userToEvent) =>
      !!userToEvent.transferSignatureExecuted &&
      !!userToEvent.transferTransactionHash
  )
  return allOptInsHavePaidOut
}

export async function getPublicEvent(eventId) {
  return await fetch(BACKEND_URL + '/publicEvent/' + eventId, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(async (res) => {
    return await res.json()
  })
  .catch((err) => {
    console.log('getEvent err', { err })
      return err
    })
}

async function getEvent(eventId, currentEthAddress) {
  return await fetch(
    BACKEND_URL + '/event/' + eventId + '?ethAddress=' + currentEthAddress,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }
  )
    .then(async (res) => {
      return await res.json()
    })
    .catch((err) => {
      console.log('getEvent err', { err })
      return err
    })
}

function hasReachedMinimumCapacity(event) {
  const eventTypesWithNoMinimums = [
    'General Fundraising',
    'Specific Fundraising',
  ]
  if (eventTypesWithNoMinimums.includes(event.eventType)) {
    return true
  }

  if (!event.minimumPeople) return false
  if (!event.userToEvents || event.userToEvents.length === 0) return false
  const numOfOptIns = event.userToEvents.filter(
    (ute) => ute.isInvitee && ute.optedIn
  ).length
  return numOfOptIns >= event.minimumPeople
}

function hasReachedGoalAmount(event) {
  if (event.eventType === 'General Fundraising') {
    const numOfOptIns = event.userToEvents.filter(
      (ute) => ute.isInvitee && ute.optedIn
    ).length
    return numOfOptIns > 0
  }
  if (event.eventType === 'Dynamic') {
    return hasReachedMinimumCapacity(event)
  }
  if (!event.userToEvents || event.userToEvents.length === 0) return false

  const promisedContributions = event.userToEvents.reduce(
    (accumulator, userToEvent) => {
      if (userToEvent.isInvitee && userToEvent.optedIn) {
        return accumulator + userToEvent.currentContribution
      } else {
        return accumulator
      }
    },
    0
  )
  return promisedContributions >= event.goalAmount
}

function hasMetTippingPointRequirements(event) {
  // const isActive = event.active
  const hasMetMinimumPeople = hasReachedMinimumCapacity(event)
  const hasReachGoalAmount = hasReachedGoalAmount(event) 
  const statusTipped = event.status === 'Tipped'
  return (
    hasMetMinimumPeople && hasReachGoalAmount && statusTipped // && isPastDeadline
  )
}

export function getTotalAmountRaised(event) {
  const totalRaised = event?.userToEvents.reduce((accumulator, ute) => {
    if (ute?.isInvitee && ute?.optedIn && !!ute.transferAuthorizationSignature) {
      return accumulator + Number(ute.currentContribution)
    }
    return accumulator
  }, 0)
  return totalRaised
}

export async function cancelEvent(eventId, creatorEthAddress, _eventCallback) {
  await fetch(BACKEND_URL + '/event/cancelEvent', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: JSON.stringify({
      event: {
        id: eventId,
        creatorEthAddress: creatorEthAddress,
      },
    }),
  })
    .then(async function (response) {
      const { event } = await response.json()
      console.log('updateEvent response', { event, response })
      _eventCallback(event)
    })
    .catch(function (error) {
      console.log('updateEvent', error)
    })
}

export {
  hasEventTipped,
  getEvent,
  hasMetTippingPointRequirements,
  hasReachedGoalAmount,
  hasReachedMinimumCapacity,
}
