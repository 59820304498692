import React from 'react'
// import { OnrampElement } from '../StripeCryptoElements';
import { CryptoElements, OnrampElement } from '../StripeCryptoElements'
import { loadStripeOnramp } from '@stripe/crypto'
import { CircularProgress } from '@mui/material'
import { BACKEND_URL } from '../utils/utils'

const stripePublicKey =
  process.env.REACT_APP_ENVIRONMENT === 'production'
    ? 'pk_live_51PZJWkE0DBTl32eGDeMw6KPXUK0KtnpO3mwRwr2Ffkcitd0JAaRwlFnisysv0EjIcsY2w7lR2h0prjE1iGaazCRM00tHzDaJnx'
    : 'pk_test_51PZJWkE0DBTl32eG9b8WgnHJTv7FPQLiOUnsBXAx0M799QjdkpT1mkuoSjrkfqZJViMdrNRdQcHXNd8vKIk6tCWK00RKeLKIRW'
const stripeOnrampPromise = loadStripeOnramp(stripePublicKey)

const StripeOnramp = (props) => {
  const [clientSecret, setClientSecret] = React.useState(null)
  const { address } = props

  return (
    <div
      style={{
        width: '100%',
        height: '110%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {clientSecret ? (
        <CryptoWrapper clientSecret={clientSecret} />
      ) : (
        <button
          onClick={async () => {
            await fetch(BACKEND_URL + '/stripe?address=' + address, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              credentials: 'include',
            }).then(async function (response) {
              console.log('response3', response)
              let r = await response.json()
              console.log('r', r)
              setClientSecret(r.clientSecret)
              return r
            })
          }}
        >
          Purchase USDC(on Base)
        </button>
      )}
    </div>
  )
}

const CryptoWrapper = (props) => {
  const clientSecret = props.clientSecret
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 4000)

    return () => clearTimeout(timer)
  }, [])

  if (loading) {
    return (
      <CircularProgress
        size={30}
        sx={{
          color: 'rgb(193, 254, 175)',
          padding: 0,
        }}
      />
    )
  }

  return (
    <CryptoElements stripeOnramp={stripeOnrampPromise}>
      <OnrampElement clientSecret={clientSecret} />
    </CryptoElements>
  )
}

export default StripeOnramp
