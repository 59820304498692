import { Check } from "@mui/icons-material"
import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useWeb3Open, useWeb3Service} from "../services/web3Service"


export const ConnectWalletScreen = () => {

  const [step, setStep] = useState('connect')
  const [connectLoading, setConnectLoading] = useState(false)
  const [createLoading, setCreateLoading] = useState(false)
  const [authenticateLoading, setAuthenticateLoading] = useState(false)

  const { open, authenticate, disconnect, status } = useWeb3Service()

  useEffect(() => {
    if (status === 'connected') {
      setStep('authenticate')
      setConnectLoading(false)
      setCreateLoading(false)
    }
    if (status === 'authenticated') {
      setStep('done')
      setAuthenticateLoading(false)
    }
  }, [status])

  useEffect(() => {
    console.log('step', step)
  }, [step])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
          position: 'relative',
          width: '100px'
        }}
      >
        <div
          style={{
            position: 'absolute',
            height: '40px',
            width: '40px',
            backgroundColor: '#c1feaf',
            borderRadius: '50%',
            left: '50%',
            top: '25%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {step === 'connect' ? (
            <div
              style={{
                color: '#2e2e2e',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              1
            </div>
          ) : (
            <Check
              sx={{
                color: '#2e2e2e',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2,
              }}
            />
          )}
        </div>
        <div
          style={{
            width: '10px',
            height: '22%',
            position: 'absolute',
            left: '50%',
            top: '39%',
            backgroundColor: '#c1feaf',
            transform: 'translate(-50%, -50%)',
            zIndex: 1,
          }}
        />
        <div
          style={{
            width: '10px',
            height: '22%',
            position: 'absolute',
            left: '50%',
            top: '61%',
            backgroundColor: step !== 'connect' ? '#c1feaf' : '#a2a3a9',
            transform: 'translate(-50%, -50%)',
          }}
        />
        <div
          style={{
            position: 'absolute',
            height: '40px',
            width: '40px',
            backgroundColor: step !== 'connect' ? '#c1feaf' : '#a2a3a9',
            borderRadius: '50%',
            left: '50%',
            top: '75%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          {step !== 'done' ? (
            <div
              style={{
                color: step !== 'connect' ? '#2e2e2e' : '#f1f1f1',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              2
            </div>
          ) : (
            <Check
              sx={{
                color: step !== 'connect' ? '#2e2e2e' : '#f1f1f1',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '500px',
          position: 'relative',
          width: '75%',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
            left: '50%',
            top: '25%',
            width: '100%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: step === 'connect' ? '#f1f1f1' : '#a2a3a9',
              marginBottom: '10px',
            }}
          >
            Connect Your Wallet
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                width: '20%',
                backgroundColor: step === 'connect' ? '#c1feaf' : '#a2a3a9',
                borderRadius: '8px',
                fontSize: '18px',
                color: '#2e2e2e',
                padding: '10px',
                margin: '10px',
                cursor: step === 'connect' ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (step !== 'connect') return
                setConnectLoading(true)
                open({ view: 'Connect' })
              }}
            >
              {
                connectLoading ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                  />
                ) : 'Connect'
              }
            </div>
            <div
              style={{
                width: '20%',
                backgroundColor: step === 'connect' ? '#c1feaf' : '#a2a3a9',
                borderRadius: '8px',
                fontSize: '18px',
                color: '#2e2e2e',
                padding: '10px',
                margin: '10px',
                cursor: step === 'connect' ? 'pointer' : 'default',
              }}
              onClick={() => {
                disconnect()
              }}
            >
              {
                createLoading ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                  />
                ) : 'Create Wallet'
              }
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            height: '50%',
            left: '50%',
            top: '75%',
            width: '100%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              color: step !== 'connect' ? '#f1f1f1' : '#a2a3a9',
              marginBottom: '10px',
              width: '100%',
            }}
          >
            Authenticate Your Log-In
          </div>
          
            <div
              style={{
                width: '20%',
                backgroundColor: step !== 'connect' ? '#c1feaf' : '#a2a3a9',
                borderRadius: '8px',
                fontSize: '18px',
                color: '#2e2e2e',
                padding: '10px',
                cursor: step !== 'connect' ? 'pointer' : 'default',
              }}
              onClick={() => {
                setAuthenticateLoading(true)
                authenticate()
              }}
            >
              {
                authenticateLoading ? (
                  <CircularProgress
                    size={15}
                    color="inherit"
                  />
                ) : 'Authenticate'
              }
            </div>
            
        </div>
      </div>
    </div>
  )

}