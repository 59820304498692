import * as React from 'react'

import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import Paper from '@mui/material/Paper'
import AddIcon from '@mui/icons-material/Add'
import SettingsIcon from '@mui/icons-material/Settings'
import SubjectIcon from '@mui/icons-material/Subject'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import { useLocation, useNavigate } from 'react-router-dom'
import EventIcon from '@mui/icons-material/Event'

export const BottomNavBar = (props) => {
  const [value, setValue] = React.useState(0)
  let location = useLocation()

  const handleChange = (event, newValue) => {
    console.log('handleChange', { event, newValue })
    setValue(newValue)
    navigate(newValue)
  }

  React.useEffect(() => {
    setValue(location.pathname)
  }, [location])

  const navigate = useNavigate()
  return (
    <>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
          style={{
            color: 'rgba(173, 173, 173, 1)',
            backgroundColor: 'rgba(64, 64, 66, 1)',
          }}
          showLabels
          // onChange={(event, newValue) => {
          // setValue(newValue)
          // }}
          value={value}
          onChange={handleChange}
        >
          <BottomNavigationAction
            label="Create Campaign"
            icon={<AddIcon />}
            value="/create"
            sx={{
              color: 'rgba(173, 173, 173, 1)',
              '&.Mui-selected': { color: 'rgb(193, 254, 175)' },
            }}
          />
          <BottomNavigationAction
            label="Active Campaigns"
            icon={<EventIcon />}
            value="/active"
            sx={{
              color: 'rgba(173, 173, 173, 1)',
              '&.Mui-selected': { color: 'rgb(193, 254, 175)' },
            }}
          />
          <BottomNavigationAction
            label="Campaign History"
            icon={<SubjectIcon />}
            value="/inactive"
            sx={{
              color: 'rgba(173, 173, 173, 1)',
              '&.Mui-selected': { color: 'rgb(193, 254, 175)' },
            }}
          />
          <BottomNavigationAction
            label="Settings"
            icon={<SettingsIcon />}
            value="/settings"
            sx={{
              color: 'rgba(173, 173, 173, 1)',
              '&.Mui-selected': { color: 'rgb(193, 254, 175)' },
            }}
          />
        </BottomNavigation>
      </Paper>
    </>
  )
}
