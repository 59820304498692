import { Stack } from "@mui/system"
import { FooterType1 } from "../subcomponents/Footer"
import styles from "../../styles/AboutUs.module.css"

export function TermsOfUse() {
  return (
    <>
      <Stack direction='column' spacing={2}>
        <div className={styles.AboutUsHeader}>Terms of Use</div>
        <Stack direction='column' spacing={2}>
          <div className={styles.AboutUsSubHeader}>Our Mission</div>
          <div className={styles.AboutUsText}>
            <span className={styles.AboutUsTextLine}>Welcome to Tipping Point, a better way
             to raise money from your community for projects and events.
             </span>
             <span className={styles.AboutUsTextLine}>
             With Tipping Point, you're in control. Create a Campaign, 
             set your targets, and invite your network. You only move forward 
             if the project hits your goals — guaranteeing that every contribution counts.
             </span>
             <span className={styles.AboutUsTextLine}>
             Try as many Campaigns as you like for free - contributors only pay a small 
             fee if it succeeds. And once it "tips," you'll have your funds immediately, 
             so you can book that venue, buy those tickets, or start that project right away.
            </span>
            <span className={styles.AboutUsTextLine}>
            Our innovative web3 platform cuts out the traditional financial middlemen
              and connects you directly with your network, enabling instant transactions
              and lower fees than any other crowdfunding platform.
            </span>
            <span className={styles.AboutUsTextLine}>
            Smart contracts on the Ethereum blockchain route funds directly from 
            contributors to fundraisers, and the network's decentralized design ensures 
            transactions can't be censored and users can't be deplatformed - enabling true 
            financial freedom for global crowdfunding.
            </span>
            <span className={styles.AboutUsTextLine}>
              There's no upfront cost and no personal or financial information required. 
              Just connect your wallet, set a display name, and start bringing your ideas to life.
            </span>
            <span className={styles.AboutUsTextLine}>
            Tipping Point is community peer-to-peer funding made smooth, simple, and secure,
            so you can focus on what matters: bringing people together.
            </span>
            <span className={styles.AboutUsTextLine}>
              Welcome to modern crowdfunding. Welcome to Tipping Point.
            </span>
          </div>
        </Stack>
        <Stack direction='column' spacing={2}>
          <div className={styles.AboutUsSubHeader}>What We Do</div>
          <span className={styles.AboutUsText}>
            Moving money around these days is easy, but coordinating social consensus
            around projects or events is <i>hard</i>:
          </span>
          <ul style={{color: '#f1f1f1'}}>
            <li className={styles.AboutUsText}>
            <span>
              Sometimes the price of attendance depends on how many people
               opt in, but most people won't opt in until they know the price.
            </span>
            </li>
            <li className={styles.AboutUsText}>
              <span>
                Sometimes people only want to participate if they know other people are participating too.
              </span>
            </li>
            <li className={styles.AboutUsText}>
              <span>
                People frequently back out or have to cancel at the last minute.
              </span>
            </li>
            <li className={styles.AboutUsText}>
              <span>
              Many ideas can only come to life if a particular threshold of participation is reached.
              </span>
            </li>
          </ul>
          <span className={styles.AboutUsText}>
          Tipping Point solves these problems by helping you first plan and coordinate
           your projects with your network, based on the number of contributors or 
           attendees you want and the amount of money you need to raise.
          </span>
          <span className={styles.AboutUsText}>
          Then, if you hit your targets, your project "tips" and you receive precisely the funding you need.
          </span>
          <span className={styles.AboutUsText}>
          Creating a Campaign is simple: just add the details, define your goals, set a deadline,
           and invite your network. Once enough people participate, your project tips — and the 
           funds are immediately sent to your wallet.
          </span>
          <span className={styles.AboutUsText}>
          Tipping Point uses a series of smart contracts on the Ethereum blockchain to route
           funds between participants, so users log in with a web3 wallet instead of the 
           traditional username and password. 
          </span>
          <span className={styles.AboutUsText}>
            There's no personal or financial data required, and Tipping Point never has access
             to user funds, which are always self-custodied in users' wallets.
          </span>
          <span className={styles.AboutUsText}>
            (Don't have a wallet? No problem, it's free to set up and only takes a minute.)
          </span>
          <span className={styles.AboutUsText}>
          Once you're connected, Tipping Point does the rest - and thanks to its web3 backbone
           Tipping Point can offer its users the fastest, cheapest, and most secure crowdfunding experience out there.
          </span>
          <span className={styles.AboutUsText}>
          With Tipping Point, you're free to dream big. Whether it's your local church community,
           an anonymous group chat, your social media followers, or the entire world, Tipping Point
            helps connect you to the people you care about so you can bring your ideas to life.
          </span>
        </Stack>
        <div className={styles.AboutUsBottomText}>Unlock the Power of Your Crowd – with Tipping Point.</div>
      </Stack>
      <FooterType1 />
    </>
  )
}