import * as React from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  Stack,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  Modal,
  CircularProgress,
  Tooltip,
  Snackbar,
  Alert,
} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ImageIcon from '@mui/icons-material/Image'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import HelpIcon from '@mui/icons-material/Help'
import CheckIcon from '@mui/icons-material/Check'
import * as web3 from '../web3/web3'
import { getCurrentAllowance } from '../web3/BanktTeller'

import { TextField, InputAdornment, Button } from '@mui/material'
import { CustomSwitch } from './subcomponents/CustomSwitch'
import * as eventService from '../services/eventService'
import {
  BACKEND_URL,
  convertIntTo2DecimalFloat,
  EVENT_TYPES,
  getMyContributionAmount,
  getPrettyDateString,
  USDC_DECIMALS,
  usdc_round,
} from '../utils/utils'
import styles from '../styles/ActiveEventComponent.module.css'
import { axiosInstance } from '../services/webClient'
import { useContext } from 'react'
import { ProviderAdapterContext } from '../App'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { FormattedEventUrl } from './subcomponents/FormattedEventUrl'
import AppsIcon from '@mui/icons-material/Apps'
import PersonIcon from '@mui/icons-material/Person'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import { useWeb3Provider } from '../services/web3Service'
import { broadcastRefundToBanktTeller } from '../web3/BanktTeller'

const EventHeaderComponent = (props) => {
  const { event, switchDisabled, switchOptedIn, switchOnClick } = props

  const creatorUserToEvent = event.userToEvents.find(
    (userToEvent) => userToEvent.isCreator
  )
  const creatorEthAddress = creatorUserToEvent.user.ethAddress
  const creatorUsername = creatorUserToEvent.user.displayName

  return (
    <Stack
      direction={'row'}
      spacing={{ xs: 2 }}
      className={styles.EventHeaderContainer}
    >
      <Stack
        direction={'row'}
        spacing={{ xs: 2 }}
        className={styles.EventHeaderIconContainer}
      >
        <AccountCircleIcon className={styles.EventHeaderIcon} />
        <Stack direction={'column'}>
          <div className={styles.EventHeader}>{event.name}</div>
          <div className={styles.EventSubheader}>
            Created by{' '}
            {creatorUsername
              ? creatorUsername
              : `${creatorEthAddress.substring(0, 8)}...`}
          </div>
        </Stack>
      </Stack>
      <div
        className={styles.EventSwitch}
        onClick={() => {
          // don't think this switch should do anything
          // if (!switchDisabled) switchOnClick(event)
        }}
      >
        <CustomSwitch disabled={switchDisabled} optedIn={switchOptedIn} />
      </div>
    </Stack>
  )
}

const PastAttendeeListItem = (props) => {
  const { attendee } = props

  return (
    <Stack
      direction={'row'}
      spacing={2}
      className={styles.EventAttendingListItem}
      style={{
        width: '100%',
      }}
    >
      <Stack
        direction={'row'}
        className={styles.EventAttendingListItemContainer}
        spacing={2}
      >
        {attendee.profilePic ? (
          <img
            src={
              'https://tippingpoint-staging-profile-pics.s3.us-east-2.amazonaws.com/' +
              attendee.id
            }
            className={styles.EventAttendingListItemImage}
          />
        ) : (
          <AccountCircleIcon
            className={styles.EventAttendingListItemImage}
            style={{ width: '44px', height: '44px' }}
          />
        )}
        <div className={styles.EventAttendingListItemName}>
          {attendee.user.displayName}
          {attendee.isCreator ? ' (Creator)' : ''} (
          {attendee.user.ethAddress.slice(0, 5) +
            '...' +
            attendee.user.ethAddress.slice(-3)}
          )
        </div>
      </Stack>
      <div className={styles.EventAttendingListItemAddr}>
        {attendee.optedIn ? (
          attendee.transferTransactionHash ? (
            <Link
              href={`https://${
                process.env.REACT_APP_ENVIRONMENT === 'production'
                  ? ''
                  : 'sepolia.'
              }basescan.org/tx/${attendee.transferTransactionHash}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Opted In
            </Link>
          ) : (
            'Opted In'
          )
        ) : (
          'Opted Out'
        )}
      </div>
    </Stack>
  )
}

const AttendeesListComponent = (props) => {
  const { numAttendees, attendees, tipped } = props

  const [expanded, setExpanded] = React.useState(false)

  return (
    <>
      <Stack direction={'column'} className={styles.EventAttendeesContainer}>
        <Stack direction={'row'} className={styles.EventAttendeesHeader}>
          <div className={styles.EventAttendeesHeaderText}>
            {tipped ? 'Attendees' : 'Opt-Ins'}
          </div>
          <div className={styles.EventAttendeesHeaderCount}>
            {numAttendees} {numAttendees !== 1 ? 'people' : 'person'}
          </div>
        </Stack>
        {
          <Accordion
            className={styles.EventAttendeesAccordion}
            expanded={expanded}
          >
            {!expanded && (
              <AccordionSummary>
                <Stack
                  direction={'row'}
                  className={styles.EventAttendeesAccordionHeader}
                  justifyContent={{ xs: 'space-between' }}
                  spacing={{ xs: 20 }}
                >
                  <div className={styles.EventAttendeesAccordionHeaderText}>
                    See Full List
                  </div>
                  <div
                    className={styles.EventAttendeesAccordionOpenButton}
                    onClick={() => setExpanded(true)}
                  >
                    OPEN
                  </div>
                </Stack>
              </AccordionSummary>
            )}
            <AccordionDetails>
              <Stack
                direction={'column'}
                spacing={{ xs: 1 }}
                className={styles.EventAttendeesAccordionDetails}
              >
                {/* <Stack
                  direction={'column'}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                > */}
                {attendees.map((ute) => {
                  return (
                    <PastAttendeeListItem attendee={ute} />
                    // <>
                    //   <div
                    //     className={styles.EventAttendeesAccordionDetailsName}
                    //     sx={{
                    //       display: 'flex',
                    //       direction: 'row',
                    //       justifyContent: 'space-between',
                    //     }}
                    //   >
                    //     {ute.user.displayName}
                    //   </div>
                    //   <div
                    //     className={styles.EventAttendeesAccordionDetailsName}
                    //   >
                    //     {ute.optedIn ? 'Opted In' : 'Opted Out'}
                    //     {ute.transferTransactionHash ? (
                    //       <Link
                    //         href={`https://${
                    //           process.env.REACT_APP_ENVIRONMENT ===
                    //           'production'
                    //             ? ''
                    //             : 'sepolia.'
                    //         }basescan.org/tx/${ute.transferTransactionHash}`}
                    //         target="_blank"
                    //         rel="noopener noreferrer"
                    //       >
                    //         Link
                    //       </Link>
                    //     ) : null}
                    //   </div>
                    // </>
                  )
                })}
                {/* </Stack> */}
                <div
                  className={styles.EventAttendeesAccordionDetailsCloseButton}
                  onClick={() => setExpanded(false)}
                >
                  CLOSE
                </div>
              </Stack>
            </AccordionDetails>
          </Accordion>
        }
      </Stack>
    </>
  )
}

export const PastEventPage = (props) => {
  const { currentEthAddress } = props
  let { eventId } = useParams()
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [refundModalOpen, setRefundModalOpen] = useState(false)
  const { getProvider } = useWeb3Provider()
  const navigate = useNavigate()

  const isCreator = (activeEvent) => {
    const isCreator = activeEvent.creatorEthAddress === currentEthAddress
    return isCreator
  }

  const getMyOptIn = (event) => {
    const currentUserToEvent = event?.userToEvents.find(
      (userToEvent) => userToEvent.user.ethAddress === currentEthAddress
    )
    if (currentUserToEvent.isCreator) return true
    return currentUserToEvent ? currentUserToEvent.optedIn : false
  }

  const refundEvent = async (eventId, address, provider) => {
    await fetch(BACKEND_URL + '/event/refundEvent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventId: eventId,
        address: address,
      }),
      credentials: 'include',
    })
      .then(async function (response) {
        const { eip712RefundMessage } = await response.json()
        const refundApproval = eip712RefundMessage?.params[1]?.message
        console.log('refundEvent res ', { response, refundApproval })
        const refundSignature = await provider.request(eip712RefundMessage)
        await broadcastRefundToBanktTeller(
          refundSignature,
          provider,
          refundApproval
        )
      })
      .catch(function (error) {
        console.log('refundEvent', error)
      })
  }

  const eventHasPaidOut = React.useMemo(async () => {
    if (selectedEvent) {
      const hasEventPaidOut = await eventService.hasEventPaidOut(selectedEvent)
      return hasEventPaidOut
    }
  }, [selectedEvent])

  useEffect(() => {
    async function getSelectedEvent() {
      await eventService
        .getEvent(eventId, currentEthAddress)
        .then(async (event) => {
          setSelectedEvent(event)
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            setSelectedEvent(undefined)
          }
        })
    }
    getSelectedEvent()
  }, [])

  function calculateInviteeTransactionLink(selectedEvent, ethAddress) {
    const userToEvent = selectedEvent.userToEvents.find(
      (ute) => ute.user.ethAddress === ethAddress
    )
    if (!userToEvent.transferTransactionHash) return null
    if (process.env.NODE_ENV === 'production') {
      return `https://basescan.org/tx/${userToEvent.transferTransactionHash}`
    } else {
      return `https://sepolia.basescan.org/tx/${userToEvent.transferTransactionHash}`
    }
  }

  function getEventOutcome() {
    if (selectedEvent) {
      if (wasEventCanceled()) {
        return 'Campaign Cancelled'
      }
      if (eventTipped()) {
        return 'Campaign Tipped'
      }
      if (selectedEvent.status === 'TIPPED_AND_REFUNDED') {
        return 'Campaign Tipped & Refunded'
      }
      return 'Campaign Not Tipped'
    }
  }

  function wasEventCanceled() {
    return selectedEvent.status === 'CANCELLED'
  }

  function eventTipped() {
    return selectedEvent.status === 'TIPPED'
  }

  const EventDetailsDisplayComponent = (props) => {
    const { header, value, icon } = props

    return (
      <Stack
        direction={'column'}
        spacing={{ xs: 1 }}
        className={styles.EventDetailsSubContainer}
      >
        <div className={styles.EventDetailsText}>{header}</div>
        <TextField
          className={styles.EventDetailsTextField}
          disabled={true}
          value={value}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              '-webkit-text-fill-color': '#a2a3a9',
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          }}
        />
      </Stack>
    )
  }

  const EventDetailsComponent = (props) => {
    const { event, currentEthAddress } = props

    if (event.eventType === EVENT_TYPES.DYNAMIC) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Split Fixed Cost'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
          <EventDetailsDisplayComponent
            header={'Minimum People'}
            value={event.minimumPeople ? event.minimumPeople : 0}
            icon={<PersonIcon className={styles.EventDetailsIcon} />}
          />
          {event.maximumPeople && (
            <EventDetailsDisplayComponent
              header={'Maximum People'}
              value={event.maximumPeople}
              icon={<AccessibilityIcon className={styles.EventDetailsIcon} />}
            />
          )}
          <EventDetailsDisplayComponent
            header={'Goal Amount'}
            value={`$${convertIntTo2DecimalFloat(event.goalAmount)}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
    if (event.eventType === EVENT_TYPES.STATIC) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Fixed Price Per Person'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
          <EventDetailsDisplayComponent
            header={'Minimum People'}
            value={event.minimumPeople ? event.minimumPeople : 0}
            icon={<PersonIcon className={styles.EventDetailsIcon} />}
          />
          {event.maximumPeople && (
            <EventDetailsDisplayComponent
              header={'Maximum People'}
              value={event.maximumPeople ? event.maximumPeople : 0}
              icon={<AccessibilityIcon className={styles.EventDetailsIcon} />}
            />
          )}
          <EventDetailsDisplayComponent
            header={'Price per Person'}
            value={`$${convertIntTo2DecimalFloat(event.staticPricePerPerson)}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
    if (event.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Make or Break'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
          <EventDetailsDisplayComponent
            header={'Goal Amount'}
            value={`$${convertIntTo2DecimalFloat(event.goalAmount)}`}
            icon={<AttachMoneyIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
    if (event.eventType === EVENT_TYPES.GENERAL_FUNDRAISING) {
      return (
        <Stack
          direction={'column'}
          spacing={{ xs: 2 }}
          className={styles.EventDetailsContainer}
        >
          <EventDetailsDisplayComponent
            header={'Campaign Category'}
            value={'Anything Helps'}
            icon={<AppsIcon className={styles.EventDetailsIcon} />}
          />
        </Stack>
      )
    }
  }

  return (
    <>
      {selectedEvent && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Stack
            direction={'column'}
            spacing={{ xs: 2 }}
            alignItems={{ xs: 'center' }}
            className={styles.EventContainer}
          >
            <EventHeaderComponent
              event={selectedEvent}
              switchDisabled={isCreator(selectedEvent)}
              switchOptedIn={getMyOptIn(selectedEvent)}
              switchOnClick={() => {}}
            />
            {/* Event Image */}
            {selectedEvent.file && (
              <img
                src={selectedEvent.imgUrl}
                alt={selectedEvent.name}
                className={styles.EventImage}
              />
            )}
            {!selectedEvent.file && (
              <div className={styles.EventImageContainer}>
                <ImageIcon className={styles.EventImageIcon} />
              </div>
            )}
            <div className={styles.EventTippedContainer}>
              <Stack direction={'row'} justifyContent={{ xs: 'space-between' }}>
                <div
                  className={styles.EventTippedText}
                  style={{ color: '#829aff' }}
                >
                  {getEventOutcome(selectedEvent)}
                </div>
                <div className={styles.EventTippedDate}>
                  {wasEventCanceled()
                    ? ''
                    : getPrettyDateString(new Date(selectedEvent.deadline))}
                </div>
              </Stack>
            </div>
            <AttendeesListComponent
              numAttendees={selectedEvent.userToEvents.reduce(
                (acc, userToEvent) => (userToEvent.optedIn ? acc + 1 : acc),
                0
              )}
              attendees={selectedEvent.userToEvents.filter(
                (ute) => !!ute.isInvitee && !!ute.optedIn
              )}
              tipped={eventTipped()}
            />
            <Stack
              direction={'column'}
              className={styles.EventDescriptionContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventDescriptionText}>Description</div>
              <TextField
                className={styles.EventDescriptionTextField}
                disabled={true}
                value={selectedEvent.description}
                multiline
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            <EventDetailsComponent
              event={selectedEvent}
              currentEthAddress={currentEthAddress}
            />
            <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventPreferredCurrencyContainer}
            >
              <div className={styles.EventDetailsText}>
                {'Your Contribution'}
              </div>
              <TextField
                // className={styles.EventPreferredCurrencyTextField}
                disabled={true}
                variant="outlined"
                value={`${convertIntTo2DecimalFloat(
                  getMyContributionAmount(selectedEvent, currentEthAddress)
                )}`}
                // disabling the text field causes styles to be overridden
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {<AttachMoneyIcon className={styles.EventDetailsIcon} />}
                    </InputAdornment>
                  ),
                  endAdornment: calculateInviteeTransactionLink(
                    selectedEvent,
                    currentEthAddress
                  ) ? (
                    <>
                      <Link
                        href={calculateInviteeTransactionLink(
                          selectedEvent,
                          currentEthAddress
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        TX
                        <OpenInNewIcon
                          sx={{ width: '.85rem', height: '.85rem' }}
                        />
                      </Link>{' '}
                    </>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Stack>
            <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventPreferredCurrencyContainer}
            >
              <div className={styles.EventDetailsText}>
                {'Total Money Raised'}
              </div>
              <TextField
                // className={styles.EventPreferredCurrencyTextField}
                disabled={true}
                variant="outlined"
                value={`${convertIntTo2DecimalFloat(
                  // If Event is not a TIPPED status, then it never raised any money, so we return 0
                  selectedEvent.status === 'TIPPED' ||
                    selectedEvent.status === 'TIPPED_AND_REFUNDED'
                    ? eventService.getTotalAmountRaised(selectedEvent)
                    : 0
                )}`}
                // disabling the text field causes styles to be overridden
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {<AttachMoneyIcon className={styles.EventDetailsIcon} />}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>

            <FormattedEventUrl
              event={selectedEvent}
              containerClassName={styles.EventLinkContainer}
            />
            {isCreator(selectedEvent) ? (
              <Stack
                direction={'row'}
                spacing={{ xs: 2 }}
                className={styles.EventCancelEditContainer}
              >
                <Button
                  className={styles.EventCancelButton}
                  variant="outlined"
                  onClick={() => {
                    // eventService.cancelEvent(selectedEvent.id)
                    console.log('cancel event', selectedEvent)
                    navigate('/create', {
                      state: {
                        eventInputs: {
                          name: selectedEvent.name,
                          description: selectedEvent.description,
                          deadline: selectedEvent.deadline,
                          goalAmount: selectedEvent.goalAmount
                            ? convertIntTo2DecimalFloat(
                                selectedEvent.goalAmount
                              )
                            : null,
                          isCreatorAttendee: selectedEvent.isCreatorAttendee,
                          staticPricePerPerson:
                            selectedEvent.staticPricePerPerson,
                          minimumPeople: selectedEvent.minimumPeople,
                          maximumPeople: selectedEvent.maximumPeople,
                          eventType: selectedEvent.eventType,
                          file: selectedEvent.file,
                          imgUrl: selectedEvent.imgUrl,
                        },
                      },
                    })
                  }}
                >
                  Replicate Campaign
                </Button>
                {!!eventHasPaidOut &&
                  selectedEvent.status !== 'TIPPED_AND_REFUNDED' && (
                    <Button
                      className={styles.EventReturnButton}
                      variant="outlined"
                      onClick={async () => {
                        setRefundModalOpen(true)
                      }}
                      disabled={selectedEvent.status === 'TIPPED_AND_REFUNDED'}
                    >
                      Return Funds
                    </Button>
                  )}
              </Stack>
            ) : (
              <></>
            )}
          </Stack>
        </div>
      )}
      <RefundModal
        open={refundModalOpen}
        setOpen={setRefundModalOpen}
        event={selectedEvent}
        address={currentEthAddress}
        setSelectedEvent={setSelectedEvent}
        currentEthAddress={currentEthAddress}
      />
    </>
  )
}

const RefundModal = ({
  open,
  setOpen,
  event,
  address,
  setSelectedEvent,
  currentEthAddress,
}) => {
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [insufficentUSDC, setInsufficentUSDC] = React.useState(false)
  const [successfulRefundTx, setSuccessfulRefundTx] = React.useState(false)
  return (
    <>
      <Modal
        open={open}
        setOpen={setOpen}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'visible',
        }}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        onClose={() => setOpen(false)}
      >
        <div
          style={{
            backgroundColor: '#000000',
            borderRadius: '16px',
            border: 'solid 1px',
            height: 'auto',
            width: '400px',
            padding: '15px 10px',
            overflow: 'visible',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#c1feaf',
          }}
        >
          <TwoStepConfirmComponent
            event={event}
            address={address}
            closeModal={() => setOpen(false)}
            setSnackbarOpen={setSnackbarOpen}
            setInsufficentUSDC={setInsufficentUSDC}
            setSuccessfulRefundTx={setSuccessfulRefundTx}
            setSelectedEvent={setSelectedEvent}
            currentEthAddress={currentEthAddress}
          />
        </div>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={successfulRefundTx ? 'success' : 'error'}
        >
          {successfulRefundTx
            ? 'Refund successful'
            : insufficentUSDC
            ? 'Insufficient USDC tokens'
            : 'Something went wrong'}
        </Alert>
      </Snackbar>
    </>
  )
}

const TwoStepConfirmComponent = (props) => {
  const {
    event,
    setSelectedEvent,
    currentEthAddress,
    setInsufficentUSDC,
    setSnackbarOpen,
    closeModal,
    address,
    setSuccessfulRefundTx,
  } = props

  const { getProvider } = useWeb3Provider()
  const [stepOne, setStepOne] = React.useState(true)
  const [stepOneLoading, setStepOneLoading] = React.useState(false)
  const [stepTwoLoading, setStepTwoLoading] = React.useState(false)
  const [refundApprovalEip712Msg, setRefundApprovalEip712Msg] =
    React.useState(null)
  const [refundApprovalMsg, setRefundApprovalMsg] = React.useState(null)
  const [done, setDone] = React.useState(false)

  const doAllowanceRequest = async (eventId, address) => {
    let allowanceAmtNeeded = 0
    await fetch(BACKEND_URL + '/event/refundEvent', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        eventId: eventId,
        address: address,
      }),
      credentials: 'include',
    })
      .then(async function (response) {
        const { eip712RefundMessage } = await response.json()
        const _refundApprovalMsg = eip712RefundMessage?.params[1]?.message
        setRefundApprovalEip712Msg(eip712RefundMessage)
        setRefundApprovalMsg(_refundApprovalMsg)
        allowanceAmtNeeded = _refundApprovalMsg.amounts.reduce(
          (acc, curr) => acc + Number(curr),
          0
        )
      })
      .catch(function (error) {
        console.error('refundEvent', error)
      })
    setStepOneLoading(true)
    // compute allowance amount
    const provider = await getProvider()
    let currentAllowance = await getCurrentAllowance(address, provider)
    const newAllowance = Number(currentAllowance) + Number(allowanceAmtNeeded)

    // request allowance increase
    try {
      const { signatureAllowanceUSDC, allowanceTX } =
        await web3.completeAllowanceRequest(
          address,
          newAllowance,
          new Date(),
          await getProvider()
        )
      setInsufficentUSDC(false)
      setStepOneLoading(false)
      setStepOne(false)
    } catch (error) {
      if (error.message === 'Insufficient USDC tokens') {
        setInsufficentUSDC(true)
        setSnackbarOpen(true)
      } else {
        console.error('doAllowanceRequest error', JSON.stringify(error))
      }
      setStepOneLoading(false)
    }
  }

  const doTransactionRefund = async () => {
    setStepTwoLoading(true)

    // request transaction signing
    try {
      const provider = await getProvider()
      const refundSignature = await provider.request(refundApprovalEip712Msg)
      const txReceipt = await broadcastRefundToBanktTeller(
        refundSignature,
        provider,
        refundApprovalMsg
      )
      if (txReceipt) {
        // show a success message popup
        setSuccessfulRefundTx(true)
        setSnackbarOpen(true)

        // update the event to TIPPED AND REFUNDED
        await fetch(BACKEND_URL + '/event/editPastEvent', {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            event: {
              id: event.id,
              status: 'TIPPED_AND_REFUNDED',
            },
          }),
          credentials: 'include',
        })
          .then((res) => {
            console.log('edit event response', { res })
          })
          .catch((err) => {
            console.error('edit event error', { eventId: event.id })
          })
      }
      setStepTwoLoading(false)
      setDone(true)
      // update the event
      await eventService
        .getEvent(event.id, currentEthAddress)
        .then(async (event) => {
          setSelectedEvent(event)
        })
        .catch((err) => {
          if (err?.response?.status === 404) {
            setSelectedEvent(undefined)
          }
        })
      setTimeout(() => {
        closeModal()
      }, 1000)
    } catch (error) {
      console.error('doTransactionRefund error', JSON.stringify(error))
    }
  }

  return (
    <>
      <Stack direction="row" spacing={1} height={'100px'}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            position: 'relative',
          }}
        >
          <div
            style={{
              backgroundColor: '#c1feaf',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              position: 'relative',
              top: '25%',
              transform: 'translateY(-50%)',
              color: '#1a1a1a',
              border: '1px solid #f1f1f1',
              zIndex: 1,
            }}
          >
            {stepOne ? (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                1
              </div>
            ) : (
              <CheckIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            )}
          </div>
          <div
            style={{
              position: 'absolute',
              width: '10px',
              height: '12px',
              top: '44px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: '#c1feaf',
              borderLeft: '1px solid #f1f1f1',
              borderRight: '1px solid #f1f1f1',
              zIndex: 2,
            }}
          />
          <div
            style={{
              position: 'absolute',
              width: '10px',
              height: '12px',
              top: '56px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: stepOne ? '#1a1a1a' : '#c1feaf',
              borderLeft: '1px solid #f1f1f1',
              borderRight: '1px solid #f1f1f1',
              zIndex: 2,
            }}
          />
          <div
            style={{
              backgroundColor: stepOne ? '#1a1a1a' : '#c1feaf',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              position: 'absolute',
              top: '75%',
              transform: 'translateY(-50%)',
              border: '1px solid #f1f1f1',
              zIndex: 1,
            }}
          >
            {!done ? (
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: stepOne ? '#f1f1f1' : '#1a1a1a',
                }}
              >
                2
              </div>
            ) : (
              <CheckIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: stepOne ? '#f1f1f1' : '#1a1a1a',
                }}
              />
            )}
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '150px',
            position: 'relative',
          }}
        >
          <div
            style={{
              padding: '10px 0px',
              textAlign: 'center',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: stepOne ? '#c1feaf' : '#1a1a1a',
              color: stepOne ? '#1a1a1a' : '#a2a3a9',
              cursor: stepOne ? 'pointer' : 'default',
              position: 'absolute',
              top: '25%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '14.5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (stepOne) {
                doAllowanceRequest(event.id, address)
              }
            }}
          >
            {stepOneLoading ? (
              <CircularProgress
                size={20}
                sx={{
                  color: '#1a1a1a',
                  padding: 0,
                }}
              />
            ) : (
              <>
                Approve Allowance
                <Tooltip
                  title="This allowance should cover all your opted-in events for Tipping Point to dispense funds"
                  arrow
                >
                  <HelpIcon
                    style={{
                      color: '#a2a3a9',
                      position: 'relative',
                      left: '.5px',
                      scale: '.5',
                      cursor: 'help',
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
          <div
            style={{
              padding: '10px 0px',
              textAlign: 'center',
              width: '100%',
              borderRadius: '8px',
              backgroundColor: !stepOne ? '#c1feaf' : '#1a1a1a',
              color: !stepOne ? '#1a1a1a' : '#a2a3a9',
              cursor: !stepOne ? 'pointer' : 'not-allowed',
              position: 'absolute',
              top: '75%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              if (!stepOne && !done) {
                doTransactionRefund()
              }
            }}
          >
            {stepTwoLoading ? (
              <CircularProgress
                size={20}
                sx={{
                  color: '#1a1a1a',
                  padding: 0,
                }}
              />
            ) : (
              <>
                Refund
                <Tooltip
                  title="This authorizes the transfer details for the Tipping Point smart contract"
                  arrow
                >
                  <HelpIcon
                    style={{
                      color: '#a2a3a9',
                      position: 'relative',
                      left: '1px',
                      scale: '.5',
                      cursor: 'help',
                    }}
                  />
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </Stack>
    </>
  )
}
