import { Alert, Button, Snackbar, Stack, TextField } from "@mui/material"
import React from "react"
import styles from '../../styles/ContactUs.module.css'
import * as emailService from "../../services/emailService"


export const ContactUs = () => {

    const [email, setEmail] = React.useState('')
    const [subject, setSubject] = React.useState('')
    const [body, setBody] = React.useState('')

    const [emailError, setEmailError] = React.useState(null)
    const [subjectError, setSubjectError] = React.useState(null)
    const [bodyError, setBodyError] = React.useState(null)

    const [emailSubmissionError, setEmailSubmissionError] = React.useState(false)

    const [openSnackbar, setOpenSnackbar] = React.useState(false)

    const handleSubmit = () => {
        if (!email) {
            setEmailError('Email is required')
        }
        if (!subject) {
            setSubjectError('Subject is required')
        }
        if (!body) {
            setBodyError('Body is required')
        }
        if (emailError || subjectError || bodyError) {
            setOpenSnackbar(true)
        } else {
            // Send email
            emailService.sendEmail(email, subject, body)
            .then((response) => {
                console.log('Email sent successfully:', response)
                setEmail('')
                setSubject('')
                setBody('')
                setEmailSubmissionError(false)
                setOpenSnackbar(true)
            })
            .catch((error) => {
                console.error('Error sending email:', error)
                setEmailSubmissionError(true)
                setOpenSnackbar(true)
            })
        }
    }

    return (
        <>
            <Stack direction={'column'} spacing={2}>
                <div
                    className={styles.ContactUsHeader}
                >
                    Contact Us
                </div>
                <Stack direction={'column'} spacing={1} alignItems={'center'}>
                    <div 
                    className={styles.ContactUsSubHeader}
                    >
                        Email
                    </div>
                    <TextField
                    error={emailError ? true : false}
                    helperText={emailError}
                    className={styles.ContactUsTextField}
                    placeholder={'Enter your email address'}
                    value={email}
                    autoComplete="off"
                    onChange={(e) => {
                        if (e.target.value === '') {
                            setEmailError('Email is required')
                        } else {
                            setEmailError(null)
                        }
                        setEmail(e.target.value)
                    }}
                    sx={{
                        '& .MuiInputBase-input': {
                            '-webkit-text-fill-color': '#a2a3a9',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            '-webkit-text-fill-color': '#a2a3a9',
                        },
                        '& input': {
                            color: '#a2a3a9',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-error fieldset': {
                            borderColor: '#ff0000',
                            borderWidth: '1px',
                            },
                            '&:hover fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-error:hover fieldset': {
                            borderColor: '#ff0000',
                            borderWidth: '1px',
                            }
                        },
                    }}
                />
              </Stack>
              <Stack direction={'column'} spacing={1} alignItems={'center'}>
                    <div 
                    className={styles.ContactUsSubHeader}
                    >
                        Subject
                    </div>
                    <TextField
                    error={subjectError ? true : false}
                    helperText={subjectError}
                    className={styles.ContactUsTextField}
                    placeholder={'Email Subject'}
                    value={subject}
                    autoComplete="off"
                    onChange={(e) => {
                        if (e.target.value === '') {
                            setSubjectError('Subject is required')
                        } else {
                            setSubjectError(null)
                        }
                        setSubject(e.target.value)
                    }}
                    sx={{
                        '& .MuiInputBase-input': {
                            '-webkit-text-fill-color': '#a2a3a9',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            '-webkit-text-fill-color': '#a2a3a9',
                        },
                        '& input': {
                            color: '#a2a3a9',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-error fieldset': {
                            borderColor: '#ff0000',
                            borderWidth: '1px',
                            },
                            '&:hover fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-error:hover fieldset': {
                            borderColor: '#ff0000',
                            borderWidth: '1px',
                            }
                        },
                    }}
                />
              </Stack>
              <Stack direction={'column'} spacing={1} alignItems={'center'}>
                    <div 
                    className={styles.ContactUsSubHeader}
                    >
                        Body
                    </div>
                    <TextField
                    error={bodyError ? true : false}
                    helperText={bodyError}
                    className={styles.ContactUsTextField}
                    placeholder={'Email Body'}
                    value={body}
                    autoComplete="off"
                    onChange={(e) => {
                        if (e.target.value === '') {
                            setBodyError('Body is required')
                        } else {
                            setBodyError(null)
                        }
                        setBody(e.target.value)
                    }}
                    multiline
                    minRows={4}
                    maxRows={10}
                    sx={{
                        '& .MuiInputBase-input': {
                            '-webkit-text-fill-color': '#a2a3a9',
                        },
                        '& .MuiInputBase-input.Mui-disabled': {
                            '-webkit-text-fill-color': '#a2a3a9',
                        },
                        '& textarea': {
                            color: '#a2a3a9',
                        },
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-focused fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-error fieldset': {
                            borderColor: '#ff0000',
                            borderWidth: '1px',
                            },
                            '&:hover fieldset': {
                            borderColor: '#a2a3a9',
                            borderWidth: '1px',
                            },
                            '&.Mui-error:hover fieldset': {
                            borderColor: '#ff0000',
                            borderWidth: '1px',
                            }
                        },
                    }}
                />
              </Stack>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{
                    '&.MuiButtonBase-root': {
                        backgroundColor: '#c1feaf',
                        color: '#000000',
                        fontWeight: '400',
                        borderRadius: '10px',
                        padding: '10px 0px',
                        width: '250px',
                        alignSelf: 'center',
                        '&:hover': {
                            backgroundColor: '#a1de8f',
                        },
                    },
                }}
              >
                Send
              </Button>
            </Stack>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={(emailError || subjectError || bodyError|| emailSubmissionError) ? 'error' : 'success' }>
                    {
                        emailError ?
                        'Email is required' :
                        subjectError ?
                        'Subject is required' :
                        bodyError ?
                        'Body is required' :
                        emailSubmissionError ?
                        'Error sending email' :
                        'Email sent successfully'
                    }
                </Alert>
            </Snackbar>
        </>
    )
}