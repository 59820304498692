import { Stack } from '@mui/material'
import styles from '../../styles/HowItWorks.module.css'
import { FooterType1 } from '../subcomponents/Footer'
import { useState, useEffect } from 'react'

function HowItWorks() {
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return (
    <>
      <Stack direction="column" spacing={{ md: 2 }}>
        <div className={styles.HowItWorksHeader}>How It Works</div>
        <div className={styles.HowItWorksText}>
          <span
            className={styles.HowItWorksTextLine}
            style={{ color: '#f1f1f1' }}
          >
            Tipping Point is a platform for funding group projects and campaigns
            where contributors only pay if the campaign is guaranteed to
            succeed. Here’s how it works:
          </span>
          <span className={styles.HowItWorksTextLine}>
            1. Create a Campaign: Set up your campaign with a funding goal,
            optional contributor count, and a deadline.
          </span>
          <span className={styles.HowItWorksTextLine}>
            2. Invite Contributors: Share your campaign with others, who can opt
            in by pledging funds.
          </span>
          <span className={styles.HowItWorksTextLine}>
            3. Campaign Tips: If the goals are met before or by the deadline,
            the campaign "tips," and Tipping Point transfers the pledged funds
            to the campaign creator. Contributors pay a small (1.5%) fee only on
            campaigns that tip.
          </span>
          <span className={styles.HowItWorksTextLine}>
            4. No Risk: If the goals aren't met, no funds are collected and no
            fees are charged.
          </span>
        </div>
        <div className={styles.HowItWorksSubHeader}>
          Types of Tipping Point Campaigns
        </div>
        <div className={styles.HowItWorksText}>
          <span className={styles.HowItWorksTextLine}>
            Tipping Point offers four campaign types tailored to different
            funding needs:
          </span>

          <span className={styles.HowItWorksListHeader}>
            <span className={styles.HowItWorksListHeaderAlt}></span>Split Fixed
            Cost
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextLine}
          >
            Collect a fixed total amount, with the per-person contribution
            decreasing as more people opt in.
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextList}
          >
            Examples:
          </span>
          <ul>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Booking a vacation home to share with friends
              </span>
            </li>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Renting venues or organizing group activities like boat rentals
                or party buses.
              </span>
            </li>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Crowdfunding a startup or construction project.
              </span>
            </li>
          </ul>
          <span className={styles.HowItWorksListHeader}>
            <span className={styles.HowItWorksListHeaderAlt}></span>Fixed Price
            Per Person
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextLine}
          >
            Set a fixed cost per contributor while ensuring a minimum number of
            participants.
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextList}
          >
            Examples:
          </span>
          <ul>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Buying tickets as a group for concerts or sports events.
              </span>
            </li>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Hiring group services like chefs, tour guides, or yoga
                instructors.
              </span>
            </li>
          </ul>
          <span className={styles.HowItWorksListHeader}>
            <span className={styles.HowItWorksListHeaderAlt}></span>Make or
            Break Fundraising
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextLine}
          >
            Raise a minimum total amount for the campaign to tip, regardless of
            the number of contributors.
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextList}
          >
            Examples:
          </span>
          <ul>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Launching a small business or product.
              </span>
            </li>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Producing creative projects like films or albums.
              </span>
            </li>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Funding scientific research or expeditions.
              </span>
            </li>
          </ul>
          <span className={styles.HowItWorksListHeader}>
            <span className={styles.HowItWorksListHeaderAlt}></span>Anything
            Helps Fundraising
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextLine}
          >
            No minimum funding or contributor requirements; the campaign will
            tip with any contribution.
          </span>
          <span
            style={{ paddingLeft: width > 1199 ? '20px' : '0px' }}
            className={styles.HowItWorksTextList}
          >
            Examples:
          </span>
          <ul>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Crowdfunding medical expenses or disaster relief.
              </span>
            </li>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Collecting donations for charities.
              </span>
            </li>
            <li>
              <span
                style={{ paddingLeft: '0px' }}
                className={styles.HowItWorksTextList}
              >
                Funding personal projects or creative works.
              </span>
            </li>
          </ul>
          <div className={styles.HowItWorksSubHeader}>How We're Different</div>
          <div className={styles.HowItWorksText}>
            <span className={styles.HowItWorksTextLine}>
              By cutting out middlemen like banks and payment processors and
              building on the Ethereum blockchain instead, Tipping Point offers
              unparalleled benefits for peer-to-peer community funding:
            </span>
            <span className={styles.HowItWorksTextLine}>
              <span style={{ color: '#f1f1f1' }}>Minimal Fees:</span> Just 1.5%
              per tipped campaign—no charges otherwise.
            </span>
            <span className={styles.HowItWorksTextLine}>
              <span style={{ color: '#f1f1f1' }}>
                Lightning-Fast Transactions:
              </span>
              Funds are transferred immediately upon tipping, 24/7/365.
            </span>
            <span className={styles.HowItWorksTextLine}>
              <span style={{ color: '#f1f1f1' }}>Privacy First:</span> No
              personal data or financial info is required to use Tipping Point.
            </span>
            <span className={styles.HowItWorksTextLine}>
              <span style={{ color: '#f1f1f1' }}>Self-Custodied Funds:</span>
              Contributions stay in users' wallets until the campaign tips,
              eliminating the risk of loss or third-party seizure.
            </span>
            <span className={styles.HowItWorksTextLine}>
              <span style={{ color: '#f1f1f1' }}>Global Reach:</span> Collect
              funds from anywhere in the world without being limited by payment
              processors, banking restrictions or geographic barriers.
            </span>
            <span className={styles.HowItWorksTextLine}>
              Tipping Point combines simplicity, security, and flexibility to
              make group funding risk-free and accessible for everyone.
            </span>
            <span className={styles.HowItWorksTextLine}>
              <span style={{ color: '#f1f1f1' }}>
                Unlock the power of your crowd - With Tipping Point!
              </span>
            </span>
          </div>
        </div>
      </Stack>
      <FooterType1 />
    </>
  )
}

export default HowItWorks
