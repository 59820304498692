import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'
import { useState } from 'react'

const CustomSwitchBase = (props) => {
  const { optedIn, disabled } = props

  const [state, setState] = useState({
    checkedA: true,
  })

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  return (
    <Switch
      disabled={disabled}
      checked={optedIn}
      onChange={handleChange}
      name="checkedA"
      id="optin-switch"
      inputProps={{ 'aria-label': 'secondary checkbox' }}
      sx={{
        cursor: disabled ? 'not-allowed' : 'pointer',
      }}
      {...props}
    />
  )
}

/**
 * Used this link as reference:
 * https://codesandbox.io/p/sandbox/angry-breeze-qknljj?file=%2Fsrc%2FApp.js%3A8%2C20
 */
export const CustomSwitch = styled(CustomSwitchBase)(({ theme }) => ({
  '&.MuiSwitch-root': {
    width: '50px',
    height: '24px',
    padding: '0px',
  },
  '& .MuiSwitch-switchBase': {
    color: '#818181',
    padding: '1px',
    '&.Mui-checked': {
      color: '#23bf58',
      '& + .MuiSwitch-track': {
        backgroundColor: '#23bf58',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    color: 'white',
    width: '20px',
    height: '20px',
    margin: '1px',
  },
  '& .MuiSwitch-track': {
    borderRadius: '20px',
    backgroundColor: '#818181',
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'white',
      position: 'absolute',
      top: '6px',
    },
    '&:after': {
      content: '"In"',
      fontSize: '11px',
      left: '8px',
    },
    '&:before': {
      fontSize: '11px',
      content: '"Out"',
      right: '4px',
    },
  },
  '& .Mui-checked': {
    color: '#23bf58 !important',
    transform: 'translateX(26px) !important',
  },
}))
