import styles from '../../styles/AboutUs.module.css'
import { Stack } from '@mui/system'
import { FooterType1 } from '../subcomponents/Footer'

function GetStarted() {
  return (
    <>
      <Stack direction="column" spacing={2}>
        <div className={styles.AboutUsHeader}>Get Started</div>
        <Stack direction="column" spacing={2}>
          <div className={styles.AboutUsText}>
            <div className={styles.AboutUsSubHeader}>
              Getting started with Tipping Point is easy!
            </div>

            <span className={styles.AboutUsTextLine}>
              To create a Campaign, all you need is a web3 wallet with an
              Ethereum address.
            </span>
            <span className={styles.AboutUsTextLine}>
              To contribute to a Campaign, in addition to a wallet you'll need
              some USDC on Ethereum's Base network.
            </span>

            <div className={styles.AboutUsSubHeader}>Need a wallet?</div>

            <span className={styles.AboutUsTextLine}>
              You can log in to Tipping Point with a variety of web3 wallets,
              such as{' '}
              <a
                href="https://www.coinbase.com/wallet/downloads"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'grey' }}
              >
                Coinbase Wallet
              </a>{' '}
              or{' '}
              <a
                href="https://metamask.io/download/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'grey' }}
              >
                Metamask
              </a>
              .
            </span>
            <span className={styles.AboutUsTextLine}>
              You can also have Tipping Point create a wallet for you by logging
              in with your email or a social account like Google or Apple
              (you'll see these options after clicking the "Connect" button).
            </span>
            <span className={styles.AboutUsTextLine}>
              Once you have a wallet, log into Tipping Point by clicking on the
              "Connect" button on the homepage and approving the two wallet
              prompts that follow.
            </span>
            <span className={styles.AboutUsTextLine}>
              The first prompt connects your wallet to the Tipping Point
              platform, and the second logs you in to your account.
            </span>

            <div className={styles.AboutUsSubHeader}>Need USDC?</div>

            <span className={styles.AboutUsTextLine}>
              Just like Tipping Point works with a number of wallets, there are
              also several ways to fund your wallet with USDC.
            </span>

            <span className={styles.AboutUsTextLine}>
              If you have a Coinbase account, you can swap USD for USDC for free
              on their platform.
            </span>
            <span className={styles.AboutUsTextLine}>
              Then, if you're using Coinbase Wallet, you can{' '}
              <a
                href="https://help.coinbase.com/en/wallet/managing-account/usdc-coinbase-wallet#how-to-buy-usdc-on-coinbase-wallet"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'grey' }}
              >
                bridge (send) USDC to your wallet address on Base for free.
              </a>
            </span>
            <span className={styles.AboutUsTextLine}>
              (You can also bridge Base USDC from your Coinbase account to other
              wallets, but Coinbase might charge a small fee.)
            </span>
            <span className={styles.AboutUsTextLine}>
              Most other crypto exchanges, such as Kraken, also offer
              USD-to-USDC conversion, although they may require additional steps
              to bridge the USDC to the Base network.
            </span>

            <div className={styles.AboutUsSubHeader}>Creating a Campaign</div>

            <span className={styles.AboutUsTextLine}>
              There are three steps to creating a Tipping Point Campaign once
              you've set up your account.
            </span>
            <span className={styles.AboutUsTextLine}>
              First, add some information about your Campaign, like a
              description, deadline, and photo or video.
            </span>
            <span className={styles.AboutUsTextLine}>
              Next, select your Campaign type (for more information on Campaign
              types, see{' '}
              <a
                href="https://tippingpoint.app/howItWorks"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'grey' }}
              >
                How It Works
              </a>
              ).
            </span>
            <span className={styles.AboutUsTextLine}>
              Finally, set your fundraising and attendance goals. Once your
              Campaign is created you'll receive an invite link to share with
              your network.
            </span>
            <span className={styles.AboutUsTextLine}>
              You can edit, cancel, or monitor the progress of your Campaign
              from the Active Campaigns page.
            </span>
            <span className={styles.AboutUsTextLine}>
              Once the deadline is reached, the details of the Campaign can be
              found in Campaign History.
            </span>
          </div>
        </Stack>
      </Stack>
      <FooterType1 />
    </>
  )
}

export default GetStarted
