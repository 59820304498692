import * as React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import {
  EVENT_TYPES,
  USDC_DECIMALS,
  convertIntTo2DecimalFloat,
} from '../../utils/utils'

function LinearProgressWithLabel(props) {
  const { selectedEvent } = props
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Tooltip title={generateTooltipText(selectedEvent)} placement="top">
        <div>Progress: </div> <br />
      </Tooltip>
      <Box sx={{ width: '100%', mr: 1, ml: 1 }}>
        <Tooltip title={generateTooltipText(selectedEvent)} placement="top">
          <LinearProgress
            variant="determinate"
            {...props}
            value={() => generateProgressBarValue(selectedEvent)}
          />
        </Tooltip>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {generateProgressBarText(selectedEvent)}
        </Typography>
      </Box>
    </Box>
  )
}

function generateTooltipText(selectedEvent) {
  const eventTypeToolTipTexts = {
    [EVENT_TYPES.DYNAMIC]: 'The percentage of $ pledged out of the goal amount',
    [EVENT_TYPES.SPECIFIC_FUNDRAISING]:
      'The percentage of $ pledged out of the goal amount',
    [EVENT_TYPES.STATIC]:
      'The number of people pledged to opt in out of minimum people needed',
  }
  return eventTypeToolTipTexts[selectedEvent?.eventType]
}

export function generateProgressBarValue(event) {
  switch (event?.eventType) {
    case EVENT_TYPES.DYNAMIC:
    case EVENT_TYPES.SPECIFIC_FUNDRAISING:
      const totalMoneyPledged = event.userToEvents.reduce(
        (acc, userToEvent) => {
          return userToEvent.optedIn && userToEvent.isInvitee
            ? acc + Number(userToEvent.currentContribution)
            : acc
        },
        0
      )

      const amountPledgedOverGoalAmount =
        (totalMoneyPledged / Number(event.goalAmount)) * 100
      return amountPledgedOverGoalAmount ?? 0
    case EVENT_TYPES.STATIC:
      const totalInviteesOptedIn = event.userToEvents.reduce(
        (acc, userToEvent) => (userToEvent.optedIn ? acc + 1 : acc),
        0
      )
      return (totalInviteesOptedIn / event.minimumPeople) * 100
    default:
      return 0
  }
}

function generateProgressBarText(event) {
  switch (event?.eventType) {
    case EVENT_TYPES.DYNAMIC:
    case EVENT_TYPES.SPECIFIC_FUNDRAISING:
      let totalMoneyPledged = event.userToEvents.reduce(
        (accumulator, userToEvent) => {
          const value =
            userToEvent.optedIn && !userToEvent.isCreator
              ? parseInt(userToEvent.currentContribution)
              : 0
          return accumulator + value
        },
        0
      )
      totalMoneyPledged =
        parseInt(Math.floor(totalMoneyPledged)) / USDC_DECIMALS

      console.log('nick 2334 4', {
        goalAmount: event.goalAmount,
      })
      const amountPledgedOverGoalAmount =
        totalMoneyPledged /
        convertIntTo2DecimalFloat(parseInt(event.goalAmount))

      return amountPledgedOverGoalAmount
        ? `${Math.round(amountPledgedOverGoalAmount * 100)}%`
        : '0%'
    case EVENT_TYPES.STATIC:
      const totalInviteesOptedIn = event.userToEvents.reduce(
        (accumulator, userToEvent) =>
          accumulator + (userToEvent.optedIn && !userToEvent.isCreator ? 1 : 0),
        0
      )
      const totalInviteesNeeded = event.minimumPeople
      return `${totalInviteesOptedIn} / ${totalInviteesNeeded}`
    default:
      return ''
  }
}

export { LinearProgressWithLabel }
