import "../../styles/TermsOfUseDocx.css";

export const TermsOfUseDocx = () => {

    return (
        <>
            <div>
  <p style={{ textAlign: "center", fontSize: "10pt" }}>
    <strong>TIPPING POINT TERMS OF USE</strong>
  </p>
  <p style={{ textAlign: "justify", fontSize: "10pt" }}>&nbsp;</p>
  <p style={{ textAlign: "justify", fontSize: "10pt" }}>
    <strong>Last Modified: </strong>
    <strong>
      <span>08/02/2024</span>
    </strong>
    <strong></strong>
  </p>
  <p style={{ textAlign: "justify", fontSize: "10pt" }}>&nbsp;</p>
  <p style={{ textAlign: "justify", fontSize: "10pt" }}>
    These Terms of Use (the "<strong>Terms</strong>") explain the terms and
    conditions by which you may access and use the Service provided by Tipping
    Point (referred to herein as "<strong>Tipping Point</strong>", "
    <strong>we</strong>", "<strong>our</strong>", or "<strong>us</strong>
    ").&nbsp; The Service shall include, but shall not necessarily be limited
    to, (a)&nbsp; tippingpoint.app, a website-hosted user interface (the "
    <strong>Site</strong>"), (b) the technology, platform, and resources
    available through or enabled via the Site, and (c) any other products and
    services that link to this Agreement (collectively, the “
    <strong>Service</strong>”).
  </p>
  <p style={{ textAlign: "justify", fontSize: "10pt" }}>&nbsp;</p>
  <p style={{ textAlign: "justify", fontSize: "10pt" }}>
    You must read this Agreement carefully as it governs your use of the
    Service. By clicking “Accept Terms” or by using the Services (including by
    connecting your wallet to our UI), you represent that (1) you have read and
    understand, and agree to be bound by, the Terms of Use, (2) you are of legal
    age to form a binding contract with Tipping Point, and (3) you have the
    authority to enter into the Terms personally or on behalf of the entity
    using the Services, and to bind that entity to the Terms of Use. If you do
    not agree, you are not authorized to access or use any of the Services. If
    you do not agree, you are not authorized to access or use any of our
    Products and should not use our Products.
  </p>
  <p
    className="NormalWeb"
    style={{
      marginTop: "14pt",
      marginBottom: "14pt",
      textAlign: "justify",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span className="Strong" style={{ fontFamily: "Arial" }}>
      SECTION 13.2 (DISPUTE RESOLUTION) OF THIS AGREEMENT CONTAINS PROVISIONS
      GOVERNING HOW DISPUTES BETWEEN YOU AND TIPPING POINT WILL BE RESOLVED,
      INCLUDING, WITHOUT LIMITATION, ANY DISPUTES THAT AROSE OR WERE ASSERTED
      PRIOR TO THE EFFECTIVE DATE OF THIS AGREEMENT. SPECIFICALLY, SECTION 16
      CONTAINS AN ARBITRATION AGREEMENT THAT WILL, WITH LIMITED EXCEPTIONS,
      REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO BINDING AND FINAL
      ARBITRATION. UNDER THIS AGREEMENT: (1) YOU WILL ONLY BE PERMITTED TO
      PURSUE DISPUTES OR CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL
      BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
      ACTION OR PROCEEDING; AND (2) YOU ARE WAIVING YOUR RIGHT TO PURSUE
      DISPUTES OR CLAIMS ND SEEKE RELIEF IN A COURT OF LAW AND TO HAVE A JURY
      TRIAL. For more information, see the section in these Terms entitled
      “Dispute Resolution, Class Action Waiver, and Jury Trial Waiver.
    </span>
  </p>
  <ol className="awlist1" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Description of Services<span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <ol className="awlist2" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      The User Interface and Protocol
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{ textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause2"
    style={{ textIndent: "0pt", textAlign: "justify", fontSize: "10pt" }}
  >
    The User Interface (“<strong>UI</strong>”) provides a web or mobile-based
    means of access to a set of smart contracts (the “<strong>Protocol</strong>
    ”) on the Ethereum blockchain that allows users to transfer certain
    compatible digital assets (“<strong>User Assets</strong>”) from a user’s
    non-custodial wallet (“<strong>Sender Wallet</strong>”) to the non-custodial
    wallet of another user (a “<strong>Recipient Wallet</strong>”).
  </p>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "0pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause2"
    style={{ textIndent: "0pt", textAlign: "justify", fontSize: "10pt" }}
  >
    Using the UI, a user may interact with a self-executing smart contract that
    causes a transfer of User Assets pledged by other users upon triggering
    certain pre-determined conditions. A user who pledges User Assets to a
    particular recipient makes an irrevocable commitment to contribute such
    digital assets to the Recipient’s Wallet once such conditions are triggered.
    Upon the triggering of such conditions, the Protocol will automatically
    transfer the pledged User Assets from the Sender’s Wallet to the Recipient’s
    Wallet.
  </p>
  <p
    className="MFParasubclause2"
    style={{ textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause2"
    style={{ textIndent: "0pt", textAlign: "justify", fontSize: "10pt" }}
  >
    The UI is distinct from the Protocol and is one, but not the exclusive,
    means of accessing the Protocol.{" "}
    <a id="_Hlk172881157">
      <span style={{ backgroundColor: "#ffffff" }}>
        While Tipping Point does have the ability to create and structure the
        protocol in
      </span>
      <span style={{ backgroundColor: "#ffffff" }}>&nbsp;</span>
      <span style={{ backgroundColor: "#ffffff" }}>
        the first place, once deployed to the Ethereum network the smart
        contracts that compose the Protocol are immutable and uncensorable.
        Tipping Point may change the Protocol by
      </span>
      <span style={{ backgroundColor: "#ffffff" }}>&nbsp;</span>
      <span style={{ backgroundColor: "#ffffff" }}>
        changing the smart contracts within (e.g. to add new features or fix
        bugs, etc.), but those contracts cannot be changed by Tipping Point (or
        anyone else) once deployed to the Ethereum blockchain
      </span>
      .
    </a>{" "}
    By using the UI, you understand that you are not buying or selling digital
    assets from us and that we do not operate the Protocol or control the
    movement of digital assets to or from your wallet.
  </p>
  <p
    className="MFParasubclause2"
    style={{ textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause2"
    style={{ textIndent: "0pt", fontSize: "10pt" }}
  >
    To access the Interface, you must use a non-custodial wallet software, which
    allows you to interact with the Ethereum blockchain. Your relationship with
    that non-custodial wallet provider is not governed by these Terms but is
    governed by the applicable terms of service. Tipping Point does not have
    custody or control over your wallet's contents and cannot retrieve or
    transfer funds from any wallet used in connection with the Service.
  </p>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist3"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Other Services<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    We may, from time to time in the future, offer additional services, and such
    additional services shall be considered a Service as used herein, regardless
    of whether such service is specifically defined in this Agreement.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist4"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>{" "}
      User Assets<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    When you use the Service, you represent and warrant that (a) you own or have
    the authority to deploy your User Assets; and (b) all User Assets you send
    or receive or otherwise make available in connection with the Service have
    been earned, received, or otherwise acquired by you in compliance with all
    applicable laws.
  </p>
  <p className="MFParasubclause1" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist5"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Compatibility<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The Services may not be compatible with all forms of digital assets, and
    certain of your User Assets may not be compatible with the Services. Whether
    or not a User Asset is compatible with the Services may change at any time,
    in Tipping Point’s sole discretion, with or without notice to you.
  </p>
  <p className="MFParasubclause1" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={5}
    className="awlist6"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Taxes<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    You are solely responsible for determining what, if any, taxes apply to any
    transactions involving your User Assets, including your receipt of any User
    Assets.
  </p>
  <p className="MFParasubclause1" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist7"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Modifications to Terms or Services
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol className="awlist8" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Modifications to Terms.
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p style={{ marginLeft: "49.5pt", textAlign: "justify", fontSize: "10pt" }}>
    We may modify the Terms at any time at our sole discretion. If we do so, we
    will let you know either by posting the modified Terms on the Site, by
    providing you a notice through the Service, or through other methods of
    communication that we deem reasonable. The modified Terms will be effective
    at the time they are posted on the Site. It’s important that you review the
    Terms whenever we modify them because if you continue to use the Services
    after we have modified the Terms, you agree to be bound by the modified
    Terms. If you do not agree to be bound by the modified Terms, then you may
    not use the Services.
  </p>
  <p
    className="MFParasubclause1"
    style={{ textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <ol
    start={2}
    className="awlist9"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Modifications to Services.
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p style={{ marginLeft: "49.5pt", textAlign: "justify", fontSize: "10pt" }}>
    Our Services are evolving over time, and we may change or discontinue all or
    any part of the Services at any time and without notice, at our sole
    discretion.
  </p>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist10"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Use of Services.
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <ol className="awlist11" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      License to Use Services.
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    Subject to these Terms, we grant you a limited, revocable, non-exclusive,
    non-sublicensable, non-transferable license to access and use the Services
    solely in accordance with this Agreement. Unless otherwise specified by
    Tipping Point in a separate license, your right to use any and all Services
    is subject to this Agreement. You acknowledge and agree that nothing set
    forth herein shall be construed as a sale of any ownership interest in or to
    the Services or any intellectual property rights associated therewith.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist12"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Eligibility<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <span style={{ backgroundColor: "#ffffff" }}>
      You may use the Services if you are 18 years or older and are not barred
      from using the Services under applicable law.{" "}
    </span>
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist13"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Registration and Account<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    If you want to use the Services, you must create an account (
    <strong>“Account”</strong>). You agree that you won’t disclose your Account
    credentials to anyone, and you’ll notify us immediately of any unauthorized
    use of your Account. If you suspect that your Account or any of your
    security details have been compromised or if you become aware of any fraud
    or attempted fraud or any other security incident (including a
    cyber-security attack) affecting you, your Account, and/or Tipping Point,
    you must notify us immediately&nbsp;and provide accurate and up to date
    information throughout the duration of the incident.
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    You’re responsible for all activities that occur under your Account, or are
    otherwise referable to your Account credentials, whether or not you know
    about them. We reserve the right to suspend or terminate your Account,
    including if you provide inaccurate, untrue, or incomplete information, or
    if you fail to comply with the Account registration requirements or these
    terms.
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    In registering an Account, you agree to (a) provide true, accurate, current,
    and complete information about yourself as prompted by the registration
    form; and (b) maintain and promptly update any information provided about
    yourself to us. You represent that you are (i) at least 18 years old; and
    (ii) not a person barred from using the Services under the laws of the
    United States, your place of residence or any other applicable jurisdiction.
    If you are acting on behalf of an entity, you represent you have all right
    and authority necessary to act on behalf of such entity. You acknowledge and
    agree that our obligation tt provide you with any Services is conditioned on
    the information you provide to us being accurate and complete at all times
    during the term of this Agreement.
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    You are responsible for all activities that occur under your Account. You
    agree to monitor your account and to restrict use by anyone other than you,
    and you will accept full responsibility for any unauthorized use of the
    Services.
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      textAlign: "justify",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    You may not share your Account or password with anyone, and you agree to (y)
    notify Tipping Point immediately of any unauthorized use of your password or
    any other breach of security; and (z) exit from your Account at the end of
    each session. If you provide any information that is untrue, inaccurate, not
    current or incomplete, or Tipping Point has reasonable grounds to suspect
    that any information you provide is untrue, inaccurate, not current or
    incomplete,
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      textAlign: "justify",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    Tipping Point has the right to suspend or terminate your Account and refuse
    any and all current or future use of the Services (or any portion thereof).
    You agree not to create an Account using a false identity or information, or
    on behalf of someone other than yourself. You agree not to create an Account
    or use the Services if you have been previously removed by Tipping Point, or
    if you have been previously banned from any of the Services.
  </p>
  <ol
    start={4}
    className="awlist14"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Certain Restrictions<span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The Services are intended for your internal use only. The rights granted to
    you in the Agreement are subject to the following restrictions: (a) you
    shall not use, modify, distribute, merge, make derivative works of, tamper
    with, reverse engineer, disassemble or decompile any of our Service for any
    purpose other than as expressly permitted pursuant to this Agreement; (b)
    you shall not license, sell, rent, lease, transfer, assign, reproduce,
    distribute, host, or otherwise commercially exploit the Services or any
    portion of the Services, including the Site; (c) you shall not frame or
    utilize framing techniques to enclose any trademark, logo or other Services
    of Tipping Point; (d) you shall not use any of Tipping Point’s name or
    trademarks; (e) you shall not use any manual or automated software, devices
    or other processes to scrape or download data from any web pages contained
    in the Site; (f) except as expressly stated herein, no part of the Services
    may be copied, reproduced, distributed, republished, downloaded, displayed,
    posted or transmitted in any form or by any means; and (h) you shall not
    remove or destroy any copyright notices or other proprietary markings
    contained on or in the Services. Any unauthorized use of the services may
    terminate the licenses granted by Tipping Point pursuant to the Agreement.
  </p>
  <p className="MFParasubclause1" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={5}
    className="awlist15"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Third Party Services and Content
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p style={{ marginLeft: "50.4pt", textAlign: "justify", fontSize: "10pt" }}>
    Our Services may contain references or links to third-party services,
    including, but not limited to, information, materials, products, or services
    (“<strong>Third Party Materials</strong>”), that we do not own or control.
    We do not approve, monitor, endorse, warrant or assume any responsibility
    for any Third Party Materials. If you access any such Third Party Materials,
    you do so at your own risk, and you understand that this Agreement does not
    apply to your dealings or relationships with any third parties. You
    expressly relieve us of any and all liability arising from your use of any
    Third Party Materials.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist16"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Ownership and Control<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <ol className="awlist17" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Intellectual Property Rights Generally
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    You acknowledge and agree that as between you and Tipping Point, Tipping
    Point owns all rights, title and interest in the Services, including, but
    not limited to, any software, computer code, algorithms, technology, themes,
    objects, concepts, artwork, animations, sounds, methods of operation,
    software, text, images, and documentation, as well as all intellectual
    property and proprietary rights related thereto, including trademarks,
    service marks, copyrights, patents, designs, and its "look and feel." You
    will not remove, alter or obscure any copyright, trademark, service mark, or
    other proprietary rights notices incorporated in or accompanying any
    Services.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist18"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        marginLeft: "86.4pt",
        textIndent: "-36pt",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      User Content<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="NormalWeb"
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>“</span>
    <strong>
      <span style={{ fontFamily: "Arial" }}>User Content</span>
    </strong>
    <span style={{ fontFamily: "Arial" }}>
      ” means any Content that Account holders (including you) make available
      through the Services.{" "}
    </span>
  </p>
  <p
    className="NormalWeb"
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      textAlign: "justify",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>
      In order to operate and provide our Services, you grant us a worldwide,
      non-exclusive, royalty-free, sublicensable, and transferable license to
      use, copy, distribute, create derivative works of, display, and perform
      the User Content that you upload, submit, store, send, or receive using
      the Product or through our Services. The rights you grant in this license
      are for the limited purpose of operating and providing our Services.
      Additional information about your privacy and how we use User Content is
      available in the Privacy Policy.
    </span>
  </p>
  <p
    className="NormalWeb"
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      textAlign: "justify",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>
      You warrant and represent that you have the right and authority to submit
      your User Content and that the User Content or any part thereof does not
      infringe the intellectual property rights or any other rights of any third
      party.
    </span>
  </p>
  <p
    className="NormalWeb"
    style={{
      marginTop: "14pt",
      marginLeft: "50.4pt",
      marginBottom: "14pt",
      textAlign: "justify",
      fontSize: "10pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>
      You acknowledge that, in certain instances, where you have removed your
      User Content by deleting it, some of your User Content (such as posts or
      comments you make) may not be completely removed and copies of your User
      Content may continue to exist on the Services. We are not responsible or
      liable for the removal or deletion of (or the failure to remove or delete)
      any of your User Content.
    </span>
  </p>
  <ol
    start={5}
    className="awlist19"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      User Conduct<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <ol className="awlist20" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Prohibited Activity<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    You agree that you are solely responsible for your conduct in connection
    with the Services<strong>. </strong>You agree that you will abide by this
    Agreement and will not (and will not attempt to):
  </p>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    <strong>&nbsp;</strong>
  </p>
  <ol className="awlist21" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.88pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>provide</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>false</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.95pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>misleading</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.95pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>information</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Tipping Point;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist22"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.33pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>attempt</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.95pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>another</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>user’s</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Account</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>without authorization</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>from</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>such</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>user;</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "0pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist23"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.88pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>pose</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>as</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>another person</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>entity;</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist24"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.33pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>in</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>manner</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>that</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>could</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>interfere</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>with,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>disrupt,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>negatively</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>affect</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>inhibit</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>other</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>users</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>from</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>fully</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>enjoying</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>that</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>could</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>damage,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>disable,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>overburden</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>impair</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>functioning</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>of</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>in</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.45pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>manner;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={5}
    className="awlist25"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.88pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>develop,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>utilize,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>disseminate</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.45pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>software,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>interact</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>with</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>API</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>in</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>manner,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>that</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.6pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>could</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>damage,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>harm,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>impair</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.6pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={6}
    className="awlist26"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "25.11pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>bypass</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.6pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>circumvent</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.6pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>measures</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>employed</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>prevent or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>limit access</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>service,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>area,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>code</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>of</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={7}
    className="awlist27"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.33pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>attempt</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>circumvent</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>content-filtering</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>techniques</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>we</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>employ;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={8}
    className="awlist28"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.33pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>collect</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>harvest data</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>from our</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>that would</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>allow</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>you</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.75pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.75pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>contact</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>individuals,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>companies,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>other</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>persons</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>entities,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>such</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>data</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.75pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>contact</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>such</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>entities;</span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={9}
    className="awlist29"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "25.66pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.95pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>data</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>collected</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>from</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.85pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>our</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.95pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>for</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.95pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>direct</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.85pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>marketing</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>activity</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>(including</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>without</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>limitation,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>email</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>marketing,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>SMS</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>marketing,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>telemarketing,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>and</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>direct</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>marketing);</span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={10}
    className="awlist30"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "25.66pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>bypass</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>ignore</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>instructions</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>that</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>control</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>all</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>automated</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>access</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={11}
    className="awlist31"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.88pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Service</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>for</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>illegal</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>unauthorized</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>purpose,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>engage</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>in,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>encourage,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or promote</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>activity</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>that violates</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>applicable</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>law</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>this</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Agreement;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.45pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={12}
    className="awlist32"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "25.66pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>your</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.75pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Account</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>carry</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>out</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>illegal</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>activities</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>in</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>connection</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>with</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>in</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>any</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>way</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>related</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>your</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>access</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>and</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>use</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>of</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.7pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>including</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>but</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.7pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>not</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>limited</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.65pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.5pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>money</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>laundering,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>terrorist</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.35pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>financing</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>or</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>deliberately</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>engaging</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>in</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>activities</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>designed</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>to</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>adversely</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.4pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>affect</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.45pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "-0.05pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>performance</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>of</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>the</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>Services;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={13}
    className="awlist33"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "19.55pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>engage</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.8pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>
        in activity that activity that violates any applicable law, rule, or
        regulation concerning the trading of securities or derivatives,
        including, but not limited to, the unregistered offering of securities
        and the offering of leveraged and margined commodity products to retail
        customers in the United States.
      </span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={14}
    className="awlist34"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.33pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        engage in buying, selling, or transferring of stolen items, fraudulently
        obtained items, items taken without authorization, and/or any other
        illegally obtained items.
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={15}
    className="awlist35"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.33pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        engage in any activity that seeks to defraud us or any other person or
        entity, including, but not limited to, providing any false, inaccurate,
        or misleading information in order to unlawfully obtain the property of
        another.
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{
      marginLeft: "121.5pt",
      textIndent: "-35.1pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={16}
    className="awlist36"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        marginLeft: "121.5pt",
        textIndent: "-35.1pt",
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold"
      }}
    >
      <span
        style={{
          width: "22.33pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        engage in any activity that violates any applicable law, rule, or
        regulation of the United States or another relevant jurisdiction,
        including, but not limited to, the restrictions and regulatory
        requirements imposed by U.S. law.
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "0pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={6}
    className="awlist37"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Disclaimers<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <ol className="awlist38" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      No Custody or Fiduciary Duty
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Tipping Point does not ever have custody, possession, or control of User
    Assets at any time. You are solely responsible for the custody of the
    cryptographic private keys to the digital asset wallets you hold, and you
    should never share your wallet credentials or seed phrase with anyone. We
    accept no responsibility for, or liability to you, in connection with your
    use of a wallet, and make no representations or warranties regarding how any
    of our Services will operate with any specific wallet. Likewise, you are
    solely responsible for any associated wallet, and we are not liable for any
    acts or omissions by you in connection with or as a result of your wallet
    being compromised.
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist39"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Assumption of Risk<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol className="awlist21" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You understand and acknowledge that Tipping Point is not a financial or
        investment advisor and that the Services entail a risk of loss of your
        digital assets. All transactions submitted by you using our Services are
        unsolicited, which means they are solely initiated by you.
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist22"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        The Services provided by Tipping Point rely on the Ethereum blockchain,
        which may not be reliable, consistent, or dependent in all scenarios.
        Tipping Point does not control the functionality of the Ethereum
        blockchain and may not be able to foresee or anticipate technical or
        other difficulties which may result in data loss or other service
        interruptions. Tipping Point does not make any representation or
        warranty as to the safety, functionality, or availability of the
        Ethereum blockchain and the Protocol.
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "0pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist23"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        By accessing and using any of our Services, you represent that you are
        financially and technically sophisticated enough to understand the
        inherent risks associated with using cryptographic and blockchain-based
        systems, and that you have a working knowledge of the usage and
        intricacies of digital assets such as Ether (ETH), so-called
        stablecoins, and other digital tokens such as those following the
        ethereum token standard (ERC-20).
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "0pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist24"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You understand that Tipping Point is not responsible for verifying the
        accuracy of information provided or statements made by Recipients using
        the Services and does not make any representation or warranty regarding
        any Campaign, Sender, or Recipient. Each user should conduct his or her
        own due diligence regarding each Campaign, Sender, and Recipient prior
        to transacting using the Services.
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "86.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={5}
    className="awlist25"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You understand that by pledging your User Assets, you irrevocably commit
        to transfer such pledged User Assets to the applicable Recipient. A
        pledge is irreversible by the pledging user, and the Protocol will
        automatically execute a transfer of pledged User Assets to the Recipient
        Wallet upon the triggering of certain pre-determined conditions.{" "}
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={6}
    className="awlist26"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "26.01pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You acknowledge and accept that the cost and speed of transacting with
        cryptographic and blockchain-based systems such as Ethereum are variable
        and may increase dramatically at any time.{" "}
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={7}
    className="awlist27"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You acknowledge and accept that we are not responsible for any of these
        variables or risks, do not own or control the Protocol, and cannot be
        held liable for any resulting losses that you experience while accessing
        or using any of our Services. Accordingly, you understand and agree to
        assume full responsibility for all of the risks of accessing and using
        the UI to interact with the Protocol.
      </span>
    </li>
  </ol>
  <p className="ListParagraph" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={8}
    className="awlist28"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You understand that like any software, the Service and the Protocol
        could be at risk of third-party malware, hacks or cybersecurity
        breaches. You agree it is your responsibility to monitor your User
        Assets regularly and confirm their proper use and deployment consistent
        with your intentions.{" "}
      </span>
    </li>
  </ol>
  <p className="ListParagraph" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={9}
    className="awlist29"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "26.56pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        There are risks associated with using digital assets, including but not
        limited to, the risk of hardware, software, and Internet connections;
        the risk of malicious software introduction; the risk that third parties
        may obtain unauthorized access to information stored within your
        Account. You accept and acknowledge that Tipping Point will not be
        responsible for any communication failures, disruptions, errors,
        distortions, delays, or losses you may experience when using blockchain
        technology or the Services, however caused.
      </span>
    </li>
  </ol>
  <p className="ListParagraph" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={10}
    className="awlist30"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "26.56pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You control your non-custodial wallets used in connection with the
        Services, and Tipping Point is not responsible for its performance, nor
        any risks associated with the use thereof.
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={11}
    className="awlist31"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You acknowledge and accept that while
      </span>
      <span style={{ fontWeight: "normal", backgroundColor: "#ffffff" }}>
        {" "}
        Tipping Point does have the ability to create and structure the protocol
        in
      </span>
      <span style={{ fontWeight: "normal", backgroundColor: "#ffffff" }}>
        &nbsp;
      </span>
      <span style={{ fontWeight: "normal", backgroundColor: "#ffffff" }}>
        the first place, once deployed to the Ethereum network the smart
        contracts that compose the Protocol are immutable and uncensorable.
        Tipping Point may change the Protocol by
      </span>
      <span style={{ fontWeight: "normal", backgroundColor: "#ffffff" }}>
        &nbsp;
      </span>
      <span style={{ fontWeight: "normal", backgroundColor: "#ffffff" }}>
        changing the smart contracts within (e.g. to add new features or fix
        bugs, etc.), but those contracts cannot be changed by Tipping Point (or
        anyone else) once deployed to the
      </span>
      <span style={{ fontWeight: "normal", backgroundColor: "#ffffff" }}>
        &nbsp;
      </span>
      <span style={{ fontWeight: "normal", backgroundColor: "#ffffff" }}>
        Ethereum blockchain,
      </span>
      <span style={{ fontWeight: "normal" }}>
        {" "}
        and Tipping Point cannot be held liable for any resulting losses that
        you experience while accessing or using the Protocol. Accordingly, you
        understand and agree to assume full responsibility for all of the risks
        of accessing and using the UI to interact with the Protocol.
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={12}
    className="awlist32"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "26.56pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        You acknowledge and accept that all data, information, and other content
        is the sole responsibility of the party from whom such content
        originated. This means that you, not Tipping Point, are entirely
        responsible for all your content that you upload, post, e-mail,
        transmit, or otherwise make available through any Services.
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist40"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Compliance and Tax Obligations
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Your use of our Services or the Protocol may result in various tax
    consequences, such as income or capital gains tax, value-added tax, goods
    and services tax, or sales tax in certain jurisdictions. By accessing or
    using any of our Services, you agree that you are solely and entirely
    responsible for compliance with all laws and regulations that may apply to
    you.
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Tipping Point is not obligated to provide the information necessary to
    report and determine your tax liability from using the Services. It is your
    responsibility to determine whether taxes apply to any transactions you
    initiate or receive and, if so, to report and/or remit the correct tax to
    the appropriate tax authority.
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={7}
    className="awlist41"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Fees<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", textAlign: "justify" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Tipping Point may charge fees (“<strong>Fees</strong>”) in connection with
    your use of certain Services. All pricing and payment terms for such Fees
    are as indicated on the Service, and any payment obligations you incur are
    binding at the time of the applicable transaction.
  </p>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={8}
    className="awlist42"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Term and Termination<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol className="awlist43" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Term.
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p style={{ marginLeft: "49.5pt", textAlign: "justify", fontSize: "10pt" }}>
    The Agreement commences on the date you accept it (as described in the
    preamble above) and remains in full force and effect while you use the
    Services unless terminated earlier in accordance with the Agreement.
  </p>
  <p
    className="MFParasubclause1"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist44"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Prior Use<span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Notwithstanding the foregoing, you hereby acknowledge and agree that the
    Agreement commenced on the earlier of (a) the date you first used Services
    or (b) the date you accepted the Agreement, and will remain in full force
    and effect while you use any Services, unless earlier terminated in
    accordance with the Agreement.
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist45"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Termination of Services by Tipping Point
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Tipping Point reserves the right to terminate this Agreement and your access
    to the Services at any time, for any or for no reason, with or without
    notice to you.
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist46"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Termination by You<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    If you want to terminate the Services provided by Tipping Point, you may do
    so by (a) notifying us at any time and (b) closing your Account for all of
    the Services that you use. Your notice should be sent in writing to our
    email address set forth below.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={5}
    className="awlist47"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "22.1pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Effect of Termination<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Termination of any Service includes removal of access to such Service and
    barring of further use of the Service. Termination of all Services also
    includes blacklisting your wallet from connecting to the Tipping Point UI.
    Upon termination of any Service, your right to use such Service will
    automatically terminate immediately. You understand that any termination of
    Services may involve deletion of your User Content associated therewith from
    our live databases. Tipping Point will not have any liability whatsoever to
    you for any suspension or termination, including for deletion of your User
    Content. All provisions of the Agreement which by their nature should
    survive, shall survive termination of Services, including without
    limitation, ownership provisions, warranty disclaimers, indemnification and
    limitation of liability.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={9}
    className="awlist48"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "20.46pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Indemnification
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    You agree to indemnify and hold Tipping Point, its parents, subsidiaries,
    affiliates, officers, employees, agents, partners, suppliers, and licensors
    (each, a “<strong>Tipping Point Party</strong>” and collectively, the “
    <strong>Tipping Point Parties</strong>”) harmless from any losses, costs,
    liabilities and expenses (including reasonable attorneys’ fees) relating to
    or arising out of any and all of the following: (a) your content; (b) your
    use of, or inability to use, any Services; (c) your violation of the
    Agreement, including any of your representations or warranties hereunder;
    (d) your violation of any rights of another party, including any Tipping
    Point Users; (e) your failure to provide accurate or complete data in
    connection with your use of the Services; or (f) your violation of any
    applicable laws, rules or regulations. Tipping Point reserves the right, at
    its own cost, to assume the exclusive defense and control of any matter
    otherwise subject to indemnification by you, in which event you will fully
    cooperate with Tipping Point in asserting any available defenses. This
    provision does not require you to "indemnify any of the Tipping Point
    Parties for any unconscionable commercial practice by such party or for such
    party’s fraud, deception, false promise, misrepresentation or concealment,
    or suppression or omission of any material fact in connection with the Site
    or any Services provided hereunder. You agree that the provisions in this
    section will survive any termination of your Account, the Agreement and/or
    your access to Services.
  </p>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={10}
    className="awlist49"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "14.9pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Release<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    TO THE MAXIMUM EXTENT PERMISSIBLE BY APPLICABLE LAW, YOU HEREBY RELEASE THE
    TIPPING POINT PARTIES FROM ANY AND ALL LIABILITY, CLAIMS, DEMANDS, ACTIONS,
    AND<span style={{ letterSpacing: "1.6pt" }}> </span>CAUSES
    <span style={{ letterSpacing: "1.65pt" }}> </span>OF
    <span style={{ letterSpacing: "1.65pt" }}> </span>ACTION,
    <span style={{ letterSpacing: "1.65pt" }}> </span>WHATSOEVER,
    <span style={{ letterSpacing: "1.65pt" }}> </span>ARISING
    <span style={{ letterSpacing: "1.6pt" }}> </span>OUT
    <span style={{ letterSpacing: "1.65pt" }}> </span>OF
    <span style={{ letterSpacing: "1.65pt" }}> </span>OR
    <span style={{ letterSpacing: "1.6pt" }}> </span>RELATED
    <span style={{ letterSpacing: "1.6pt" }}> </span>TO
    <span style={{ letterSpacing: "1.6pt" }}> </span>ANY
    <span style={{ letterSpacing: "1.6pt" }}> </span>LOSS WHICH MAY BE SUSTAINED
    BY YOU WHILE USING, ARISING OUT OF, OR IN CONNECTION WITH
    <span style={{ letterSpacing: "0.75pt" }}> </span>THE
    <span style={{ letterSpacing: "0.8pt" }}> </span>USE
    <span style={{ letterSpacing: "0.75pt" }}> </span>OF
    <span style={{ letterSpacing: "0.8pt" }}> </span>THE
    <span style={{ letterSpacing: "0.9pt" }}> </span>SERVICES,
    <span style={{ letterSpacing: "0.8pt" }}> </span>INCLUDING
    <span style={{ letterSpacing: "0.75pt" }}> </span>ANY
    <span style={{ letterSpacing: "0.75pt" }}> </span>DIMUNITION
    <span style={{ letterSpacing: "0.75pt" }}> </span>OF
    <span style={{ letterSpacing: "0.8pt" }}> </span>VALUE
    <span style={{ letterSpacing: "0.8pt" }}> </span>TO
    <span style={{ letterSpacing: "0.75pt" }}> </span>OR
    <span style={{ letterSpacing: "0.75pt" }}> </span>LOSS OR
    <span style={{ letterSpacing: "3.25pt" }}> </span>THEFT
    <span style={{ letterSpacing: "3.3pt" }}> </span>OF
    <span style={{ letterSpacing: "3.3pt" }}> </span>ANY
    <span style={{ letterSpacing: "3.25pt" }}> </span>USER
    <span style={{ letterSpacing: "3.25pt" }}> </span>ASSETS.
    <span style={{ letterSpacing: "3.3pt" }}> </span>TO
    <span style={{ letterSpacing: "3.25pt" }}> </span>THE
    <span style={{ letterSpacing: "3.3pt" }}> </span>MAXIMUM
    <span style={{ letterSpacing: "3.3pt" }}> </span>EXTENT
    <span style={{ letterSpacing: "3.3pt" }}> </span>PERMISSIBLE
    <span style={{ letterSpacing: "3.25pt" }}> </span>BY APPLICABLE
    <span style={{ letterSpacing: "1.5pt" }}> </span>LAW,
    <span style={{ letterSpacing: "1.6pt" }}> </span>THIS
    <span style={{ letterSpacing: "1.5pt" }}> </span>RELEASE
    <span style={{ letterSpacing: "1.5pt" }}> </span>IS
    <span style={{ letterSpacing: "1.5pt" }}> </span>BINDING
    <span style={{ letterSpacing: "1.5pt" }}> </span>UPON
    <span style={{ letterSpacing: "1.5pt" }}> </span>YOUR
    <span style={{ letterSpacing: "1.5pt" }}> </span>RELATIVES,
    <span style={{ letterSpacing: "1.5pt" }}> </span>SPOUSE,
    <span style={{ letterSpacing: "1.55pt" }}> </span>HEIRS, NEXT
    <span style={{ letterSpacing: "2.7pt" }}> </span>OF
    <span style={{ letterSpacing: "2.7pt" }}> </span>KIN,
    <span style={{ letterSpacing: "2.75pt" }}> </span>EXECUTORS,
    <span style={{ letterSpacing: "2.7pt" }}> </span>ADMINISTRATORS,
    <span style={{ letterSpacing: "2.7pt" }}> </span>BENEFICIARIES,
    <span style={{ letterSpacing: "2.95pt" }}> </span>PARTNERS,
    <span style={{ letterSpacing: "2.75pt" }}> </span>AND
    <span style={{ letterSpacing: "2.7pt" }}> </span>ANY OTHER
    <span style={{ letterSpacing: "-0.1pt" }}> </span>AFFILIATES OR INTERESTED
    <span style={{ letterSpacing: "-0.05pt" }}> </span>PARTIES.
  </p>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    To<span style={{ letterSpacing: "0.9pt" }}> </span>the
    <span style={{ letterSpacing: "0.85pt" }}> </span>maximum
    <span style={{ letterSpacing: "0.85pt" }}> </span>extent
    <span style={{ letterSpacing: "0.9pt" }}> </span>permissible
    <span style={{ letterSpacing: "0.95pt" }}> </span>by
    <span style={{ letterSpacing: "0.8pt" }}> </span>applicable
    <span style={{ letterSpacing: "0.85pt" }}> </span>law,
    <span style={{ letterSpacing: "0.95pt" }}> </span>you
    <span style={{ letterSpacing: "0.95pt" }}> </span>waive
    <span style={{ letterSpacing: "0.85pt" }}> </span>and
    <span style={{ letterSpacing: "0.85pt" }}> </span>relinquish
    <span style={{ letterSpacing: "0.95pt" }}> </span>any
    <span style={{ letterSpacing: "0.85pt" }}> </span>and
    <span style={{ letterSpacing: "0.85pt" }}> </span>all
    <span style={{ letterSpacing: "1pt" }}> </span>rights
    <span style={{ letterSpacing: "0.85pt" }}> </span>and benefits
    <span style={{ letterSpacing: "-0.1pt" }}> </span>otherwise
    <span style={{ letterSpacing: "-0.1pt" }}> </span>conferred
    <span style={{ letterSpacing: "-0.15pt" }}> </span>by
    <span style={{ letterSpacing: "-0.15pt" }}> </span>any
    <span style={{ letterSpacing: "-0.15pt" }}> </span>statutory
    <span style={{ letterSpacing: "-0.25pt" }}> </span>or
    <span style={{ letterSpacing: "-0.1pt" }}> </span>non-statutory
    <span style={{ letterSpacing: "-0.25pt" }}> </span>law
    <span style={{ letterSpacing: "-0.3pt" }}> </span>of
    <span style={{ letterSpacing: "-0.2pt" }}> </span>any
    <span style={{ letterSpacing: "-0.25pt" }}> </span>jurisdiction
    <span style={{ letterSpacing: "-0.15pt" }}> </span>that
    <span style={{ letterSpacing: "-0.1pt" }}> </span>would
    <span style={{ letterSpacing: "-0.25pt" }}> </span>purport
    <span style={{ letterSpacing: "-0.1pt" }}> </span>to limit
    <span style={{ letterSpacing: "0.6pt" }}> </span>the
    <span style={{ letterSpacing: "0.6pt" }}> </span>scope
    <span style={{ letterSpacing: "0.6pt" }}> </span>of
    <span style={{ letterSpacing: "0.6pt" }}> </span>a
    <span style={{ letterSpacing: "0.6pt" }}> </span>release
    <span style={{ letterSpacing: "0.6pt" }}> </span>or
    <span style={{ letterSpacing: "0.6pt" }}> </span>waiver,
    <span style={{ letterSpacing: "0.55pt" }}> </span>including
    <span style={{ letterSpacing: "0.55pt" }}> </span>any
    <span style={{ letterSpacing: "0.6pt" }}> </span>all
    <span style={{ letterSpacing: "0.6pt" }}> </span>rights
    <span style={{ letterSpacing: "0.75pt" }}> </span>and
    <span style={{ letterSpacing: "0.7pt" }}> </span>benefits
    <span style={{ letterSpacing: "0.75pt" }}> </span>which
    <span style={{ letterSpacing: "0.6pt" }}> </span>you
    <span style={{ letterSpacing: "0.55pt" }}> </span>have
    <span style={{ letterSpacing: "0.6pt" }}> </span>or
    <span style={{ letterSpacing: "0.5pt" }}> </span>may
    <span style={{ letterSpacing: "1.15pt" }}> </span>have under California
    Civil Code Section 1542, which states “A GENERAL RELEASE DOES
    <span style={{ letterSpacing: "-0.05pt" }}> </span>NOT EXTEND TO
    <span style={{ letterSpacing: "-0.45pt" }}> </span>CLAIMS
    <span style={{ letterSpacing: "-0.35pt" }}> </span>THAT
    <span style={{ letterSpacing: "-0.4pt" }}> </span>THE
    <span style={{ letterSpacing: "-0.3pt" }}> </span>CREDITOR
    <span style={{ letterSpacing: "-0.4pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.4pt" }}> </span>RELEASING
    <span style={{ letterSpacing: "-0.45pt" }}> </span>PARTY
    <span style={{ letterSpacing: "-0.45pt" }}> </span>DOES
    <span style={{ letterSpacing: "-0.4pt" }}> </span>NOT
    <span style={{ letterSpacing: "-0.3pt" }}> </span>KNOW
    <span style={{ letterSpacing: "-0.35pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.4pt" }}> </span>SUSPECT
    <span style={{ letterSpacing: "-0.4pt" }}> </span>TO EXIST
    <span style={{ letterSpacing: "1pt" }}> </span>IN
    <span style={{ letterSpacing: "1.1pt" }}> </span>HIS
    <span style={{ letterSpacing: "1.05pt" }}> </span>OR
    <span style={{ letterSpacing: "1pt" }}> </span>HER
    <span style={{ letterSpacing: "1.1pt" }}> </span>FAVOR
    <span style={{ letterSpacing: "1pt" }}> </span>AT
    <span style={{ letterSpacing: "1.05pt" }}> </span>THE
    <span style={{ letterSpacing: "1.15pt" }}> </span>TIME
    <span style={{ letterSpacing: "1.05pt" }}> </span>OF
    <span style={{ letterSpacing: "1.05pt" }}> </span>EXECUTING
    <span style={{ letterSpacing: "0.95pt" }}> </span>THE
    <span style={{ letterSpacing: "1.05pt" }}> </span>RELEASE
    <span style={{ letterSpacing: "1pt" }}> </span>AND
    <span style={{ letterSpacing: "1pt" }}> </span>THAT,
    <span style={{ letterSpacing: "1.15pt" }}> </span>IF KNOWN
    <span style={{ letterSpacing: "1.55pt" }}>&nbsp; </span>BY
    <span style={{ letterSpacing: "1.55pt" }}>&nbsp; </span>HIM
    <span style={{ letterSpacing: "1.55pt" }}>&nbsp; </span>OR
    <span style={{ letterSpacing: "1.6pt" }}>&nbsp; </span>HER,
    <span style={{ letterSpacing: "1.55pt" }}>&nbsp; </span>WOULD
    <span style={{ letterSpacing: "1.55pt" }}>&nbsp; </span>HAVE
    <span style={{ letterSpacing: "1.6pt" }}>&nbsp; </span>MATERIALLY
    <span style={{ letterSpacing: "1.55pt" }}>&nbsp; </span>AFFECTED
    <span style={{ letterSpacing: "1.5pt" }}>&nbsp; </span>HIS
    <span style={{ letterSpacing: "1.6pt" }}>&nbsp; </span>OR
    <span style={{ letterSpacing: "1.6pt" }}>&nbsp; </span>HER SETTLEMENT WITH
    THE DEBTOR OR RELEASED PARTY.”
    <span style={{ letterSpacing: "3.45pt" }}> </span>You acknowledge that the
    releases in these<span style={{ letterSpacing: "0.6pt" }}> </span>Terms
    <span style={{ letterSpacing: "0.7pt" }}> </span>of
    <span style={{ letterSpacing: "0.65pt" }}> </span>Use
    <span style={{ letterSpacing: "0.65pt" }}> </span>are intended to be as
    broad<span style={{ letterSpacing: "0.6pt" }}> </span>and inclusive as
    <span style={{ letterSpacing: "0.65pt" }}> </span>permitted
    <span style={{ letterSpacing: "0.6pt" }}> </span>by law,
    <span style={{ letterSpacing: "0.6pt" }}> </span>and as a
    <span style={{ letterSpacing: "0.6pt" }}> </span>complete and continuous
    release and waiver of liability for any and all use of the Services.
  </p>
  <p className="MFPara-Clause" style={{ textIndent: "0pt", fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={11}
    className="awlist50"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "14.9pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Disclaimer of Warranties<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{
      marginLeft: "21.6pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol className="awlist51" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      As Is
      <span style={{ fontWeight: "normal" }}>
        . YOU EXPRESSLY UNDERSTAND AND AGREE THAT TO THE EXTENT PERMITTED BY
        APPLICABLE LAW, YOUR USE OF SERVICES IS AT YOUR SOLE RISK, AND SERVICES
        ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITH ALL FAULTS.
        TIPPING POINT PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES,
        REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
        INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OR CONDITIONS OF
        TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
        NON-INFRINGEMENT ARISING FROM USE OF THE WEBSITE. THE TIPPING POINT
        PARTIES MAKE NO REPRESENTATION THAT THE SERVICES OR THE PROTOCOL, WILL
        FUNCTION AS INTENDED OR BE SUITABLE FOR YOUR PURPOSES, AND YOU BEAR ALL
        RISK ASSOCIATED WITH ANY USER ASSETS THAT YOU USE IN CONNECTION
        THEREWITH. TIPPING POINT DOES NOT MAKE ANY REPRESENTATIONS OR WARRANTIES
        THAT ACCESS TO THE SERVICES OR ANY OF THE MATERIALS CONTAINED THEREIN
        WILL BE CONTINUOUS, UNINTERRUPTED, TIMELY, OR ERROR-FREE. SERVICE
        INTERRUPTIONS MAY CAUSE YOU TO BE SIGNED OUT OF YOUR ACCOUNT AND REQUIRE
        YOU TO RE-ENTER YOUR RECOVERY PHRASE TO REGAIN ACCESS
      </span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol className="awlist21" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>TIPPING POINT</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>PARTIES</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>MAKE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>NO</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>WARRANTY,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>REPRESENTATION</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>OR CONDITION</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>THAT:</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>(1)</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>SERVICES</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>WILL</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>MEET</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>YOUR</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>REQUIREMENTS;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>(2)</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>YOUR</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>USE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>OF SERVICES</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>WILL</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>BE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>UNINTERRUPTED,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>TIMELY,</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.15pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>SECURE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>OR</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>ERROR-FREE;</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>OR</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.1pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>(3)</span>
      <span style={{ fontWeight: "normal", letterSpacing: "3.2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>THE RESULTS</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>THAT</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>MAY</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.45pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>BE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>OBTAINED</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>FROM</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.45pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>USE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>OF</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.45pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>SERVICES</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>WILL</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>BE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.3pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>ACCURATE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>OR RELIABLE.</span>
      <span style={{ fontWeight: "normal", letterSpacing: "2.55pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>THE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.9pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>TIPPING POINT</span>
      <span style={{ fontWeight: "normal", letterSpacing: "0.95pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>PARTIES MAKE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>NO</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>REPRESENTATION</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>THAT</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>THE</span>
      <span style={{ fontWeight: "normal", letterSpacing: "1.25pt" }}> </span>
      <span style={{ fontWeight: "normal" }}>
        SERVICES WILL BE FREE OF THIRD PARTY MALWARE, HACKS OR OTHER CYBSECURITY
        BREACHES.
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "86.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist22"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        ANY CONTENT DOWNLOADED FROM OR OTHERWISE ACCESSED THROUGH SERVICES IS
        ACCESSED AT YOUR OWN RISK, AND YOU SHALL BE SOLELY RESPONSIBLE FOR ANY
        DAMAGE TO YOUR PROPERTY, INCLUDING, BUT NOT LIMITED TO, YOUR COMPUTER
        SYSTEM AND ANY DEVICE YOU USE TO ACCESS SERVICES, OR ANY OTHER LOSS THAT
        RESULTS FROM ACCESSING SUCH CONTENT.
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist23"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        THE SERVICES MAY BE SUBJECT TO DELAYS, CANCELLATIONS AND OTHER
        DISRUPTIONS. TIPPING POINT MAKES NO WARRANTY, REPRESENTATION OR
        CONDITION WITH RESPECT TO SERVICES, INCLUDING BUT NOT LIMITED TO, THE
        QUALITY, EFFECTIVENESS, REPUTATION AND OTHER CHARACTERISTICS OF
        SERVICES.
      </span>
    </li>
  </ol>
  <p
    className="ListParagraph"
    style={{ textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist24"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED FROM TIPPING
        POINT OR THROUGH SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE
        HEREIN.
      </span>
    </li>
  </ol>
  <p className="ListParagraph" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist52"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      <span style={{ fontWeight: "normal" }}>
        SIMILARLY, THE PROTOCOL IS PROVIDED "AS IS", AT YOUR OWN RISK, AND
        WITHOUT WARRANTIES OF ANY KIND. WHILE TIPPING POINT DOES HAVE THE
        ABILITY TO CREATE AND STRUCTURE THE PROTOCOL IN THE FIRST PLACE, ONCE
        DEPLOYED TO THE ETHEREUM NETWORK THE SMART CONTRACTS THAT COMPOSE THE
        PROTOCOL ARE IMMUTABLE AND UNCENSORABLE. TIPPING POINT MAY CHANGE THE
        PROTOCOL BY CHANGING THE SMART CONTRACTS WITHIN (E.G. TO ADD NEW
        FEATURES OR FIX BUGS, ETC.), BUT THOSE CONTRACTS CANNOT BE CHANGED BY
        TIPPING POINT (OR ANYONE ELSE) ONCE DEPLOYED TO THE ETHEREUM BLOCKCHAIN.
        NO DEVELOPER OR ENTITY INVOLVED IN CREATING THE PROTOCOL WILL BE LIABLE
        FOR ANY CLAIMS OR DAMAGES WHATSOEVER ASSOCIATED WITH YOUR USE, INABILITY
        TO USE, OR YOUR INTERACTION WITH OTHER USERS OF, THE PROTOCOL, INCLUDING
        ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR
        CONSEQUENTIAL DAMAGES, OR LOSS OF PROFITS, CRYPTOCURRENCIES, TOKENS, OR
        ANYTHING ELSE OF VALUE. WE DO NOT ENDORSE, GUARANTEE, OR ASSUME
        RESPONSIBILITY FOR ANY ADVERTISEMENTS, OFFERS, OR STATEMENTS MADE BY
        THIRD PARTIES CONCERNING ANY OF OUR PRODUCTS.
      </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist53"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        textAlign: "justify",
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      No Liability for Conduct of Third Parties
      <span style={{ fontWeight: "normal" }}>
        . You acknowledge and agree that Tipping Point Parties are not liable,
        and you agree not to seek to hold Tipping Point Parties liable, for the
        conduct of third parties, including operators of external sites, and
        that the risk of injury from such third parties rests entirely with you.
        Tipping Point shall be under no obligation to inquire into and shall not
        be liable for any damages, other liabilities or harm to any person or
        entity relating to any losses, delays, failures, errors, interruptions
        or loss of data occurring directly or indirectly by reason of
        circumstances
      </span>
      <span style={{ fontWeight: "normal" }}>&nbsp; </span>
      <span style={{ fontWeight: "normal" }}>
        beyond Tipping Point’s control, including without limitation through the
        deployment of User Assets to the Ethereum blockchain in connection with
        the Services.
      </span>
    </li>
  </ol>
  <p style={{ fontSize: "10pt" }}>&nbsp;</p>
  <ol
    start={12}
    className="awlist54"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "14.9pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Limitation of Liability<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol className="awlist55" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Disclaimer of Certain Damages
      <span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="BodyText"
    style={{ marginLeft: "49.5pt", textAlign: "justify", fontSize: "10pt" }}
  >
    YOU UNDERSTAND AND AGREE THAT, TO THE FULLEST
    <span style={{ letterSpacing: "-0.25pt" }}> </span>EXTENT
    <span style={{ letterSpacing: "-0.2pt" }}> </span>PROVIDED
    <span style={{ letterSpacing: "-0.25pt" }}> </span>BY
    <span style={{ letterSpacing: "-0.25pt" }}> </span>LAW,
    <span style={{ letterSpacing: "-0.15pt" }}> </span>IN
    <span style={{ letterSpacing: "-0.25pt" }}> </span>NO
    <span style={{ letterSpacing: "-0.25pt" }}> </span>EVENT
    <span style={{ letterSpacing: "-0.2pt" }}> </span>SHALL
    <span style={{ letterSpacing: "-0.15pt" }}> </span>TIPPING POINT
    <span style={{ letterSpacing: "-0.2pt" }}> </span>PARTIES
    <span style={{ letterSpacing: "-0.25pt" }}> </span>BE
    <span style={{ letterSpacing: "-0.2pt" }}> </span>LIABLE
    <span style={{ letterSpacing: "-0.05pt" }}> </span>FOR
    <span style={{ letterSpacing: "1.5pt" }}> </span>ANY
    <span style={{ letterSpacing: "1.5pt" }}> </span>LOSS
    <span style={{ letterSpacing: "1.65pt" }}> </span>OF
    <span style={{ letterSpacing: "1.55pt" }}> </span>PROFITS,
    <span style={{ letterSpacing: "1.55pt" }}> </span>REVENUE
    <span style={{ letterSpacing: "1.55pt" }}> </span>OR
    <span style={{ letterSpacing: "1.5pt" }}> </span>DATA,
    <span style={{ letterSpacing: "1.55pt" }}> </span>INDIRECT,
    <span style={{ letterSpacing: "1.65pt" }}> </span>INCIDENTAL,
    <span style={{ letterSpacing: "1.55pt" }}> </span>SPECIAL,
    <span style={{ letterSpacing: "1.65pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.05pt" }}> </span>CONSEQUENTIAL
    <span style={{ letterSpacing: "0.4pt" }}> </span>DAMAGES,
    <span style={{ letterSpacing: "0.4pt" }}> </span>OR
    <span style={{ letterSpacing: "0.35pt" }}> </span>DAMAGES
    <span style={{ letterSpacing: "0.35pt" }}> </span>OR
    <span style={{ letterSpacing: "0.35pt" }}> </span>COSTS
    <span style={{ letterSpacing: "0.4pt" }}> </span>DUE
    <span style={{ letterSpacing: "0.4pt" }}> </span>TO
    <span style={{ letterSpacing: "0.3pt" }}> </span>LOSS
    <span style={{ letterSpacing: "0.5pt" }}> </span>OF
    <span style={{ letterSpacing: "0.4pt" }}> </span>PRODUCTION
    <span style={{ letterSpacing: "0.35pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.05pt" }}> </span>USE,
    <span style={{ letterSpacing: "-0.05pt" }}> </span>BUSINESS
    <span style={{ letterSpacing: "-0.05pt" }}> </span>INTERRUPTION,
    <span style={{ letterSpacing: "-0.05pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.1pt" }}> </span>PROCUREMENT
    <span style={{ letterSpacing: "-0.05pt" }}> </span>OF
    <span style={{ letterSpacing: "-0.05pt" }}> </span>SUBSTITUTE
    <span style={{ letterSpacing: "-0.1pt" }}> </span>GOODS
    <span style={{ letterSpacing: "-0.05pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.1pt" }}> </span>SERVICES,
    <span style={{ letterSpacing: "-0.05pt" }}> </span>IN
    <span style={{ letterSpacing: "0.25pt" }}> </span>EACH
    <span style={{ letterSpacing: "0.35pt" }}> </span>CASE
    <span style={{ letterSpacing: "0.3pt" }}> </span>WHETHER
    <span style={{ letterSpacing: "0.2pt" }}> </span>OR
    <span style={{ letterSpacing: "0.25pt" }}> </span>NOT
    <span style={{ letterSpacing: "0.35pt" }}> </span>TIPPING POINT
    <span style={{ letterSpacing: "0.4pt" }}> </span>HAS
    <span style={{ letterSpacing: "0.25pt" }}> </span>BEEN
    <span style={{ letterSpacing: "0.25pt" }}> </span>ADVISED
    <span style={{ letterSpacing: "0.35pt" }}> </span>OF
    <span style={{ letterSpacing: "0.4pt" }}> </span>THE
    <span style={{ letterSpacing: "0.25pt" }}> </span>POSSIBILITY
    <span style={{ letterSpacing: "0.25pt" }}> </span>OF
    <span style={{ letterSpacing: "-0.05pt" }}> </span>SUCH
    <span style={{ letterSpacing: "-0.25pt" }}> </span>DAMAGES,
    <span style={{ letterSpacing: "-0.2pt" }}> </span>ARISING
    <span style={{ letterSpacing: "-0.25pt" }}> </span>OUT
    <span style={{ letterSpacing: "-0.2pt" }}> </span>OF
    <span style={{ letterSpacing: "-0.2pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.25pt" }}> </span>IN
    <span style={{ letterSpacing: "-0.25pt" }}> </span>CONNECTION
    <span style={{ letterSpacing: "-0.25pt" }}> </span>WITH
    <span style={{ letterSpacing: "-0.25pt" }}> </span>THE
    <span style={{ letterSpacing: "-0.2pt" }}> </span>AGREEMENT
    <span style={{ letterSpacing: "-0.05pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.25pt" }}> </span>USE
    <span style={{ letterSpacing: "-0.25pt" }}> </span>OF
    <span style={{ letterSpacing: "-0.05pt" }}> </span>SERVICES
    <span style={{ letterSpacing: "1.65pt" }}> </span>(INCLUDING
    <span style={{ letterSpacing: "1.75pt" }}> </span>THE
    <span style={{ letterSpacing: "1.75pt" }}> </span>PROTOCOL)
    <span style={{ letterSpacing: "1.75pt" }}> </span>OR
    <span style={{ letterSpacing: "1.75pt" }}> </span>ANY
    <span style={{ letterSpacing: "1.75pt" }}> </span>COMMUNICATIONS,
    <span style={{ letterSpacing: "-0.05pt" }}> </span>INTERACTIONS
    <span style={{ letterSpacing: "1.75pt" }}> </span>OR
    <span style={{ letterSpacing: "1.8pt" }}> </span>EXCHANGES
    <span style={{ letterSpacing: "1.8pt" }}> </span>WITH
    <span style={{ letterSpacing: "1.85pt" }}> </span>OTHER
    <span style={{ letterSpacing: "1.75pt" }}> </span>USERS
    <span style={{ letterSpacing: "1.75pt" }}> </span>OF
    <span style={{ letterSpacing: "1.85pt" }}> </span>SERVICES
    <span style={{ letterSpacing: "1.9pt" }}> </span>OR
    <span style={{ letterSpacing: "1.75pt" }}> </span>THIRD
    <span style={{ letterSpacing: "1.75pt" }}> </span>PARTIES
    <span style={{ letterSpacing: "-0.05pt" }}> </span>THAT
    <span style={{ letterSpacing: "0.7pt" }}> </span>INTERACT
    <span style={{ letterSpacing: "0.7pt" }}> </span>WITH
    <span style={{ letterSpacing: "0.75pt" }}> </span>THE
    <span style={{ letterSpacing: "0.7pt" }}> </span>SERVICES,
    <span style={{ letterSpacing: "0.7pt" }}> </span>ON
    <span style={{ letterSpacing: "0.65pt" }}> </span>ANY
    <span style={{ letterSpacing: "0.65pt" }}> </span>THEORY
    <span style={{ letterSpacing: "0.65pt" }}> </span>OF
    <span style={{ letterSpacing: "0.7pt" }}> </span>LIABILITY,
    <span style={{ letterSpacing: "0.9pt" }}> </span>INCLUDING
    <span style={{ letterSpacing: "0.65pt" }}> </span>ANY
    <span style={{ letterSpacing: "-0.05pt" }}> </span>SUCH
    <span style={{ letterSpacing: "2.7pt" }}> </span>DAMAGES
    <span style={{ letterSpacing: "2.7pt" }}> </span>RESULTING
    <span style={{ letterSpacing: "2.7pt" }}> </span>FROM:
    <span style={{ letterSpacing: "2.8pt" }}> </span>(a)
    <span style={{ letterSpacing: "2.7pt" }}> </span>LOSS
    <span style={{ letterSpacing: "2.7pt" }}> </span>OR
    <span style={{ letterSpacing: "2.7pt" }}> </span>DIMINISHMENT
    <span style={{ letterSpacing: "2.85pt" }}> </span>IN
    <span style={{ letterSpacing: "2.7pt" }}> </span>VALUE
    <span style={{ letterSpacing: "2.75pt" }}> </span>OF
    <span style={{ letterSpacing: "2.75pt" }}> </span>USER
    <span style={{ letterSpacing: "-0.05pt" }}> </span>ASSETS,
    <span style={{ letterSpacing: "-0.2pt" }}> </span>(b)
    <span style={{ letterSpacing: "-0.15pt" }}> </span>THE
    <span style={{ letterSpacing: "-0.2pt" }}> </span>USE
    <span style={{ letterSpacing: "-0.25pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.25pt" }}> </span>INABILITY
    <span style={{ letterSpacing: "-0.25pt" }}> </span>TO
    <span style={{ letterSpacing: "-0.15pt" }}> </span>USE
    <span style={{ letterSpacing: "-0.15pt" }}> </span>SERVICES;
    <span style={{ letterSpacing: "-0.15pt" }}> </span>(c)
    <span style={{ letterSpacing: "-0.15pt" }}> </span>THE
    <span style={{ letterSpacing: "-0.2pt" }}> </span>COST
    <span style={{ letterSpacing: "-0.25pt" }}> </span>OF
    <span style={{ letterSpacing: "-0.2pt" }}> </span>PROCUREMENT
    <span style={{ letterSpacing: "-0.2pt" }}> </span>OF
    <span style={{ letterSpacing: "-0.05pt" }}> </span>SUBSTITUTE
    <span style={{ letterSpacing: "0.45pt" }}> </span>GOODS
    <span style={{ letterSpacing: "0.5pt" }}> </span>OR
    <span style={{ letterSpacing: "0.5pt" }}> </span>SERVICES
    <span style={{ letterSpacing: "0.5pt" }}> </span>RESULTING
    <span style={{ letterSpacing: "0.45pt" }}> </span>FROM
    <span style={{ letterSpacing: "0.55pt" }}> </span>ANY
    <span style={{ letterSpacing: "0.45pt" }}> </span>GOODS,
    <span style={{ letterSpacing: "0.5pt" }}> </span>DATA,
    <span style={{ letterSpacing: "0.5pt" }}> </span>INFORMATION
    <span style={{ letterSpacing: "-0.05pt" }}> </span>OR
    <span style={{ letterSpacing: "0.35pt" }}> </span>SERVICES
    <span style={{ letterSpacing: "0.35pt" }}> </span>PURCHASED
    <span style={{ letterSpacing: "0.35pt" }}> </span>OR
    <span style={{ letterSpacing: "0.35pt" }}> </span>OBTAINED;
    <span style={{ letterSpacing: "0.45pt" }}> </span>OR
    <span style={{ letterSpacing: "0.35pt" }}> </span>MESSAGES
    <span style={{ letterSpacing: "0.35pt" }}> </span>RECEIVED
    <span style={{ letterSpacing: "0.2pt" }}> </span>FOR
    <span style={{ letterSpacing: "0.35pt" }}> </span>TRANSACTIONS
    <span style={{ letterSpacing: "-0.05pt" }}> </span>ENTERED
    <span style={{ letterSpacing: "-0.15pt" }}> </span>INTO
    <span style={{ letterSpacing: "-0.15pt" }}> </span>THROUGH
    <span style={{ letterSpacing: "-0.05pt" }}> </span>SERVICES; (d)
    <span style={{ letterSpacing: "-0.05pt" }}> </span>UNAUTHORIZED ACCESS TO
    <span style={{ letterSpacing: "-0.1pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.1pt" }}> </span>ALTERATION
    <span style={{ letterSpacing: "-0.15pt" }}> </span>OF
    <span style={{ letterSpacing: "-0.05pt" }}> </span>YOUR
    <span style={{ letterSpacing: "0.8pt" }}> </span>TRANSMISSIONS
    <span style={{ letterSpacing: "0.9pt" }}> </span>OR
    <span style={{ letterSpacing: "0.8pt" }}> </span>DATA;
    <span style={{ letterSpacing: "0.9pt" }}> </span>(e)
    <span style={{ letterSpacing: "0.85pt" }}> </span>STATEMENTS
    <span style={{ letterSpacing: "0.8pt" }}> </span>OR
    <span style={{ letterSpacing: "0.8pt" }}> </span>CONDUCT
    <span style={{ letterSpacing: "0.95pt" }}> </span>OF
    <span style={{ letterSpacing: "0.9pt" }}> </span>ANY
    <span style={{ letterSpacing: "0.8pt" }}> </span>THIRD
    <span style={{ letterSpacing: "0.8pt" }}> </span>PARTY
    <span style={{ letterSpacing: "-0.05pt" }}> </span>ON
    <span style={{ letterSpacing: "1.85pt" }}> </span>OR
    <span style={{ letterSpacing: "1.85pt" }}> </span>IN
    <span style={{ letterSpacing: "1.85pt" }}> </span>CONNECTION
    <span style={{ letterSpacing: "1.85pt" }}> </span>WITH
    <span style={{ letterSpacing: "1.85pt" }}> </span>THE
    <span style={{ letterSpacing: "2pt" }}> </span>SERVICES;
    <span style={{ letterSpacing: "0.6pt" }}> </span>(f)
    <span style={{ letterSpacing: "0.3pt" }}> </span>ANY
    <span style={{ letterSpacing: "0.45pt" }}> </span>OTHER
    <span style={{ letterSpacing: "-0.05pt" }}> </span>MATTER
    <span style={{ letterSpacing: "3.85pt" }}> </span>RELATED
    <span style={{ letterSpacing: "3.8pt" }}> </span>TO
    <span style={{ letterSpacing: "3.9pt" }}> </span>SERVICES,
    <span style={{ letterSpacing: "3.85pt" }}> </span>WHETHER
    <span style={{ letterSpacing: "3.8pt" }}> </span>BASED
    <span style={{ letterSpacing: "3.95pt" }}> </span>ON
    <span style={{ letterSpacing: "3.8pt" }}> </span>WARRANTY,
    <span style={{ letterSpacing: "3.85pt" }}> </span>COPYRIGHT,
    <span style={{ letterSpacing: "-0.05pt" }}> </span>CONTRACT,
    <span style={{ letterSpacing: "-0.7pt" }}> </span>TORT
    <span style={{ letterSpacing: "-0.7pt" }}> </span>(INCLUDING
    <span style={{ letterSpacing: "-0.7pt" }}> </span>NEGLIGENCE),
    <span style={{ letterSpacing: "-0.6pt" }}> </span>PRODUCT
    <span style={{ letterSpacing: "-0.7pt" }}> </span>LIABILITY
    <span style={{ letterSpacing: "-0.7pt" }}> </span>OR
    <span style={{ letterSpacing: "-0.7pt" }}> </span>ANY
    <span style={{ letterSpacing: "-0.65pt" }}> </span>OTHER
    <span style={{ letterSpacing: "-0.7pt" }}> </span>LEGAL
    <span style={{ letterSpacing: "-0.05pt" }}> </span>THEORY.
    <span style={{ letterSpacing: "3.15pt" }}> </span>
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist56"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Cap on Liability<span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    TO THE FULLEST EXTENT PROVIDED BY LAW, TIPPING POINT PARTIES WILL NOT BE
    LIABLE TO YOU FOR MORE THAN THE GREATER OF (a) $100; OR (b) THE REMEDY OR
    PENALTY IMPOSED BY THE STATUTE UNDER WHICH SUCH CLAIM ARISES WHERE SUCH
    REMEDY OR PENALTY CANNOT BE WAIVED OR REDUCED PURSUANT TO THIS AGREEMENT.
    THE FOREGOING CAP ON LIABILITY SHALL NOT APPLY TO LIABILITY OF A TIPPING
    POINT PARTY FOR (i) DEATH OR PERSONAL INJURY CAUSED BY A TIPPING POINT
    PARTY’S NEGLIGENCE; OR FOR (ii) ANY INJURY CAUSED BY A TIPPING POINT PARTY’S
    FRAUD OR FRAUDULENT MISREPRESENTATION.
  </p>
  <p className="MFPara-Clause" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist57"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Exclusion of Damages<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OF LIABILITY FOR PERSONAL
    INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO CERTAIN LIMITATIONS IN
    THIS SECTION 10 MAY NOT APPLY TO YOU. THE FOREGOING DISCLAIMER WILL NOT
    APPLY TO THE EXTENT PROHIBITED BY LAW.
  </p>
  <p className="MFParasubclause1" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={13}
    className="awlist58"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "14.9pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Governing Law, Dispute Resolution, and Class Action Waivers
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol className="awlist59" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Governing Law<span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    You agree that the laws of the State of Texas, without regard to principles
    of conflict of laws, govern this Agreement and any Dispute between you and
    us. You further agree that each of our Services shall be deemed to be based
    solely in the State of Texas, and that although a Services may be available
    in other jurisdictions, its availability does not give rise to general or
    specific personal jurisdiction in any forum outside the State of Texas. The
    parties acknowledge that this Agreement evidences interstate commerce. Any
    arbitration conducted pursuant to this Agreement shall be governed by the
    Federal Arbitration Act. You agree that the federal and state courts of
    Travis County, Texas are the proper forum for any appeals of an arbitration
    award or for court proceedings in the event that this Agreement's binding
    arbitration clause is found to be unenforceable.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist60"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Dispute Resolution<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol className="awlist21" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause2"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Applicability of Arbitration Agreement
      <span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="BodyText"
    style={{ marginLeft: "86.4pt", textAlign: "justify", fontSize: "10pt" }}
  >
    <a id="_bookmark0" />
    Subject<span style={{ letterSpacing: "2pt" }}> </span>to
    <span style={{ letterSpacing: "2pt" }}> </span>the
    <span style={{ letterSpacing: "2pt" }}> </span>terms
    <span style={{ letterSpacing: "2pt" }}> </span>of
    <span style={{ letterSpacing: "2pt" }}> </span>this
    <span style={{ letterSpacing: "2pt" }}> </span>Arbitration Agreement,
    <span style={{ letterSpacing: "0.2pt" }}> </span>you
    <span style={{ letterSpacing: "0.2pt" }}> </span>and
    <span style={{ letterSpacing: "0.2pt" }}> </span>Tipping Point
    <span style={{ letterSpacing: "0.2pt" }}> </span>agree that
    <span style={{ letterSpacing: "0.2pt" }}> </span>any
    <span style={{ letterSpacing: "0.2pt" }}> </span>dispute,
    <span style={{ letterSpacing: "0.2pt" }}> </span>claim,
    <span style={{ letterSpacing: "0.15pt" }}> or </span>disagreements
    <span style={{ letterSpacing: "0.2pt" }}> </span>arising out
    <span style={{ letterSpacing: "0.2pt" }}> </span>of
    <span style={{ letterSpacing: "0.2pt" }}> </span>or
    <span style={{ letterSpacing: "0.1pt" }}> </span>relating
    <span style={{ letterSpacing: "0.15pt" }}> </span>in
    <span style={{ letterSpacing: "0.15pt" }}> </span>any
    <span style={{ letterSpacing: "-0.05pt" }}> </span>way
    <span style={{ letterSpacing: "0.4pt" }}> </span>to
    <span style={{ letterSpacing: "0.3pt" }}> </span>your
    <span style={{ letterSpacing: "0.3pt" }}> </span>access
    <span style={{ letterSpacing: "0.3pt" }}> </span>to
    <span style={{ letterSpacing: "0.3pt" }}> </span>or
    <span style={{ letterSpacing: "0.45pt" }}> </span>use
    <span style={{ letterSpacing: "0.4pt" }}> </span>of
    <span style={{ letterSpacing: "0.3pt" }}> </span>the
    <span style={{ letterSpacing: "0.65pt" }}> </span>Services
    <span style={{ letterSpacing: "0.5pt" }}> </span>or
    <span style={{ letterSpacing: "0.3pt" }}> </span>the
    <span style={{ letterSpacing: "0.35pt" }}> </span>Terms
    <span style={{ letterSpacing: "0.3pt" }}> </span>of
    <span style={{ letterSpacing: "0.3pt" }}> </span>Use
    <span style={{ letterSpacing: "0.4pt" }}> </span>and
    <span style={{ letterSpacing: "0.3pt" }}> </span>prior
    <span style={{ letterSpacing: "0.3pt" }}> </span>versions
    <span style={{ letterSpacing: "0.45pt" }}> </span>of
    <span style={{ letterSpacing: "0.3pt" }}> </span>the
    <span style={{ letterSpacing: "0.45pt" }}> </span>Terms
    <span style={{ letterSpacing: "0.3pt" }}> </span>of
    <span style={{ letterSpacing: "0.45pt" }}> </span>Use,
    <span style={{ letterSpacing: "-0.05pt" }}> </span>including claims and
    <span style={{ letterSpacing: "-0.05pt" }}> </span>disputes
    <span style={{ letterSpacing: "0.1pt" }}> </span>that arose
    <span style={{ letterSpacing: "0.1pt" }}> </span>between us
    <span style={{ letterSpacing: "-0.05pt" }}> </span>before
    <span style={{ letterSpacing: "-0.05pt" }}> </span>the effective date of
    <span style={{ letterSpacing: "-0.05pt" }}> </span>these
    <span style={{ letterSpacing: "0.45pt" }}> </span>Terms of
    <span style={{ letterSpacing: "0.1pt" }}> </span>Use (each,
    <span style={{ letterSpacing: "-0.05pt" }}> </span>a “
    <strong>Dispute</strong>”)<span style={{ letterSpacing: "0.1pt" }}> </span>
    will<span style={{ letterSpacing: "0.1pt" }}> </span>be resolved by binding
    arbitration, rather<span style={{ letterSpacing: "0.1pt" }}> </span>than in
    court, except<span style={{ letterSpacing: "0.1pt" }}> </span>that:
    <span style={{ letterSpacing: "0.1pt" }}> </span>(1)
    <span style={{ letterSpacing: "0.45pt" }}> </span>you and
    <span style={{ letterSpacing: "0.1pt" }}> </span>Tipping Point
    <span style={{ letterSpacing: "-0.05pt" }}> </span>may
    <span style={{ letterSpacing: "0.8pt" }}> </span>assert
    <span style={{ letterSpacing: "0.8pt" }}> </span>claims
    <span style={{ letterSpacing: "0.8pt" }}> </span>or
    <span style={{ letterSpacing: "0.8pt" }}> </span>seek
    <span style={{ letterSpacing: "0.8pt" }}> </span>relief
    <span style={{ letterSpacing: "0.8pt" }}> </span>in
    <span style={{ letterSpacing: "0.75pt" }}> </span>small
    <span style={{ letterSpacing: "1.05pt" }}> </span>claims
    <span style={{ letterSpacing: "0.8pt" }}> </span>court
    <span style={{ letterSpacing: "0.7pt" }}> </span>if
    <span style={{ letterSpacing: "0.8pt" }}> </span>such
    <span style={{ letterSpacing: "0.75pt" }}> </span>claims
    <span style={{ letterSpacing: "0.8pt" }}> </span>qualify
    <span style={{ letterSpacing: "0.75pt" }}> </span>and
    <span style={{ letterSpacing: "0.65pt" }}> </span>remain
    <span style={{ letterSpacing: "0.75pt" }}> </span>in
    <span style={{ letterSpacing: "0.75pt" }}> </span>small
    <span style={{ letterSpacing: "0.8pt" }}> </span>claims
    <span style={{ letterSpacing: "-0.05pt" }}> </span>court;
    <span style={{ letterSpacing: "1.75pt" }}> </span>and
    <span style={{ letterSpacing: "1.85pt" }}> </span>(2)
    <span style={{ letterSpacing: "1.95pt" }}> </span>you
    <span style={{ letterSpacing: "1.85pt" }}> </span>or
    <span style={{ letterSpacing: "1.9pt" }}> </span>Tipping Point
    <span style={{ letterSpacing: "1.8pt" }}> </span>may
    <span style={{ letterSpacing: "1.85pt" }}> </span>seek
    <span style={{ letterSpacing: "1.85pt" }}> </span>equitable
    <span style={{ letterSpacing: "1.85pt" }}> </span>relief
    <span style={{ letterSpacing: "1.8pt" }}> </span>in
    <span style={{ letterSpacing: "1.85pt" }}> </span>court
    <span style={{ letterSpacing: "1.9pt" }}> </span>for
    <span style={{ letterSpacing: "1.9pt" }}> </span>infringement
    <span style={{ letterSpacing: "1.9pt" }}> </span>or
    <span style={{ letterSpacing: "1.75pt" }}> </span>other
    <span style={{ letterSpacing: "1.8pt" }}> </span>misuse
    <span style={{ letterSpacing: "1.75pt" }}> </span>of
    <span style={{ letterSpacing: "-0.05pt" }}> </span>intellectual
    <span style={{ letterSpacing: "0.45pt" }}> </span>property
    <span style={{ letterSpacing: "0.3pt" }}> </span>rights
    <span style={{ letterSpacing: "0.2pt" }}> </span>(such
    <span style={{ letterSpacing: "0.3pt" }}> </span>as
    <span style={{ letterSpacing: "0.3pt" }}> </span>trademarks,
    <span style={{ letterSpacing: "0.3pt" }}> </span>trade
    <span style={{ letterSpacing: "0.2pt" }}> </span>dress,
    <span style={{ letterSpacing: "0.45pt" }}> </span>domain
    <span style={{ letterSpacing: "0.3pt" }}> </span>names,
    <span style={{ letterSpacing: "0.3pt" }}> </span>trade
    <span style={{ letterSpacing: "0.3pt" }}> </span>secrets,
    <span style={{ letterSpacing: "0.3pt" }}> </span>copyrights,
    <span style={{ letterSpacing: "0.3pt" }}> </span>and
    <span style={{ letterSpacing: "-0.05pt" }}> </span>patents).
    <span style={{ letterSpacing: "3pt" }}> </span>For
    <span style={{ letterSpacing: "0.8pt" }}> </span>purposes
    <span style={{ letterSpacing: "0.8pt" }}> </span>of
    <span style={{ letterSpacing: "0.7pt" }}> </span>this
    <span style={{ letterSpacing: "0.8pt" }}> </span>Arbitration
    <span style={{ letterSpacing: "0.75pt" }}> </span>Agreement,
    <span style={{ letterSpacing: "0.65pt" }}> </span>“<strong>Dispute</strong>”
    <span style={{ letterSpacing: "0.8pt" }}> </span>will
    <span style={{ letterSpacing: "0.8pt" }}> </span>also
    <span style={{ letterSpacing: "0.75pt" }}> </span>include
    <span style={{ letterSpacing: "0.8pt" }}> </span>disputes
    <span style={{ letterSpacing: "0.85pt" }}> </span>that
    <span style={{ letterSpacing: "0.8pt" }}> </span>arose
    <span style={{ letterSpacing: "0.8pt" }}> </span>or
    <span style={{ letterSpacing: "-0.05pt" }}> </span>involve
    <span style={{ letterSpacing: "0.65pt" }}> </span>facts
    <span style={{ letterSpacing: "0.65pt" }}> </span>occurring
    <span style={{ letterSpacing: "0.75pt" }}> </span>before
    <span style={{ letterSpacing: "0.65pt" }}> </span>the
    <span style={{ letterSpacing: "0.65pt" }}> </span>existence
    <span style={{ letterSpacing: "0.65pt" }}> </span>of
    <span style={{ letterSpacing: "0.7pt" }}> </span>this
    <span style={{ letterSpacing: "0.65pt" }}> </span>or
    <span style={{ letterSpacing: "0.7pt" }}> </span>any
    <span style={{ letterSpacing: "0.65pt" }}> </span>prior
    <span style={{ letterSpacing: "0.7pt" }}> </span>versions
    <span style={{ letterSpacing: "0.65pt" }}> </span>of
    <span style={{ letterSpacing: "0.7pt" }}> </span>the
    <span style={{ letterSpacing: "0.95pt" }}> </span>Terms
    <span style={{ letterSpacing: "0.8pt" }}> </span>of
    <span style={{ letterSpacing: "0.8pt" }}> </span>Use
    <span style={{ letterSpacing: "0.8pt" }}> </span>as
    <span style={{ letterSpacing: "0.8pt" }}> </span>well
    <span style={{ letterSpacing: "0.8pt" }}> </span>as claims that may arise
    after the termination of these Terms of Use.
  </p>
  <p
    className="BodyText"
    style={{ marginLeft: "86.4pt", textAlign: "justify", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist22"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Rules and Forum<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", textAlign: "justify" }}
  >
    <span style={{ fontSize: "10pt" }}>
      The Terms of Use evidence a transaction involving interstate commerce; and
      notwithstanding any other provision herein with respect to the applicable
      substantive law, the Federal Arbitration Act, 9 U.S.C. § 1 et seq., will
      govern the interpretation and enforcement of this Arbitration Agreement
      and any arbitration proceedings. If the Informal Dispute Resolution
      Conference process described above does not resolve satisfactorily within
      sixty (60) days after receipt of your Notice, you and Tipping Point agree
      that either party shall have the right to finally resolve the Dispute
      through binding arbitration. The arbitration will be administered by the
      American Arbitration Association (“
    </span>
    <strong>
      <span style={{ fontSize: "10pt" }}>AAA</span>
    </strong>
    <span style={{ fontSize: "10pt" }}>
      ”), in accordance with the Consumer Arbitration Rules (the “
    </span>
    <strong>
      <span style={{ fontSize: "10pt" }}>AAA Rules</span>
    </strong>
    <span style={{ fontSize: "10pt" }}>
      ”) then in effect, except as modified by this
    </span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>section</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>of</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>this</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>Arbitration</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>Agreement.</span>
    <span style={{ fontSize: "10pt" }}>&nbsp;&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>The</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>AAA</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>Rules</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>are</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>currently</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>available</span>
    <span style={{ fontSize: "10pt" }}>&nbsp; </span>
    <span style={{ fontSize: "10pt" }}>at https://</span>
    <a
      href="http://www.adr.org/sites/default/files/Consumer%20Rules.pdf"
      style={{ textDecoration: "none" }}
    >
      <span className="Hyperlink" style={{ fontSize: "10pt" }}>
        www.adr.org/sites/default/files/Consumer%20Rules.pdf.
      </span>
    </a>
    <span style={{ fontSize: "10pt" }}>
      {" "}
      A party who wishes to initiate arbitration must provide the other party
      with a request for arbitration (the “
    </span>
    <strong>
      <span style={{ fontSize: "10pt" }}>Request</span>
    </strong>
    <span style={{ fontSize: "10pt" }}>
      ”). The Request must include: (1) the name, telephone number, mailing
      address, e
    </span>
    <span style={{ fontFamily: '"Cambria Math"', fontSize: "10pt" }}>‐</span>
    <span style={{ fontSize: "10pt" }}>
      mail address of the party seeking arbitration (if applicable); (2) a
      statement of the legal claims being asserted and the factual bases of
      those claims; (3) a description of the remedy sought and an accurate, good
    </span>
    <span style={{ fontFamily: '"Cambria Math"', fontSize: "10pt" }}>‐</span>
    <span style={{ fontSize: "10pt" }}>
      faith calculation of the amount in controversy in United States Dollars;
      (4) a statement certifying completion of the Informal Dispute Resolution
      Conference process as described above; and (5) evidence that the
      requesting party has paid any necessary filing fees in connection with
      such arbitration. If the party requesting arbitration is represented by
      counsel, the Request shall also include counsel’s name, telephone number,
      mailing address, and email address. Such counsel must also sign the
      Request. By signing the Request, counsel certifies to the best of
      counsel’s knowledge, information, and belief, formed after an inquiry
      reasonable under the "circumstances, that: (1) the Request is not being
      presented for any improper purpose, such as to harass, cause unnecessary
      delay, or needlessly increase the cost of dispute resolution; (2) the
      claims, defenses and other legal contentions are warranted by existing law
      or by a nonfrivolous argument for extending, modifying, or reversing
      existing law or for establishing new law; and (3) the factual and damages
      contentions have evidentiary support or, if specifically so identified,
      will likely have evidentiary support after a reasonable opportunity for
      further investigation or discovery. Unless you and Tipping Point otherwise
      agree, or the Batch Arbitration process discussed in subsection 16.9 is
      triggered, the arbitration will be conducted in the county where you
      reside. Subject to the AAA Rules, the arbitrator may direct a limited and
      reasonable exchange of information between the parties, consistent with
      the expedited nature of the arbitration. If the AAA is not available to
      arbitrate, the parties will select an alternative arbitral forum. Your
      responsibility to pay any AAA fees and costs will be solely as set forth
      in the applicable AAA Rules. You and Tipping Point agree that all
      materials and documents exchanged during the arbitration proceedings shall
      be kept confidential and shall not be shared with anyone except the
      parties’ attorneys, accountants, or business advisors, and then subject to
      the condition that they agree to keep all materials and documents
      exchanged during the arbitration proceedings confidential."
    </span>
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "0pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist23"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Arbitrator<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "86.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The arbitrator will be either a retired judge or an attorney licensed to
    practice law in the state of Texas and will be selected by the parties from
    the AAA’s roster of consumer dispute arbitrators. If the parties are unable
    to agree upon an arbitrator within thirty-five (35) days of delivery of the
    Request, then the AAA will appoint the arbitrator in accordance with the AAA
    Rules.
  </p>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist24"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.23pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Authority of Arbitrator<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="BodyText"
    style={{ marginLeft: "86.4pt", textAlign: "justify", fontSize: "10pt" }}
  >
    The<span style={{ letterSpacing: "2pt" }}> </span>arbitrator
    <span style={{ letterSpacing: "2pt" }}> </span>shall
    <span style={{ letterSpacing: "2pt" }}> </span>have exclusive
    <span style={{ letterSpacing: "2pt" }}> </span>authority
    <span style={{ letterSpacing: "2pt" }}> </span>to resolve
    <span style={{ letterSpacing: "2pt" }}> </span>any Dispute,
    <span style={{ letterSpacing: "-0.5pt" }}> </span>including,
    <span style={{ letterSpacing: "0.05pt" }}> </span>without limitation,
    <span style={{ letterSpacing: "0.2pt" }}> </span>disputes arising
    <span style={{ letterSpacing: "-0.1pt" }}> </span>out
    <span style={{ letterSpacing: "0.1pt" }}> </span>of
    <span style={{ letterSpacing: "0.1pt" }}> </span>or
    <span style={{ letterSpacing: "0.1pt" }}> </span>related
    <span style={{ letterSpacing: "-0.05pt" }}> </span>to
    <span style={{ letterSpacing: "-0.1pt" }}> </span>the
    <span style={{ letterSpacing: "0.05pt" }}> </span>interpretation
    <span style={{ letterSpacing: "0.05pt" }}> </span>or
    <span style={{ letterSpacing: "-0.05pt" }}> </span>application
    <span style={{ letterSpacing: "-0.05pt" }}> </span>of
    <span style={{ letterSpacing: "-0.65pt" }}> </span>the
    <span style={{ letterSpacing: "-0.65pt" }}> </span>Arbitration
    <span style={{ letterSpacing: "-0.65pt" }}> </span>Agreement,
    <span style={{ letterSpacing: "-0.65pt" }}> </span>including
    <span style={{ letterSpacing: "-0.65pt" }}> </span>the
    <span style={{ letterSpacing: "-0.55pt" }}> </span>enforceability,
    <span style={{ letterSpacing: "-0.65pt" }}> </span>revocability,
    <span style={{ letterSpacing: "-0.65pt" }}> </span>scope,
    <span style={{ letterSpacing: "-0.65pt" }}> </span>or
    <span style={{ letterSpacing: "-0.6pt" }}> </span>validity
    <span style={{ letterSpacing: "-0.55pt" }}> </span>of
    <span style={{ letterSpacing: "-0.6pt" }}> </span>the
    <span style={{ letterSpacing: "-0.55pt" }}> </span>Arbitration
    <span style={{ letterSpacing: "-0.05pt" }}> </span>Agreement
    <span style={{ letterSpacing: "0.35pt" }}> </span>or
    <span style={{ letterSpacing: "0.3pt" }}> </span>any
    <span style={{ letterSpacing: "0.3pt" }}> </span>portion
    <span style={{ letterSpacing: "0.05pt" }}> </span>of
    <span style={{ letterSpacing: "0.2pt" }}> </span>the
    <span style={{ letterSpacing: "0.3pt" }}> </span>Arbitration
    <span style={{ letterSpacing: "0.3pt" }}> </span>Agreement,
    <span style={{ letterSpacing: "0.3pt" }}> </span>except
    <span style={{ letterSpacing: "0.2pt" }}> </span>for
    <span style={{ letterSpacing: "0.2pt" }}> </span>the
    <span style={{ letterSpacing: "0.2pt" }}> </span>following:
    <span style={{ letterSpacing: "0.75pt" }}> </span>(1)
    <span style={{ letterSpacing: "0.2pt" }}> </span>all
    <span style={{ letterSpacing: "0.35pt" }}> </span>Disputes
    <span style={{ letterSpacing: "0.2pt" }}> </span>arising
    <span style={{ letterSpacing: "-0.05pt" }}> </span>out
    <span style={{ letterSpacing: "2.1pt" }}> </span>of
    <span style={{ letterSpacing: "2pt" }}> </span>or
    <span style={{ letterSpacing: "1.95pt" }}> </span>relating
    <span style={{ letterSpacing: "1.9pt" }}> </span>to
    <span style={{ letterSpacing: "1.9pt" }}> </span>the
    <span style={{ letterSpacing: "1.95pt" }}> </span>subsection
    <span style={{ letterSpacing: "2pt" }}> </span>entitled
    <span style={{ letterSpacing: "2pt" }}> </span>“Waiver
    <span style={{ letterSpacing: "2pt" }}> </span>of
    <span style={{ letterSpacing: "2pt" }}> </span>Class
    <span style={{ letterSpacing: "2.1pt" }}> </span>and
    <span style={{ letterSpacing: "2pt" }}> </span>Other
    <span style={{ letterSpacing: "2.1pt" }}> </span>Non-Individualized
    <span style={{ letterSpacing: "2pt" }}> </span>Relief,”
    <span style={{ letterSpacing: "-0.05pt" }}> </span>including
    <span style={{ letterSpacing: "-0.75pt" }}> </span>any
    <span style={{ letterSpacing: "-0.7pt" }}> </span>claim
    <span style={{ letterSpacing: "-0.8pt" }}> </span>that
    <span style={{ letterSpacing: "-0.7pt" }}> </span>all
    <span style={{ letterSpacing: "-0.8pt" }}> </span>or
    <span style={{ letterSpacing: "-0.7pt" }}> </span>part
    <span style={{ letterSpacing: "-0.7pt" }}> </span>of
    <span style={{ letterSpacing: "-0.8pt" }}> </span>the
    <span style={{ letterSpacing: "-0.7pt" }}> </span>subsection
    <span style={{ letterSpacing: "-0.75pt" }}> </span>entitled
    <span style={{ letterSpacing: "-0.75pt" }}> </span>“Waiver
    <span style={{ letterSpacing: "-0.7pt" }}> </span>of
    <span style={{ letterSpacing: "-0.7pt" }}> </span>Class
    <span style={{ letterSpacing: "-0.85pt" }}> </span>and
    <span style={{ letterSpacing: "-0.7pt" }}> </span>Other
    <span style={{ letterSpacing: "-0.7pt" }}> </span>Non-Individualized
    <span style={{ letterSpacing: "-0.05pt" }}> </span>Relief”
    <span style={{ letterSpacing: "0.65pt" }}> </span>is
    <span style={{ letterSpacing: "0.65pt" }}> </span>unenforceable,
    <span style={{ letterSpacing: "0.65pt" }}> </span>illegal,
    <span style={{ letterSpacing: "0.65pt" }}> </span>void
    <span style={{ letterSpacing: "0.65pt" }}> </span>or
    <span style={{ letterSpacing: "0.7pt" }}> </span>voidable,
    <span style={{ letterSpacing: "0.65pt" }}> </span>or
    <span style={{ letterSpacing: "0.55pt" }}> </span>that
    <span style={{ letterSpacing: "0.7pt" }}> </span>such
    <span style={{ letterSpacing: "0.65pt" }}> </span>subsection
    <span style={{ letterSpacing: "0.65pt" }}> </span>entitled
    <span style={{ letterSpacing: "0.65pt" }}> </span>“Waiver
    <span style={{ letterSpacing: "0.7pt" }}> </span>of
    <span style={{ letterSpacing: "0.7pt" }}> </span>Class
    <span style={{ letterSpacing: "0.7pt" }}> </span>and
    <span style={{ letterSpacing: "-0.05pt" }}> </span>Other Non-Individualized
    <span style={{ letterSpacing: "-0.2pt" }}> </span>Relief”
    <span style={{ letterSpacing: "-0.15pt" }}> </span>has
    <span style={{ letterSpacing: "-0.15pt" }}> </span>been
    <span style={{ letterSpacing: "-0.05pt" }}> </span>breached,
    <span style={{ letterSpacing: "-0.15pt" }}> </span>shall be
    <span style={{ letterSpacing: "-0.05pt" }}> </span>decided
    <span style={{ letterSpacing: "-0.05pt" }}> </span>by
    <span style={{ letterSpacing: "-0.15pt" }}> </span>a
    <span style={{ letterSpacing: "-0.05pt" }}> </span>court
    <span style={{ letterSpacing: "-0.15pt" }}> </span>of
    <span style={{ letterSpacing: "-0.05pt" }}> </span>competent
    <span style={{ letterSpacing: "-0.15pt" }}> </span>jurisdiction
    <span style={{ letterSpacing: "-0.05pt" }}> </span>and
    <span style={{ letterSpacing: "-0.65pt" }}> </span>not
    <span style={{ letterSpacing: "-0.6pt" }}> </span>by
    <span style={{ letterSpacing: "-0.75pt" }}> </span>an
    <span style={{ letterSpacing: "-0.65pt" }}> </span>arbitrator;
    <span style={{ letterSpacing: "-0.6pt" }}> </span>(2)
    <span style={{ letterSpacing: "-0.7pt" }}> </span>except
    <span style={{ letterSpacing: "-0.6pt" }}> </span>as
    <span style={{ letterSpacing: "-0.65pt" }}> </span>expressly
    <span style={{ letterSpacing: "-0.65pt" }}> </span>contemplated
    <span style={{ letterSpacing: "-0.65pt" }}> </span>in
    <span style={{ letterSpacing: "-0.65pt" }}> </span>the
    <span style={{ letterSpacing: "-0.65pt" }}> </span>subsection
    <span style={{ letterSpacing: "-0.65pt" }}> </span>entitled
    <span style={{ letterSpacing: "-0.65pt" }}> </span>“Batch
    <span style={{ letterSpacing: "-0.65pt" }}> </span>Arbitration,”
    <span style={{ letterSpacing: "-0.05pt" }}> </span>all
    <span style={{ letterSpacing: "-0.4pt" }}> </span>Disputes
    <span style={{ letterSpacing: "-0.4pt" }}> </span>about
    <span style={{ letterSpacing: "-0.4pt" }}> </span>the
    <span style={{ letterSpacing: "-0.4pt" }}> </span>payment
    <span style={{ letterSpacing: "-0.35pt" }}> </span>of
    <span style={{ letterSpacing: "-0.4pt" }}> </span>arbitration
    <span style={{ letterSpacing: "-0.25pt" }}> </span>fees
    <span style={{ letterSpacing: "-0.35pt" }}> </span>shall
    <span style={{ letterSpacing: "-0.4pt" }}> </span>be
    <span style={{ letterSpacing: "-0.4pt" }}> </span>decided
    <span style={{ letterSpacing: "-0.4pt" }}> </span>only
    <span style={{ letterSpacing: "-0.35pt" }}> </span>by
    <span style={{ letterSpacing: "-0.45pt" }}> </span>a
    <span style={{ letterSpacing: "-0.4pt" }}> </span>court
    <span style={{ letterSpacing: "-0.4pt" }}> </span>of
    <span style={{ letterSpacing: "-0.4pt" }}> </span>competent
    <span style={{ letterSpacing: "-0.35pt" }}> </span>jurisdiction
    <span style={{ letterSpacing: "-0.05pt" }}> </span>and
    <span style={{ letterSpacing: "0.4pt" }}> </span>not
    <span style={{ letterSpacing: "0.45pt" }}> </span>by
    <span style={{ letterSpacing: "0.3pt" }}> </span>an
    <span style={{ letterSpacing: "0.3pt" }}> </span>arbitrator;
    <span style={{ letterSpacing: "0.45pt" }}> </span>(3)
    <span style={{ letterSpacing: "0.45pt" }}> </span>all
    <span style={{ letterSpacing: "0.45pt" }}> </span>Disputes
    <span style={{ letterSpacing: "0.3pt" }}> </span>about
    <span style={{ letterSpacing: "0.45pt" }}> </span>whether
    <span style={{ letterSpacing: "0.45pt" }}> </span>either
    <span style={{ letterSpacing: "0.45pt" }}> </span>party
    <span style={{ letterSpacing: "0.4pt" }}> </span>has
    <span style={{ letterSpacing: "0.3pt" }}> </span>satisfied
    <span style={{ letterSpacing: "0.3pt" }}> </span>any
    <span style={{ letterSpacing: "0.4pt" }}> </span>condition
    <span style={{ letterSpacing: "0.4pt" }}> </span>precedent
    <span style={{ letterSpacing: "-0.05pt" }}> </span>to
    <span style={{ letterSpacing: "-0.2pt" }}> </span>arbitration
    <span style={{ letterSpacing: "-0.2pt" }}> </span>shall
    <span style={{ letterSpacing: "-0.15pt" }}> </span>be
    <span style={{ letterSpacing: "-0.15pt" }}> </span>decided
    <span style={{ letterSpacing: "-0.15pt" }}> </span>only
    <span style={{ letterSpacing: "-0.2pt" }}> </span>by
    <span style={{ letterSpacing: "-0.2pt" }}> </span>a
    <span style={{ letterSpacing: "-0.15pt" }}> </span>court
    <span style={{ letterSpacing: "-0.15pt" }}> </span>of
    <span style={{ letterSpacing: "-0.25pt" }}> </span>competent
    <span style={{ letterSpacing: "-0.15pt" }}> </span>jurisdiction
    <span style={{ letterSpacing: "-0.2pt" }}> </span>and
    <span style={{ letterSpacing: "-0.2pt" }}> </span>not
    <span style={{ letterSpacing: "-0.25pt" }}> </span>by
    <span style={{ letterSpacing: "-0.3pt" }}> </span>an
    <span style={{ letterSpacing: "-0.15pt" }}> </span>arbitrator;
    <span style={{ letterSpacing: "-0.15pt" }}> </span>and
    <span style={{ letterSpacing: "-0.2pt" }}> </span>(4)
    <span style={{ letterSpacing: "-0.15pt" }}> </span>all
    <span style={{ letterSpacing: "-0.05pt" }}> </span>Disputes
    <span style={{ letterSpacing: "1.05pt" }}> </span>about
    <span style={{ letterSpacing: "1.05pt" }}> </span>which
    <span style={{ letterSpacing: "1pt" }}> </span>version
    <span style={{ letterSpacing: "1pt" }}> </span>of
    <span style={{ letterSpacing: "1.05pt" }}> </span>the
    <span style={{ letterSpacing: "1pt" }}> </span>Arbitration
    <span style={{ letterSpacing: "1pt" }}> </span>Agreement
    <span style={{ letterSpacing: "1.05pt" }}> </span>applies
    <span style={{ letterSpacing: "1.05pt" }}> </span>shall
    <span style={{ letterSpacing: "1.05pt" }}> </span>be
    <span style={{ letterSpacing: "1pt" }}> </span>decided
    <span style={{ letterSpacing: "1pt" }}> </span>only
    <span style={{ letterSpacing: "1pt" }}> </span>by
    <span style={{ letterSpacing: "1pt" }}> </span>a
    <span style={{ letterSpacing: "0.9pt" }}> </span>court
    <span style={{ letterSpacing: "1.05pt" }}> </span>of
    <span style={{ letterSpacing: "-0.05pt" }}> </span>competent
    <span style={{ letterSpacing: "0.35pt" }}> </span>jurisdiction
    <span style={{ letterSpacing: "0.4pt" }}> </span>and
    <span style={{ letterSpacing: "0.3pt" }}> </span>not
    <span style={{ letterSpacing: "0.45pt" }}> </span>by
    <span style={{ letterSpacing: "0.4pt" }}> </span>an
    <span style={{ letterSpacing: "0.4pt" }}> </span>arbitrator.
    <span style={{ letterSpacing: "3.55pt" }}> </span>The
    <span style={{ letterSpacing: "0.4pt" }}> </span>arbitration
    <span style={{ letterSpacing: "0.4pt" }}> </span>proceeding
    <span style={{ letterSpacing: "0.4pt" }}> </span>will
    <span style={{ letterSpacing: "0.45pt" }}> </span>not
    <span style={{ letterSpacing: "0.45pt" }}> </span>be
    <span style={{ letterSpacing: "0.4pt" }}> </span>consolidated
    <span style={{ letterSpacing: "0.4pt" }}> </span>with
    <span style={{ letterSpacing: "-0.05pt" }}> </span>any
    <span style={{ letterSpacing: "0.2pt" }}> </span>other
    <span style={{ letterSpacing: "0.1pt" }}> </span>matters
    <span style={{ letterSpacing: "0.05pt" }}> </span>or
    <span style={{ letterSpacing: "0.1pt" }}> </span>joined
    <span style={{ letterSpacing: "0.05pt" }}> </span>with
    <span style={{ letterSpacing: "0.15pt" }}> </span>any
    <span style={{ letterSpacing: "0.15pt" }}> </span>other
    <span style={{ letterSpacing: "0.1pt" }}> </span>cases
    <span style={{ letterSpacing: "0.2pt" }}> </span>or
    <span style={{ letterSpacing: "0.1pt" }}> </span>parties,
    <span style={{ letterSpacing: "0.05pt" }}> </span>except
    <span style={{ letterSpacing: "0.2pt" }}> </span>as
    <span style={{ letterSpacing: "0.2pt" }}> </span>expressly
    <span style={{ letterSpacing: "0.15pt" }}> </span>provided
    <span style={{ letterSpacing: "0.05pt" }}> </span>in
    <span style={{ letterSpacing: "0.15pt" }}> </span>the
    <span style={{ letterSpacing: "0.2pt" }}> </span>subsection
    <span style={{ letterSpacing: "-0.05pt" }}> </span>entitled
    <span style={{ letterSpacing: "0.55pt" }}> </span>“Batch
    <span style={{ letterSpacing: "0.65pt" }}> </span>Arbitration.”
    <span style={{ letterSpacing: "0.65pt" }}> </span>The
    <span style={{ letterSpacing: "0.5pt" }}> </span>arbitrator
    <span style={{ letterSpacing: "0.55pt" }}> </span>shall
    <span style={{ letterSpacing: "0.7pt" }}> </span>have
    <span style={{ letterSpacing: "0.55pt" }}> </span>the
    <span style={{ letterSpacing: "0.55pt" }}> </span>authority
    <span style={{ letterSpacing: "0.5pt" }}> </span>to
    <span style={{ letterSpacing: "0.5pt" }}> </span>grant
    <span style={{ letterSpacing: "0.6pt" }}> </span>motions
    <span style={{ letterSpacing: "0.65pt" }}> </span>dispositive
    <span style={{ letterSpacing: "0.65pt" }}> </span>of
    <span style={{ letterSpacing: "0.7pt" }}> </span>all
    <span style={{ letterSpacing: "0.6pt" }}> </span>or
    <span style={{ letterSpacing: "-0.05pt" }}> </span>part
    <span style={{ letterSpacing: "0.8pt" }}> </span>of
    <span style={{ letterSpacing: "0.8pt" }}> </span>any
    <span style={{ letterSpacing: "0.9pt" }}> </span>Dispute.
    <span style={{ letterSpacing: "2.9pt" }}> </span>The
    <span style={{ letterSpacing: "0.65pt" }}> </span>arbitrator
    <span style={{ letterSpacing: "0.8pt" }}> </span>shall
    <span style={{ letterSpacing: "0.8pt" }}> </span>issue
    <span style={{ letterSpacing: "0.8pt" }}> </span>a
    <span style={{ letterSpacing: "0.9pt" }}> </span>written
    <span style={{ letterSpacing: "0.8pt" }}> </span>award
    <span style={{ letterSpacing: "0.75pt" }}> </span>and
    <span style={{ letterSpacing: "0.8pt" }}> </span>statement
    <span style={{ letterSpacing: "0.8pt" }}> </span>of
    <span style={{ letterSpacing: "0.8pt" }}> </span>decision
    <span style={{ letterSpacing: "0.75pt" }}> </span>describing
    <span style={{ letterSpacing: "0.75pt" }}> </span>the
    <span style={{ letterSpacing: "-0.05pt" }}> </span>essential
    <span style={{ letterSpacing: "0.25pt" }}> </span>findings
    <span style={{ letterSpacing: "0.2pt" }}> </span>and
    <span style={{ letterSpacing: "0.2pt" }}> </span>conclusions
    <span style={{ letterSpacing: "0.2pt" }}> </span>on
    <span style={{ letterSpacing: "0.3pt" }}> </span>which
    <span style={{ letterSpacing: "0.2pt" }}> </span>the
    <span style={{ letterSpacing: "0.3pt" }}> </span>award
    <span style={{ letterSpacing: "0.15pt" }}> </span>is
    <span style={{ letterSpacing: "0.2pt" }}> </span>based,
    <span style={{ letterSpacing: "0.2pt" }}> </span>including
    <span style={{ letterSpacing: "0.15pt" }}> </span>the
    <span style={{ letterSpacing: "0.2pt" }}> </span>calculation
    <span style={{ letterSpacing: "0.3pt" }}> </span>of
    <span style={{ letterSpacing: "0.3pt" }}> </span>any
    <span style={{ letterSpacing: "0.3pt" }}> </span>damages
    <span style={{ letterSpacing: "-0.05pt" }}> </span>awarded.
    <span style={{ letterSpacing: "3.8pt" }}> </span>The
    <span style={{ letterSpacing: "0.4pt" }}> </span>award
    <span style={{ letterSpacing: "0.45pt" }}> </span>of
    <span style={{ letterSpacing: "0.45pt" }}> </span>the
    <span style={{ letterSpacing: "0.55pt" }}> </span>arbitrator
    <span style={{ letterSpacing: "0.45pt" }}> </span>is
    <span style={{ letterSpacing: "0.45pt" }}> </span>final
    <span style={{ letterSpacing: "0.45pt" }}> </span>and
    <span style={{ letterSpacing: "0.4pt" }}> </span>binding
    <span style={{ letterSpacing: "0.5pt" }}> </span>upon
    <span style={{ letterSpacing: "0.85pt" }}> </span>you
    <span style={{ letterSpacing: "0.4pt" }}> </span>and
    <span style={{ letterSpacing: "0.55pt" }}> </span>us.
    <span style={{ letterSpacing: "3.85pt" }}> </span>Judgment
    <span style={{ letterSpacing: "0.55pt" }}> </span>on
    <span style={{ letterSpacing: "0.4pt" }}> </span>the
    <span style={{ letterSpacing: "0.4pt" }}> </span>arbitration award may be
    entered in any court having jurisdiction.
  </p>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={5}
    className="awlist25"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause2"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "23.78pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Attorneys’ Fees and Costs<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause2"
    style={{
      marginLeft: "86.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The parties shall bear their own attorneys’ fees and costs in arbitration
    unless the arbitrator finds that either the substance of the Dispute or the
    relief sought in the Request was frivolous or was brought for an improper
    purpose (as measured by the standards set forth in Federal Rule of Civil
    Procedure 11(b)).&nbsp; If you or Tipping Point need to invoke the authority
    of a court of competent jurisdiction to compel arbitration, then the party
    that obtains an order compelling arbitration in such action shall have the
    right to collect from the other party its reasonable costs, necessary
    disbursements, and reasonable attorneys’ fees incurred in securing an order
    compelling arbitration. The prevailing party in any court action relating to
    whether either party has satisfied any condition precedent to arbitration is
    entitled to recover their reasonable costs, necessary disbursements, and
    reasonable attorneys’ fees and costs.
  </p>
  <p
    className="MFParasubclause2"
    style={{ marginLeft: "86.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist61"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Class Action and Jury Trial Waiver
      <span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p style={{ marginLeft: "50.4pt", fontSize: "10pt" }}>
    You must bring any and all Disputes against us in your individual capacity
    and not as a plaintiff in or member of any purported class action,
    collective action, private attorney general action, or other representative
    proceeding. This provision applies to class arbitration. You and we both
    agree to waive the right to demand a trial by jury.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "0pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={14}
    className="awlist62"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFPara-Clause"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "14.9pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      General Provisions<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFPara-Clause"
    style={{ marginLeft: "21.6pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol className="awlist63" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Electronic Communications<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The communications between you and Tipping Point may take place via
    electronic means, whether you visit Services or send Tipping Point e-mails,
    or whether Tipping Point posts notices on Services or communicates with you
    via e-mail. For contractual purposes, you (a) consent to receive
    communications from Tipping Point in an electronic form; and (b) agree that
    all terms and conditions, agreements, notices, disclosures, and other
    communications that Tipping Point provides to you electronically satisfy any
    legal requirement that such communications would satisfy if it were to be in
    writing.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={2}
    className="awlist64"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Assignment<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p className="ListParagraph" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The Agreement and your rights and obligations hereunder may not be assigned,
    subcontracted, delegated, or otherwise transferred by you without Tipping
    Point’s prior written consent, and any attempted assignment, subcontract,
    delegation, or transfer in violation of the foregoing will be null and void.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "0pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={3}
    className="awlist65"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Entire Agreement<span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The Agreement is the final, complete and exclusive agreement of the parties
    with respect to the subject matter hereof and supersedes and merges all
    prior discussions between the parties with respect to such subject matter.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={4}
    className="awlist66"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Force Majeure<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    Tipping Point shall not be liable for any delay or failure to perform
    resulting from causes outside its reasonable control, including, but not
    limited to, acts of God, war, terrorism, riots, embargos, acts of civil or
    military authorities, fire, floods, accidents, strikes or shortages of
    transportation facilities, fuel, energy, labor or materials.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={5}
    className="awlist67"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Contact<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", textAlign: "justify" }}
  >
    <span style={{ fontSize: "10pt" }}>
      If you have any questions, complaints or claims with respect to Services,
      please contact us at:{" "}
    </span>
    <a
      href="/cdn-cgi/l/email-protection#e4979194948b9690a4908d94948d8a83948b8d8a90ca859494"
      style={{ textDecoration: "none" }}
    >
      <span className="Hyperlink" style={{ fontSize: "10pt" }}>
        <span
          className="__cf_email__"
          data-cfemail="44373134342b363004302d34342d2a23342b2d2a306a253434"
        >
          [email&nbsp;protected]
        </span>
      </span>
    </a>
    <span style={{ fontSize: "10pt" }}>
      . We will do our best to address your concerns. If you feel that your
      concerns have been addressed incompletely, we invite you to let us know
      for further investigation.
    </span>
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={6}
    className="awlist68"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Notice<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    We may provide any notice to you under this Agreement using commercially
    reasonable means, including using public communication channels. Notices we
    provide by using public communication channels will be effective upon
    posting.
  </p>
  <p className="MFParasubclause1" style={{ fontSize: "10pt" }}>
    &nbsp;
  </p>
  <ol
    start={7}
    className="awlist69"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Waiver<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "11pt"
    }}
  >
    Any waiver or failure to enforce any provision of the Agreement on one
    occasion will not be deemed a waiver of any other provision or of such
    provision on any other occasion.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={8}
    className="awlist70"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Severability<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    If any portion of this Agreement is held invalid or unenforceable, that
    portion shall be construed in a manner to reflect, as nearly as possible,
    the original intention of the parties, and the remaining portions shall
    remain in full force and effect.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={9}
    className="awlist71"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "16.54pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Export Control<span style={{ fontWeight: "normal" }}>.</span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    <strong>&nbsp;</strong>
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    You may not use, export, import, or transfer Services except as authorized
    by the laws of the jurisdiction in which you obtained Services, and any
    other applicable laws.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <ol
    start={10}
    className="awlist72"
    style={{ margin: "0pt", paddingLeft: "0pt" }}
  >
    <li
      className="MFParasubclause1"
      style={{
        fontSize: "10pt",
        fontWeight: "bold",
        listStylePosition: "inside"
      }}
    >
      <span
        style={{
          width: "10.98pt",
          font: '7pt "Times New Roman"',
          display: "inline-block"
        }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
      </span>
      Entire Agreement<span style={{ fontWeight: "normal" }}>. </span>
    </li>
  </ol>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  <p
    className="MFParasubclause1"
    style={{
      marginLeft: "50.4pt",
      textIndent: "0pt",
      textAlign: "justify",
      fontSize: "10pt"
    }}
  >
    The Agreement is the final, complete and exclusive agreement of the parties
    with respect to the subject matter hereof and supersedes and merges all
    prior discussions between the parties with respect to such subject matter.
  </p>
  <p
    className="MFParasubclause1"
    style={{ marginLeft: "50.4pt", textIndent: "0pt", fontSize: "10pt" }}
  >
    &nbsp;
  </p>
  
</div>

        </>
    )

}