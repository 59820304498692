import * as React from 'react'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Collapse } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import MenuItem from '@mui/material/MenuItem'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { convertIntTo2DecimalFloat } from '../../utils/utils'

export default function OptInList(props) {
  const { selectedEvent } = props
  const [expanded, setExpanded] = React.useState(false)

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button onClick={() => setExpanded(!expanded)}>
          {`${expanded ? 'Collapse' : 'Expand'} Guest List`}
        </button>
      </div>
      <Collapse in={expanded}>
        <OptInTable userToEvents={selectedEvent?.userToEvents} />
      </Collapse>
    </div>
  )
}

function OptInTable(props) {
  const { userToEvents } = props
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Opted-In</TableCell>
            <TableCell>Guest Address</TableCell>
            <TableCell>Allowance Transaction</TableCell>
            <TableCell align="right">Transfer Transaction</TableCell>
            <TableCell align="right">Contribution Amount</TableCell>
            <TableCell align="right">Paid Out</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userToEvents
            ?.filter((userToEvent) => userToEvent.isCreator !== true)
            .map((userToEvent) => (
              <TableRow
                key={userToEvent.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  {userToEvent.optedIn ? (
                    <Tooltip title="Invitee Opted-In" placement="top">
                      <CheckIcon style={{ color: 'green', fontSize: '1rem' }} />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Invitee Opted-Out" placement="top">
                      <CloseIcon style={{ color: 'red', fontSize: '1rem' }} />
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>
                  {userToEvent.user.ethAddress.substring(0, 9)}...{' '}
                  <ContentCopyIcon
                    fontSize="small"
                    onClick={() =>
                      navigator.clipboard.writeText(userToEvent.user.ethAddress)
                    }
                    style={{ cursor: 'pointer', '&:active': { color: 'blue' } }}
                  />
                </TableCell>
                <TableCell align="right">
                  {userToEvent?.allowanceSignatureTransactionHash ? (
                    <>
                      <a
                        href={`https://base-sepolia.blockscout.com/tx/${userToEvent?.allowanceSignatureTransactionHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {userToEvent?.allowanceSignatureTransactionHash?.substring(
                          0,
                          12
                        )}
                      </a>{' '}
                      ...{' '}
                      <ContentCopyIcon
                        fontSize="small"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            userToEvent?.allowanceSignatureTransactionHash
                          )
                        }
                        style={{
                          cursor: 'pointer',
                          '&:active': { color: 'blue' },
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell align="right">
                  {userToEvent?.transferTransactionHash ? (
                    <>
                      <a
                        href={`https://base-sepolia.blockscout.com/tx/${userToEvent?.transferTransactionHash}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {userToEvent?.transferTransactionHash?.substring(0, 12)}
                        ...
                      </a>{' '}
                      <ContentCopyIcon
                        fontSize="small"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            userToEvent?.transferTransactionHash
                          )
                        }
                        style={{ cursor: 'pointer', color: 'blue' }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </TableCell>
                <TableCell align="right">
                  {userToEvent.currentContribution
                    ? `$${convertIntTo2DecimalFloat(
                        userToEvent.currentContribution
                      )} USDC`
                    : ''}
                </TableCell>
                <TableCell align="right">
                  {userToEvent?.transferTransactionHash ? (
                    <CheckIcon style={{ color: 'green', fontSize: '1rem' }} />
                  ) : (
                    <CloseIcon style={{ color: 'red', fontSize: '1rem' }} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <LongMenu />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function LongMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const options = [{ value: 'Kick-out', onClickFunc: () => {} }]
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            selected={option === 'Pyxis'}
            onClick={() => {
              option.onClickFunc()
              handleClose()
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
