import { axiosInstance } from '../services/webClient'
import { BACKEND_URL, EVENT_TYPES, USDC_DECIMALS } from '../utils/utils'

async function submitRawTxBackend(rawTx) {
  console.log('submitRawTxBackend entrry', rawTx)
  await axiosInstance
    .post('/web3/submitRawTx', {
      rawTx,
    })
    .then(async function (response) {
      console.log('submitRawTxBackend success', response)
      return response
    })
    .catch(async function (err) {
      console.log('submitRawTxBackend error', err)
    })
  console.log('submitRawTxBackend exit', rawTx)
}

export async function fetchAllowanceTransactionData(
  inviteeAddress,
  allowanceAmount,
  deadline
) {
  try {
    const res = await fetch(BACKEND_URL + '/web3/allowanceTransaction', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        inviteeAddr: inviteeAddress,
        allowanceAmount: allowanceAmount,
        deadline: deadline,
      }),
      credentials: 'include',
    })
    if (!res.ok) {
      const { error } = await res.json()
      throw new Error(error)
    }
    const { allowanceTransactionData, allowanceDeadlineTs } = await res.json()
    console.log('fetchAllowanceTransactionData', {
      allowanceTransactionData,
      allowanceDeadlineTs,
    })
    return { allowanceTransactionData, allowanceDeadlineTs }
  } catch (err) {
    if (err.message === 'Insufficient USDC tokens') {
      throw new Error(err.message)
    }
    console.error('fetchAllowanceTransactionData error', err)
  }
  
}

export async function allowanceImmediately({
  inviteeAddr,
  allowanceSignature,
  allowanceAmount,
  deadlineAsDate,
}) {
  const body = {
    inviteeAddr,
    allowanceSignature,
    allowanceAmount,
    deadlineAsDate,
  }
  return axiosInstance
    .post(`/test/permit`, { ...body })
    .then((res) => {
      console.log('allowanceImmediately', { res })
    })
    .catch((err) => {
      console.log(err)
    })
}

export async function optIn(
  optedIn,
  currentEthAddress,
  eventId,
  destinationAddressSignature,
  expectedContributionAmount,
  autoOptOutTarget
) {
  const body = {
    optedIn: optedIn,
    ethAddress: currentEthAddress,
    eventId: eventId,
    destinationAddressSignature,
    expectedContributionAmount,
    autoOptOutTarget,
  }
  console.log('optIn eventId', eventId)
  return await fetch(BACKEND_URL + `/event/${eventId}/optin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({...body}),
    credentials: 'include',
  })
  .then(async (res) => {
    const updatedEvent = await res.json()
    const ute = updatedEvent.userToEvents.find(
      (ute) => ute.user.ethAddress === currentEthAddress
    )
    console.log('optIn: res:', { ute })
    return ute
  }) 
}

async function fetchSetDestinationAddressTransactionData(
  inviteeAddress,
  destinationAddress,
  amount,
  eventId
) {
  return await axiosInstance
    .post('/web3/destinationAddressTransaction', {
      inviteeAddr: inviteeAddress,
      destinationAddr: destinationAddress,
      amount: amount,
      eventId: eventId,
    })
    .then((res) => {
      console.log('saveUserDecision: res:', { res })
      return res.data.transactionData.destinationTransactionData
    })
    .catch((err) => {
      console.log(err)
    })
}

async function sendDestinationHashToTpContract(signature, message) {
  const { from, to, amount, eventId } = message
  console.log('sendDestinationHashToTpContract entry', {
    signature,
    message,
  })
  const rec = await axiosInstance
    .post('/web3/sendDestinationAddressApproval', {
      signature: signature,
      message: {
        from,
        to,
        amount,
        eventId,
      },
    })
    .then((res) => {
      console.log('sendDestinationHashToTpContract: res:', { res })
      return res.data.transactionData
    })
    .catch((err) => {
      console.error('sendDestinationHashToTpContract err', { err })
    })
  return rec
}

async function fetchSigningTransactionData(signingData) {
  // const{
  //   inviteeAddress,
  //   creatorAddress,
  //   contributionAmount,
  //   eventId,
  //   deadline
  // } = signingData
  // validate SigningData before calling backend
  console.log('fetchSigningTransactionData entry', { signingData })
  const rec = await fetch(BACKEND_URL + '/web3/fetchSigningTransactionData', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(signingData),
    credentials: 'include',
  })
    .then(async (res) => {
      const { transactionData } = await res.json()
      return transactionData
    })
    .catch((err) => {
      console.error('sendDestinationHashToTpContract err', err)
      if (
        err.response.status === 403 &&
        err.response.data.error === 'Insufficient USDC tokens'
      ) {
        throw new Error(err.response.data.error)
      } else {
        throw new Error(err.message)
      }
    })
  return rec
}

const allowanceSigPromise = (
  permitUsdcAllowanceTransactionData,
  walletProvider,  
) => {
  return new Promise(async (resolve, reject) => {
    if (permitUsdcAllowanceTransactionData) {
      console.log('permitUsdcAllowanceTransactionData attempt 1.1', {
        walletProvider,
        permitUsdcAllowanceTransactionData,
      })

      const packaged = {
        method: 'eth_signTypedData_v4',
        params: [
          String(permitUsdcAllowanceTransactionData.params[0]),
          JSON.stringify(permitUsdcAllowanceTransactionData.params[1]),
        ],
      }
      console.log('permitUsdcAllowanceTransactionData attempt 1.2', packaged)
      let signatureAllowanceUSDC
      try {
        signatureAllowanceUSDC = await walletProvider.request(packaged)
      } catch (error) {
        console.error('signatureAllowanceUSDC error', JSON.stringify(error))
        reject(new Error(error.message))
      }
      resolve(signatureAllowanceUSDC)
    } else {
      resolve()
    }
  })
}

const destinationSigPromise = (
  setDestinationAddressTransactionData,
  walletProvider
) => {
  return new Promise(async (resolve, reject) => {
    if (setDestinationAddressTransactionData) {
      console.log(
        'setDestinationAddressTransactionData attempt 1',
        setDestinationAddressTransactionData.params[0]
      )
      const packaged = {
        method: 'eth_signTypedData_v4',
        params: [
          String(setDestinationAddressTransactionData.params[0]),
          JSON.stringify(setDestinationAddressTransactionData.params[1]),
        ],
      }
      const signatureSetDestinationAddress = await walletProvider.request(
        packaged
      )
      resolve(signatureSetDestinationAddress)
    } else {
      resolve()
    }
  })
}

const executeAllowanceTransaction = async (
  allowanceSignature,
  allowanceAmount,
  allowanceDeadlineTs,
) => {
  console.log('executeAllowanceTransaction', JSON.stringify({
    allowanceSignature,
    allowanceAmount,
    allowanceDeadlineTs,
    now: new Date().getTime(),
  }))
  return await fetch(BACKEND_URL + '/web3/executeAllowanceTransaction', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      allowanceSignature,
      allowanceAmount,
      allowanceDeadlineTs,
    }),
    credentials: 'include',  
  })
    .then(async (res) => {
      const { allowanceTX } = await res.json()
      console.log('executeAllowanceTransaction: res:', { allowanceTX })
      return allowanceTX
    })
    .catch((err) => {
      console.log(err)
    })
}

export async function completeAllowanceRequest(
  inviteeAddress,
  allowanceAmount,
  deadline,
  walletProvider
) {

  // fetch allowance transaction data
  const {allowanceTransactionData, allowanceDeadlineTs} = await fetchAllowanceTransactionData(
    inviteeAddress,
    allowanceAmount,
    deadline
  )
  console.log('completeAllowanceRequest', {
    allowanceTransactionData,
    allowanceDeadlineTs,
  })
  // request signature from wallet
  const signatureAllowanceUSDC = await allowanceSigPromise(
    allowanceTransactionData,
    walletProvider
  )
  console.log('completeAllowanceRequest signing', {
    signatureAllowanceUSDC,
  })

  // execute transaction
  const allowanceTX = await executeAllowanceTransaction(
    signatureAllowanceUSDC,
    allowanceAmount,
    allowanceDeadlineTs
  )

  return {signatureAllowanceUSDC, allowanceTX}
}

export async function completeTransactionRequest(
  inviteeAddress,
  destinationAddress,
  contributionAmount,
  autoOptOutTarget,
  eventId,
  deadline,
  walletProvider
) {


  // fetch transaction data
  const { destinationTransactionData } = await fetchSigningTransactionData({
    inviteeAddress,
    destinationAddress,
    contributionAmount: autoOptOutTarget ? autoOptOutTarget : contributionAmount, // if user sets a budgeting option, treat this as their contribution amount
    eventId,
    deadline
  })

  console.log('completeTransactionRequest', {
    destinationTransactionData,
  })

  // request transaction data
  const transactionSignature = await destinationSigPromise(
    destinationTransactionData,
    walletProvider
  )

  // opt-in user
  const updatedUte = await optIn(
    true,
    inviteeAddress,
    eventId,
    transactionSignature,
    contributionAmount,
    autoOptOutTarget
  )

  if (!updatedUte) {
    throw new Error('Failed to opt-in user')
  }
  return updatedUte
}

export async function saveInviteeUserDecision(
  inviteeAddress,
  contributionAmount,
  autoOptOutTarget,
  deadline,
  creatorAddress,
  optedIn,
  eventId,
  walletProvider,
  setProgress
) {
  let signatureAllowanceUSDC
  let signatureSetDestinationAddress
  if (optedIn) {
    try {
      const {
        destinationTransactionData: setDestinationAddressTransactionData,
        allowanceTransactionData: permitUsdcAllowanceTransactionData,
      } = await fetchSigningTransactionData({
        inviteeAddress,
        destinationAddress: creatorAddress,
        contributionAmount: autoOptOutTarget
          ? Number(autoOptOutTarget) * USDC_DECIMALS
          : Number(contributionAmount) * USDC_DECIMALS,
        eventId,
        deadline,
      })
      
      setProgress('signing-started')

      const allowanceSigPromise = (permitUsdcAllowanceTransactionData) => {
        return new Promise(async (resolve, reject) => {
          if (permitUsdcAllowanceTransactionData) {
            console.log('permitUsdcAllowanceTransactionData attempt 1.1', {
              walletProvider,
              permitUsdcAllowanceTransactionData,
            })

            const packaged = {
              method: 'eth_signTypedData_v4',
              params: [
                String(permitUsdcAllowanceTransactionData.params[0]),
                JSON.stringify(permitUsdcAllowanceTransactionData.params[1]),
              ],
            }
            try {
              signatureAllowanceUSDC = await walletProvider.request(packaged)
            } catch (error) {
              console.error('signatureAllowanceUSDC error', JSON.stringify(error))
              setProgress(null)
              reject(new Error(error.message))
            }
            resolve(signatureAllowanceUSDC)
          } else {
            resolve()
          }
        })
      }
      
      const destinationSigPromise = (setDestinationAddressTransactionData) => {
        return new Promise(async (resolve, reject) => {
          if (setDestinationAddressTransactionData) {
            console.log(
              'setDestinationAddressTransactionData attempt 1',
              setDestinationAddressTransactionData.params[0]
            )
            const packaged = {
              method: 'eth_signTypedData_v4',
              params: [
                String(setDestinationAddressTransactionData.params[0]),
                JSON.stringify(setDestinationAddressTransactionData.params[1]),
              ],
            }
            signatureSetDestinationAddress = await walletProvider.request(
              packaged
            )
            resolve(signatureSetDestinationAddress)
          } else {
            resolve()
          }
        })
      }

      signatureAllowanceUSDC = await allowanceSigPromise(
        permitUsdcAllowanceTransactionData
      )
      await new Promise((resolve) => setTimeout(resolve, 1000))
      signatureSetDestinationAddress = await destinationSigPromise(
        setDestinationAddressTransactionData
      )

      console.log('saveInviteeUserDecision done signing eventId', {
        eventId,
        signatureAllowanceUSDC,
        signatureSetDestinationAddress,
      })
    } catch (error) {
      console.error('saveInviteeUserDecision error', JSON.stringify(error))
      setProgress(null)
      throw new Error(error.message)
    }
  }

  setProgress('signing-done')

  let optedInResult = await optIn(
    optedIn,
    inviteeAddress,
    eventId,
    signatureAllowanceUSDC,
    signatureSetDestinationAddress,
    optedIn ? Number(contributionAmount) : undefined,
    autoOptOutTarget ? Number(autoOptOutTarget) : -1
  )
  setProgress('opt-done')
  return optedInResult
}

export {
  fetchSigningTransactionData,
  submitRawTxBackend,
  fetchSetDestinationAddressTransactionData,
  sendDestinationHashToTpContract,
}
