import {
    createSIWEConfig,
    formatMessage,
  } from '@web3modal/siwe'
import { BACKEND_URL } from '../utils/utils';


// call the server to get a nonce
 const getNonce = async () => {
    const res = await fetch(BACKEND_URL + '/nonce', {
        method: "GET",
        credentials: 'include',
      });
    if (!res.ok) {
        throw new Error('Network response was not ok');
    }
    const nonce = await res.text();
    console.log('Nonce:', nonce);
    return nonce;
}
  
// call the server to verify the message
 const verifyMessage = async ({ message, signature }) => {
    try {
        const response = await fetch(BACKEND_URL + '/verify', {
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ message, signature }),
            credentials: 'include'
        });

        if (!response.ok) {
            return false;
        }
        const result = await response.json();
        console.log('Result', result);
        return result === true;
      } catch (error) {
        return false;
      }
}

// call the server to get the session
 const getSession = async () => {
   const res = await fetch(BACKEND_URL + '/session', {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: 'include',
  });

  if (!res.ok) {
      throw new Error('Network response was not ok');
  }

  const data = await res.json()
  if (data && data.address) {
    onValidSessionFetchCallback(data)
    return data
  } else {
    onInvalidSessionFetchCallback()
    return null
  }
}

// call the server to sign out
export const signOut =  async () => {
  console.log('calling backend to sign out');
  const res = await fetch(BACKEND_URL + '/signout', {
   method: "GET",
   credentials: 'include',
 });
  if (!res.ok) {
      throw new Error('Network response was not ok');
  }
 
  const data = await res.json();
  return data == "{}";
} 

let onValidSessionFetchCallback = () => {}

export const onValidSessionFetch = (callback) => {
  onValidSessionFetchCallback = callback;
}

let onInvalidSessionFetchCallback = () => {}

export const onInvalidSessionFetch = (callback) => {
  onInvalidSessionFetchCallback = callback;
}

export const createSIWE = (chains) => {
    return createSIWEConfig({
      signOutOnAccountChange: true,
      signOutOnDisconnect: true,
      signOutOnNetworkChange: true,
              getMessageParams: async () => ({
              domain: window.location.host,
              uri: window.location.origin, 
              chains,
              statement: 'Please sign with your account',
            }),
        createMessage: ({ address, ...args }) => {
          return formatMessage(args, address)
        },
        getNonce,
        getSession,
        verifyMessage,
        signOut,

    })
}