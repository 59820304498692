import * as React from 'react'
import PropTypes from 'prop-types'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import CreateEventContainer from './CreateEventsForm'
import ActiveEventsTable from './ActiveEventsTable'
import EventHistoryTable from './EventHIstoryTable'
import Settings from './Settings'
import { useLocation, useNavigate, Route, Routes } from 'react-router-dom'
import { EventPage } from './EventPage'
import { PastEventPage } from './PastEventPage'
import Admin from '../components/Admin'

import { HomePage } from './HomePage'
import { BottomNavBar } from './BottomNavBar'
import { SuccessfulEvent } from './SuccessfulEvent'
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

const EventsTabTable = (props) => {
  const { currentEthAddress, userInfo, setCurrentUser, onOptInSign } = props

  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
    location.state = newValue
    navigate(newValue)
  }

  let location = useLocation()
  const navigate = useNavigate()

  return (
    <Box sx={{ paddingTop: '70px', width: '100%', borderColor: 'black' }}>
      <>
        <Routes location={location}>
          <Route
            path="/"
            element={<HomePage currentEthAddress={currentEthAddress} />}
          />
          <Route
            path="/create"
            element={
              <CreateEventContainer currentEthAddress={currentEthAddress} />
            }
          />
          <Route
            path="/active"
            element={
              <ActiveEventsTable
                currentEthAddress={currentEthAddress}
                userInfo={userInfo}
              />
            }
          />
          <Route
            path="/inactive"
            element={
              <EventHistoryTable currentEthAddress={currentEthAddress} />
            }
          />
          <Route
            path="/settings"
            element={
              <Settings userInfo={userInfo} setCurrentUser={setCurrentUser} />
            }
          />
          <Route path="/successfulEvent" element={<SuccessfulEvent />} />
          <Route
            path="/campaign/:eventId"
            element={
              <EventPage
                currentEthAddress={currentEthAddress}
                userInfo={userInfo}
              />
            }
          />
          <Route
            path="/pastCampaign/:eventId"
            element={<PastEventPage currentEthAddress={currentEthAddress} />}
          />
          <Route path="/admin" element={<Admin />} />
        </Routes>
      </>

      {location.pathname !== '/' && <BottomNavBar />}
    </Box>
  )
}

export { EventsTabTable as EventsTabTableWrapper }
