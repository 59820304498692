import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import {
  EVENT_TYPES,
  USDC_DECIMALS,
  convertIntTo2DecimalFloat,
  generateCampaignLink,
} from '../../utils/utils'
import ShareIcon from '@mui/icons-material/Share'
import { LinearProgressWithLabel } from './progressBar'
import * as React from 'react'
import OptInList from './OptInList'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Grid from '@mui/material/Grid'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import styles from '../../styles/ConnectWallet.module.css'
import Button from '@mui/material/Button'
import * as web3 from '../../web3/web3'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { axiosInstance } from '../../services/webClient'
import * as eventService from '../../services/eventService'
import Tooltip from '@mui/material/Tooltip'
// import { useWeb3ModalProvider } from '@web3modal/wagmi/react'
import Box from '@mui/material/Box'
import { FormHelperText } from '@mui/material'

export const EventInfo = (props) => {
  const {
    selectedEvent,
    optedIn,
    currentEthAddress,
    setOptedIn,
    updateSelectedEvent,
  } = props
  // const { walletProvider } = useWeb3ModalProvider()

  const [eventInputs, setEventInputs] = React.useState({})
  const [enableSave, setEnableSave] = React.useState(false)
  const [eventHasTipped, setEventHasTipped] = React.useState(false)
  const [eventHasPaidOut, setEventHasPaidOut] = React.useState(false)
  const [expectedContributionAmount, setExpectedContributionAmount] =
    React.useState(null)

  const isCreator = (event) => {
    const isCreator = event?.creatorEthAddress === currentEthAddress
    return isCreator
  }

  React.useEffect(() => {}, [])

  React.useEffect(() => {
    setEventInputs(selectedEvent)
    const fetchEventTippedStatus = async () => {
      const hasEventTipped = await eventService.hasEventTipped(
        selectedEvent?.id
      )
      setEventHasTipped(hasEventTipped)
    }
    const fetchEventPaidOutStatus = async () => {
      const hasEventPaidOut = await eventService.hasEventPaidOut(
        selectedEvent?.id
      )
      setEventHasPaidOut(hasEventPaidOut)
    }

    fetchEventTippedStatus()
    fetchEventPaidOutStatus()
  }, [selectedEvent])

  async function creatorUpdateEvent() {
    await axiosInstance
      .put(`/event/editEvent`, {
        event: {
          ...eventInputs,
          userToEvents: undefined,
          expectedContributionAmount: undefined,
          goalAmount: eventInputs.goalAmount
            ? eventInputs.goalAmount // lets keep the numbers as 6-decimal integers / USDC_DECIMALS
            : undefined,
          staticPricePerPerson: eventInputs.staticPricePerPerson
            ? eventInputs.staticPricePerPerson //lets keep the numbers as 6-decimal integers / USDC_DECIMALS
            : undefined,
        },
      })
      .then(async function (response) {
        const { event } = response.data
        console.log('updateEvent response', { event, response })
        updateSelectedEvent(event)
        setEnableSave(false)
      })
      .catch(function (error) {
        console.log('updateEvent', error)
      })
  }

  async function saveInviteeUserDecision(
    inviteeAddress,
    contributionAmount,
    deadline,
    creatorAddress
  ) {
    let signatureAllowanceUSDC
    let signatureSetDestinationAddress

    let walletProvider

    if (optedIn) {
      if (
        (eventInputs?.eventType === EVENT_TYPES.DYNAMIC ||
          eventInputs?.eventType === EVENT_TYPES.STATIC) &&
        !contributionLimitReached()
      ) {
        throw new Error('Contribution Amount not enough.')
      }

      const {
        destinationTransactionData: setDestinationAddressTransactionData,
        allowanceTransactionData: permitUsdcAllowanceTransactionData,
      } = await web3.fetchSigningTransactionData({
        inviteeAddress,
        destinationAddress: creatorAddress,
        contributionAmount: contributionAmount * USDC_DECIMALS,
        eventId: selectedEvent.id,
        deadline,
      })
      console.log('micke mouse', {
        setDestinationAddressTransactionData,
        permitUsdcAllowanceTransactionData,
        walletProvider,
      })
      if (setDestinationAddressTransactionData) {
        console.log('setDestinationAddressTransactionData attempt 1')
        signatureSetDestinationAddress = await walletProvider.request(
          setDestinationAddressTransactionData
        )
      }
      if (permitUsdcAllowanceTransactionData) {
        console.log('permitUsdcAllowanceTransactionData attempt 1.1', {
          walletProvider,
          permitUsdcAllowanceTransactionData
        })
        signatureAllowanceUSDC = await walletProvider.request(
          permitUsdcAllowanceTransactionData
        )
      }
    }

    let optedInResult = await web3.optIn(
      optedIn,
      inviteeAddress,
      selectedEvent.id,
      signatureAllowanceUSDC,
      signatureSetDestinationAddress,
      optedIn ? contributionAmount : undefined
    )
    if (optedInResult !== null && optedInResult !== undefined) {
      setOptedIn(optedInResult)
      setEnableSave(!enableSave)
      console.log('Saved user decision')
    }
  }

  const updateSetEvents = (key, evt) => {
    //by the creator
    const value = evt.target.value
    setEventInputs((eventInputs) => ({ ...eventInputs, [key]: value }))
    if (!enableSave) setEnableSave(true)
  }

  const tipEvent = async (eventId) => {
    await axiosInstance
      .post(`event/${eventId}/tipEvent`, { eventId })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const refundEvent = async (eventId) => {
    await axiosInstance
      .post(`/event/refundEvent`, {
        event: {
          id: eventId,
        },
      })
      .then(async function (response) {
        const { event } = response.data
        console.log('refundEvent', { event, response })
        updateSelectedEvent(event)
      })
      .catch(function (error) {
        console.log('refundEvent', error)
      })
  }

  const calcuateDynamicPricePerPersonAsDollarAmount = () => {
    const numInviteesOptedIn = selectedEvent?.userToEvents.reduce(
      (acc, userToEvent) => {
        if (userToEvent.optedIn === true && userToEvent.isCreator === false) {
          return acc + 1
        }
        return acc
      },
      0
    )
    const totalInvitees = Math.max(
      selectedEvent.minimumPeople,
      numInviteesOptedIn
    )
    return convertIntTo2DecimalFloat(selectedEvent.goalAmount / totalInvitees)
  }
  const contributionLimitReached = () => {
    return (
      expectedContributionAmount * USDC_DECIMALS >=
      selectedEvent?.expectedContributionAmount.max
    )
  }

  return (
    <Grid
      container
      sx={{
        width: '700px',
      }}
    >
      <Grid item>
        {isCreator(selectedEvent) ? (
          <h3 style={{ color: 'red' }}>You are the creator.</h3>
        ) : (
          <h3 style={{ color: 'red' }}>You are invited.</h3>
        )}
        <Stack spacing={2} direction="column">
          <TextField
            id="event-name"
            label="Campaign Name"
            variant="outlined"
            value={eventInputs?.name}
            InputProps={{
              readOnly: isCreator(selectedEvent) ? false : true,
            }}
            // disabled={isCreator(selectedEvent) ? false : true}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(evt) => updateSetEvents('name', evt)}
          />
          <TextField
            id="event-description"
            label="Description"
            variant="outlined"
            value={eventInputs?.description}
            InputProps={{
              readOnly: isCreator(selectedEvent) ? false : true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(evt) => updateSetEvents('description', evt)}
          />
          {/* TODO: moose - update inputs to show proper event type fields when event type is changed by creator */}
          <FormControl fullWidth>
            <InputLabel id="event-type-select-label" shrink={true}>
              Event Type
            </InputLabel>
            <Select
              labelId="event-type-select"
              id="event-type"
              value={eventInputs?.eventType || null}
              label="Event Type"
              disabled={isCreator(selectedEvent) ? false : true}
              onChange={(evt) => updateSetEvents('eventType', evt)}
            >
              <MenuItem value={EVENT_TYPES.DYNAMIC}>Dynamic</MenuItem>
              <MenuItem value={EVENT_TYPES.STATIC}>Static</MenuItem>
              <MenuItem value={EVENT_TYPES.GENERAL_FUNDRAISING}>
                General Fundraising
              </MenuItem>
              <MenuItem value={EVENT_TYPES.SPECIFIC_FUNDRAISING}>
                Specific Fundraising
              </MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="event-creator-address"
            label="Creator address"
            variant="outlined"
            value={selectedEvent?.creatorEthAddress}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {eventInputs?.eventType === EVENT_TYPES.DYNAMIC && (
            <>
              <TextField
                id="minimum-people"
                label="Minimum People"
                variant="outlined"
                value={eventInputs?.minimumPeople}
                InputProps={{
                  readOnly: isCreator(selectedEvent) ? false : true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required={true}
                onChange={(evt) => updateSetEvents('minimumPeople', evt)}
              />
              <TextField
                id="maximum-people"
                label="Maximum People"
                variant="outlined"
                value={eventInputs?.maximumPeople}
                InputProps={{
                  readOnly: isCreator(selectedEvent) ? false : true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) => updateSetEvents('maximumPeople', evt)}
                required={false}
              />
              <TextField
                id="event-currency-goal"
                label="Goal Amount"
                variant="outlined"
                value={
                  eventInputs?.goalAmount
                    ? convertIntTo2DecimalFloat(eventInputs.goalAmount)
                    : ''
                }
                InputProps={{
                  readOnly: isCreator(selectedEvent) ? false : true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) => updateSetEvents('goalAmount', evt)}
                required={true}
              />
            </>
          )}
          {eventInputs?.eventType === EVENT_TYPES.STATIC && (
            <>
              <TextField
                id="minimum-people"
                label="Minimum People"
                variant="outlined"
                value={eventInputs?.minimumPeople}
                InputProps={{
                  readOnly: isCreator(selectedEvent) ? false : true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                required={true}
                onChange={(evt) => updateSetEvents('minimumPeople', evt)}
              />
              <TextField
                id="maximum-people"
                label="Maximum People"
                variant="outlined"
                value={eventInputs?.maximumPeople}
                InputProps={{
                  readOnly: isCreator(selectedEvent) ? false : true,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(evt) => updateSetEvents('maximumPeople', evt)}
                required={false}
              />
            </>
          )}
          {eventInputs?.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING && (
            <TextField
              id="event-currency-goal"
              label="Goal Amount"
              variant="outlined"
              value={eventInputs?.goalAmount}
              InputProps={{
                readOnly: isCreator(selectedEvent) ? false : true,
              }}
              onChange={(evt) => updateSetEvents('goalAmount', evt)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {selectedEvent?.imgUrl && (
            <Box
              component="img"
              sx={{
                height: 233,
                width: '100%',
                maxHeight: { xs: 233, md: 167 },
                maxWidth: { xs: 350, md: 250 },
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              src={selectedEvent.imgUrl}
            />
          )}
          <DatePicker
            value={dayjs(eventInputs?.deadline)}
            labelId="deadline-label"
            id="deadline"
            label="Deadline"
            disablePast
            // InputProps={{
            //   readOnly: isCreator(selectedEvent) ? false : true,
            // }}
            disabled={isCreator(selectedEvent) ? false : true}
            onChange={(date) => {
              setEventInputs((eventInputs) => ({
                ...eventInputs,
                deadline: date,
              }))
              if (!enableSave) setEnableSave(true)
            }}
          />
          <TextField
            id="event-url"
            label="url"
            variant="outlined"
            value={generateCampaignLink(selectedEvent?.id)}
            InputProps={{
              readOnly: true,
              startAdornment: (
                <InputAdornment position="start">
                  <ShareIcon
                    sx={{
                      ':hover': {
                        bgcolor: 'primary.main', // theme.palette.primary.main
                        color: 'white',
                        borderRadius: '4px',
                        padding: '1.5px',
                      },
                      ':active': {
                        bgcolor: 'primary.light', // theme.palette.primary.main
                        color: 'white',
                        borderRadius: '4px',
                        padding: '1.5px',
                        fontSize: '1.75rem',
                      },
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(
                        generateCampaignLink(selectedEvent?.id)
                      )
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
          <LinearProgressWithLabel selectedEvent={selectedEvent} value={0} />{' '}
          {!isCreator(selectedEvent) ? (
            <>
              <FormControlLabel
                control={
                  <Switch
                    onChange={(evt) => {
                      setOptedIn(evt.target.checked)
                      setEnableSave(!enableSave)
                    }}
                    value={optedIn}
                    checked={optedIn}
                  />
                }
                label="Opt-in?"
              />
              <FormControl fullWidth sx={{ m: 1 }}>
                <InputLabel htmlFor="outlined-adornment-amount">
                  Contribution Amount
                </InputLabel>
                <OutlinedInput
                  error={
                    eventInputs?.eventType === EVENT_TYPES.DYNAMIC ||
                    eventInputs?.eventType === EVENT_TYPES.STATIC
                      ? !contributionLimitReached()
                      : false
                  }
                  id="expected-contribution-ammount"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  label="Expected Contribution Amount"
                  value={expectedContributionAmount}
                  type="number"
                  onChange={(evt) => {
                    setExpectedContributionAmount(evt.target.value)
                  }}
                />
                {(eventInputs?.eventType === EVENT_TYPES.DYNAMIC ||
                  eventInputs?.eventType === EVENT_TYPES.STATIC) && (
                  <FormHelperText>{`Please enter at least $ $${convertIntTo2DecimalFloat(
                    selectedEvent?.expectedContributionAmount.max
                  )}`}</FormHelperText>
                )}
              </FormControl>
            </>
          ) : (
            <></>
          )}
          {isCreator(selectedEvent) ? (
            <>
              {selectedEvent.active && (
                <Button
                  onClick={() => {
                    creatorUpdateEvent()
                  }}
                  className={styles['connect-wallet']}
                  disabled={!enableSave}
                  variant="contained"
                >
                  Save
                </Button>
              )}
              <Tooltip title="Tip the event manually" placement="top">
                <Button
                  onClick={() => {
                    tipEvent(selectedEvent?.id)
                  }}
                  className={styles['connect-wallet']}
                  disabled={!eventHasTipped}
                  variant="contained"
                >
                  Tip event
                </Button>
              </Tooltip>

              {
                <Button
                  onClick={async () => {
                    const eventCallback = (_event) => {
                      updateSelectedEvent(_event)
                      setEnableSave(false)
                    }
                    await eventService.cancelEvent(
                      selectedEvent.id,
                      eventInputs.creatorEthAddress,
                      eventCallback
                    )
                  }}
                  className={styles['connect-wallet']}
                  disabled={!selectedEvent.active}
                  variant="contained"
                >
                  Cancel Event
                </Button>
              }
              <Button
                onClick={() => {
                  refundEvent()
                }}
                className={styles['connect-wallet']}
                disabled={!eventHasPaidOut}
                variant="contained"
              >
                Refund Event
              </Button>
            </>
          ) : (
            <Button
              onClick={() =>
                saveInviteeUserDecision(
                  currentEthAddress,
                  expectedContributionAmount ??
                    selectedEvent?.expectedContributionAmount,
                  selectedEvent?.deadline,
                  selectedEvent?.creatorEthAddress
                )
              }
              className={styles['connect-wallet']}
              disabled={!enableSave}
              variant="contained"
            >
              Save
            </Button>
          )}
        </Stack>
      </Grid>
      <OptInList selectedEvent={selectedEvent} />
    </Grid>
  )
}
