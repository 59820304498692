import { Stack } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useEffect, useState } from 'react'
import { generateCampaignLink } from '../../utils/utils'
import styles from '../../styles/ActiveEventComponent.module.css'

export const FormattedEventUrl = (props) => {
  const { event, containerClassName } = props

  const [clipboardOpen, setClipboardOpen] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  const getDisplayUrl = () => {
    if (width > 1199) {
      return generateCampaignLink(
        event.id,
        new Date(event.deadline) < new Date()
      )
    } else if (width > 899) {
      const eventLink = generateCampaignLink(
        event.id,
        new Date(event.deadline) < new Date()
      )
      if (eventLink.length < 70) {
        return eventLink
      } else {
        return eventLink.substring(0, 70) + '...'
      }
    } else {
      return (
        generateCampaignLink(
          event.id,
          new Date(event.deadline) < new Date()
        ).substring(0, 30) + '...'
      )
    }
  }

  return (
    <>
      <Stack direction={'row'} className={`${containerClassName}`}>
        <div className={styles.EventLinkLeftContent}>
          <span className={styles.EventLinkLabel}>Invite Link:</span>
          <div className={styles.EventLinkText}>{getDisplayUrl()}</div>
        </div>

        <div className={styles.EventLinkRightContent}>
          <ContentCopyIcon
            className={styles.EventLinkCopyIcon}
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigator.clipboard.writeText(
                generateCampaignLink(
                  event.id,
                  new Date(event.deadline) < new Date()
                )
              )
              setClipboardOpen(true)
              setTimeout(() => {
                setClipboardOpen(false)
              }, 4000)
            }}
          />
          {clipboardOpen && (
            <div className={styles.EventLinkCopyText}>Copied to clipboard</div>
          )}
        </div>
      </Stack>
    </>
  )
}
