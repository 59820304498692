import { useLocation, useNavigate } from 'react-router-dom'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Stack from '@mui/material/Stack'
import {
  FacebookShareButton,
  FacebookIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  XIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import { generateCampaignLink } from '../utils/utils'

export const SuccessfulEvent = (props) => {
  const location = useLocation()
  const navigate = useNavigate()

  const searchParams = new URLSearchParams(location.search)
  const eventId = searchParams.get('eventId')
  const eventLink = generateCampaignLink(eventId)
  return (
    <>
      <div
        style={{
          color: 'rgba(193, 254, 175, 1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <TaskAltIcon
          style={{
            width: '80px',
            height: '80px',
            top: '8px',
            left: '8px',
          }}
        />
        <h1
          style={{
            color: 'rgba(255, 255, 255, 1)',
          }}
        >
          Campaign Created!
        </h1>

        <div
          style={{
            color: 'rgba(133, 133, 133, 1)',
            fontSize: '16px',
            fontWeight: '500',
            lineHeight: '19.2px',
            textAlign: 'center',
          }}
        >
          Share Campaign:
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '25px',

            alignItems: 'center',
            width: '328px',
            height: '56px',
            padding: '15px 11.63px 16px 20px',
            borderRadius: '8px',
            backgroundColor: 'rgba(26, 26, 26, 1)',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.15px',
          }}
        >
          <span id="event-created-span">
            https://tippingpoint.app/campaign/{eventId}
          </span>{' '}
          <ContentCopyIcon
            fontSize="small"
            sx={{
              cursor: 'pointer',
              '&:active': {
                color: 'blue',
                fontSize: 'calc(1em + 7.5px)',
              },
            }}
            onClick={() => navigator.clipboard.writeText(eventLink)}
          />
        </div>
        <button
          style={{
            width: '250px',
            height: '45px',
            padding: '13px 40px',
            borderRadius: '10px',
            backgroundColor: 'rgba(190, 254, 166, 1)',
            color: 'rgba(1, 20, 2, 1)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => navigate('/active')}
        >
          View My Active Campaigns
        </button>
        <Stack
          direction="row"
          spacing={1}
          style={{
            margin: '10px 0px',
          }}
        >
          <TwitterShareButton
            url={eventLink}
            title="Check out my Campaign on Tipping Point!"
          >
            <XIcon size={32} round />
          </TwitterShareButton>
          <FacebookShareButton
            url={eventLink}
            quote="Check out my Campaign on Tipping Point!"
          >
            <FacebookIcon size={32} round />
          </FacebookShareButton>
          <WhatsappShareButton
            url={eventLink}
            title="Check out my Campaign on Tipping Point!"
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
          <TelegramShareButton
            url={eventLink}
            title="Check out my Campaign on Tipping Point!"
          >
            <TelegramIcon size={32} round />
          </TelegramShareButton>
          <EmailShareButton
            url={eventLink}
            subject="Check out my Campaign on Tipping Point!"
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </Stack>
        {/* <div
          style={{
            marginTop: '35px',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
          }}
        >
          <FacebookIcon
            style={{
              color: 'rgb(24, 119, 242)',
              fontSize: '2rem',
              marginRight: '7.5px',
            }}
          />
          <XIcon
            style={{
              backgroundColor: 'white',
              color: '#000000',
              fontSize: '2rem',
              marginRight: '7.5px',
            }}
          />
          <WhatsAppIcon
            style={{ color: '#128C7E', fontSize: '2rem', marginRight: '7.5px' }}
          />
          <MailIcon
            style={{ color: 'grey', fontSize: '2rem', marginRight: '7.5px' }}
          />
          <TelegramIcon
            style={{ color: '#0088cc', fontSize: '2rem', marginRight: '7.5px' }}
          />
        </div> */}
      </div>
    </>
  )
}
