import * as React from 'react'

import { NotificationFeedPopover } from '@knocklabs/react'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { Badge, MenuItem, Typography } from '@mui/material'

export const KnockNotificationIcon = (props) => {
  const { notiCount } = props
  const [isVisible, setIsVisible] = React.useState(false)
  const notifButtonRef = React.useRef(null)

  return (
    <MenuItem ref={notifButtonRef} onClick={(e) => setIsVisible(!isVisible)}>
      {/* <Typography textAlign="center"> */}
        <Badge badgeContent={notiCount} color="secondary">
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',

            }}
          >
            Notifications <NotificationsIcon sx={{ fontSize: '1.25rem' }} />
            <NotificationFeedPopover
              buttonRef={notifButtonRef}
              isVisible={isVisible}
              onClose={() => setIsVisible(false)}
            />
          </div>
        </Badge>
      {/* </Typography> */}
    </MenuItem>
  )
}
