import * as React from 'react'
import { InputAdornment, Modal, Stack, TextField, Tooltip } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ImageIcon from '@mui/icons-material/Image'
import { Button } from '@mui/material'
import { EVENT_TYPES, getPrettyDateString } from '../utils/utils'
import CloseIcon from '@mui/icons-material/Close';
import styles from '../styles/ActiveEventComponent.module.css'
import {
  DynamicEventProgressBar,
  EventAttendingList,
  EventDetailsComponent,
  EventGoalProgressBar,
  EventHeaderComponent,
} from './EventPage'
import { FormattedEventUrl } from './subcomponents/FormattedEventUrl'
import * as eventService from '../services/eventService'

export const PublicCampaignPage = () => {
  const [selectedEvent, setSelectedEvent] = React.useState(null)
  const [openOptInModal, setOpenOptInModal] = React.useState(false)

  React.useEffect(() => {
    let eventId = window.location.pathname.split('/').pop()
    getPublicEvent(eventId)
  }, [])

  async function getPublicEvent(eventId) {
    await eventService
      .getPublicEvent(eventId)
      .then((event) => {
        setSelectedEvent(event)
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          setSelectedEvent(undefined)
        }
      })
  }

  // not dry
  const renderCreatorName = (event) => {
    if (!event) return ''
    const creator = event.userToEvents.find(
      (userToEvent) => userToEvent.isCreator
    )
    return creator
      ? `${creator.user.displayName} (${creator.user.ethAddress})`
      : ''
  }

  // not dry
  const getAttendingList = () => {
    return selectedEvent.userToEvents
      .filter(
        (userToEvent) =>
          userToEvent.optedIn ||
          (selectedEvent.isCreatorAttendee && userToEvent.isCreator)
      )
      .map((userToEvent) => ({
        ...userToEvent.user,
        ...userToEvent,
      }))
  }


  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {selectedEvent && (
        <>
          <Stack
            direction={'column'}
            spacing={{ xs: 2 }}
            alignItems={{ xs: 'center' }}
            className={styles.EventContainer}
          >
            <EventHeaderComponent
              event={selectedEvent}
              switchDisabled={true}
              switchOptedIn={false}
              switchHidden={true}
              switchOnClick={() => {}}
            />
            {/* Event Image */}
            <div
              className={styles.EventImageContainer}
              style={!selectedEvent.file ? { width: '80%' } : {}}
            >
              {selectedEvent.file ? (
                <img
                  src={selectedEvent.imgUrl}
                  alt={selectedEvent.name}
                  className={styles.EventImage}
                />
              ) : (
                <ImageIcon className={styles.EventImageIcon} />
              )}
              {selectedEvent.youtubeLink ? (
                <>
                  <iframe
                    width="560"
                    height="315"
                    src={
                      'https://www.youtube.com/embed/' +
                      selectedEvent.youtubeLink
                    }
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                  ></iframe>
                </>
              ) : (
                <></>
              )}
            </div>
            <FormattedEventUrl
              event={selectedEvent}
              containerClassName={styles.EventLinkContainer}
            />
            <Stack
              direction={'column'}
              className={styles.EventDescriptionContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventDescriptionText}>Creator</div>
              <TextField
                className={styles.CreatorNameTextField}
                disabled={true}
                value={renderCreatorName(selectedEvent)}
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            {(selectedEvent.eventType === EVENT_TYPES.DYNAMIC ||
              selectedEvent.eventType === EVENT_TYPES.STATIC) && (
              <DynamicEventProgressBar event={selectedEvent} />
            )}
            {(selectedEvent.eventType === EVENT_TYPES.SPECIFIC_FUNDRAISING ||
              (selectedEvent.eventType === EVENT_TYPES.GENERAL_FUNDRAISING &&
                selectedEvent.goalAmount)) && (
              <EventGoalProgressBar event={selectedEvent} />
            )}
            {getAttendingList().length > 0 && (
              <EventAttendingList attendees={getAttendingList()} />
            )}
            <Stack
              direction={'column'}
              className={styles.EventNameContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventNameText}>Campaign Name</div>

              {/* <input
                className={
                  editingActive ? styles.EventNameTextFieldEditing : styles.EventNameTextField
                }
                value={selectedEvent.name}
                disabled={!editingActive}
                onClick={() => {
                  if (editingActive) {
                    console.log('editing event name')
                    setEditingEvent('name')
                    setEditingValue(selectedEvent.name)
                  }
                }}
                
              /> */}
              <TextField
                className={styles.EventNameTextField}
                value={selectedEvent.name}
                disabled={true}
                onClick={() => {}}
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            <Stack
              direction={'column'}
              className={styles.EventDescriptionContainer}
              spacing={{ xs: 1 }}
            >
              <div className={styles.EventDescriptionText}>Description</div>
              <TextField
                className={styles.EventDescriptionTextField}
                disabled={true}
                value={selectedEvent.description}
                onClick={() => {}}
                multiline
                sx={{
                  '& .MuiInputBase-input': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&:hover fieldset': {
                      borderColor: '#a2a3a9',
                      borderWidth: '3px',
                    },
                    '&.Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                    '&:hover .Mui-disabled fieldset': {
                      borderColor: '#1a1a1a',
                      borderWidth: '1px',
                    },
                  },
                }}
              />
            </Stack>
            <EventDetailsComponent event={selectedEvent} />
            {/* <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventPreferredCurrencyContainer}
            >
              <div className={styles.EventDetailsText}>
                {'Preferred Currency'}
              </div>
              <TextField
                // className={styles.EventPreferredCurrencyTextField}
                disabled={true}
                variant="outlined"
                value={'USDC'}
                // disabling the text field causes styles to be overridden
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {<AttachMoneyIcon className={styles.EventDetailsIcon} />}
                    </InputAdornment>
                  ),
                }}
              />
            </Stack> */}
            <Stack
              direction={'column'}
              spacing={{ xs: 1 }}
              className={styles.EventDeadlineContainer}
            >
              <div className={styles.EventDeadlineText}>Deadline</div>
              <TextField
                // className={styles.EventDeadlineTextField}
                disabled={true}
                value={getPrettyDateString(new Date(selectedEvent.deadline))}
                sx={{
                  '& .MuiInputBase-input.Mui-disabled': {
                    '-webkit-text-fill-color': '#a2a3a9',
                  },
                  '& .MuiInputBase-root.Mui-disabled': {
                    border: '1px solid #202020',
                    borderRadius: '5px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarMonthIcon className={styles.EventDetailsIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            </Stack>
            <Tooltip title="Must sign in to opt in" arrow>
              <Button
                className={styles.EventOptInButton}
                onClick={() => {
                  setOpenOptInModal(true)
                }}
              >
                Opt-In
              </Button>
            </Tooltip>
          </Stack>
          <Modal open={openOptInModal} onClose={() => setOpenOptInModal(false)}>
            <Stack
              direction={'column'}
              style={{
                border: '1px solid #a2a3a9',
                width: '250px',
                height: 'auto',
                backgroundColor: '#080808',
                position: 'relative',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                alignItems: 'center',
                borderRadius: '10px',
                padding: '10px',
              }}
              spacing={2}
            >
              <div 
              style={{fontSize: '25px'}}
              className={styles.EditEventHeader}>Sign in required</div>
              <CloseIcon
                onClick={() => setOpenOptInModal(false)}
                style={{
                  cursor: 'pointer',
                  color: 'white',
                  position: 'absolute',
                  right: '10px',
                  top: '-8px',
                }}
              />
              <Stack
                spacing={6}
                direction="row"
                style={{
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <w3m-connect-button
                  onClick={() => {
                    setOpenOptInModal(false)
                    console.log('clicked')
                  }}
                />
              </Stack>
            </Stack>
          </Modal>
        </>
      )}
    </div>
  )
}
