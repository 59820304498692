import * as React from 'react'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { axiosInstance } from '../services/webClient'
import Stack from '@mui/material/Stack'
import {
  Divider,
  Select,
  MenuItem,
  Snackbar,
  Alert,
  Checkbox,
  Input,
} from '@mui/material'
import styles from '../styles/SetupUser.module.css'
import EmailIcon from '@mui/icons-material/Email'
import HelpIcon from '@mui/icons-material/Help'
import { useNavigate } from 'react-router-dom'
import { TermsOfUseDocx } from './subcomponents/TermsOfUseDocx'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Close } from '@mui/icons-material'
import { BACKEND_URL } from '../utils/utils'
import Tooltip from '@mui/material/Tooltip'

const PhoneNumberAdornment = () => {
  const [countryCode, setCountryCode] = React.useState('US')

  return (
    <Stack direction={{ xs: 'row' }} spacing={{ xs: 1 }}>
      <Select
        value={countryCode}
        onChange={(evt) => setCountryCode(evt.target.value)}
        sx={{
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '0px 1px 0px 0px !important',
            borderRadius: '0px !important',
          },
          '& .MuiSelect-select': {
            '-webkit-text-fill-color': '#829aff !important',
            // border: '1px solid #a2a3a9 !important',
            margin: '0 !important',
          },
          '& .MuiSelect-icon': {
            color: '#a2a3a9 !important',
          },
          'input::placeholder': {
            color: '#cac4d0 !important',
            opacity: 1,
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#a2a3a9',
            },
          },
        }}
      >
        <MenuItem value={'US'}>US</MenuItem>
      </Select>
    </Stack>
  )
}

const uploadimgFileToS3 = async (presignedUrl, imgFile) => {
  fetch(presignedUrl, {
    method: 'PUT',
    mode: 'cors',
    body: imgFile,
  })
    .then(async (response) => {
      const res = await response.text()
      console.log('uploadimgFileToS3 response', res)
    })
    .catch((error) => {
      console.error('uploadimgFileToS3 error', error)
    })
}

export const SetupUser = (props) => {
  const { setCurrentUser, ethAddress } = props
  const [userInputs, setUserInputs] = React.useState({})
  const [snackbarOpen, setSnackbarOpen] = React.useState(false)
  const [displayNameError, setDisplayNameError] = React.useState(null)
  const [termsOfUse, setTermsOfUse] = React.useState(false)
  const [termsOfUseError, setTermsOfUseError] = React.useState(null)
  const [profilePicFile, setProfilePicFile] = React.useState(null)
  const [submitUserError, setSubmitUserError] = React.useState(null)
  const [snackbarError, setSnackbarError] = React.useState(false)

  const navigate = useNavigate()

  const updateUserInputs = (key, evt) => {
    setUserInputs((userInputs) => ({
      ...userInputs,
      ...{ [key]: evt.target.value },
    }))
  }

  const submitUser = async (userInputs) => {
    if (!userInputs?.displayName) {
      setUserInputs({
        ...userInputs,
        displayName: '',
      })
      setDisplayNameError('Display name is required')
      setSnackbarError(true)
      setSnackbarOpen(true)
      return
    }
    if (userInputs?.displayName.length > 30) {
      setDisplayNameError('Display name must be 30 characters or less')
      setSnackbarError(true)
      setSnackbarOpen(true)
      return
    }
    if (!termsOfUse) {
      setTermsOfUseError('You must agree to the Terms of Use')
      setSnackbarError(true)
      setSnackbarOpen(true)
      return
    }
    console.log('submitUser enter', { userInputs, ethAddress })
    await fetch(`${BACKEND_URL}/user/createUser`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: userInputs.email,
        phoneNumber: userInputs.phoneNumber,
        displayName: userInputs.displayName,
        ethAddress: ethAddress,
        profilePic: profilePicFile ? true : false,
      }),
    })
      .then(async function (response) {
        const res = await response.json()
        console.log('submitUser res', res)
        const savedUser = res.user
        if (res.presignedUrl) {
          uploadimgFileToS3(res.presignedUrl, profilePicFile)
        }
        console.log('savedUser', savedUser)
        setCurrentUser({ ...savedUser })
      })
      .catch(function (error) {
        console.log('submitUser err', error)
        setSubmitUserError('Error submitting form')
        setSnackbarError(true)
        setSnackbarOpen(true)
      })

    console.log('submitUser exit')
  }

  return (
    <Stack direction={{ xs: 'column' }} spacing={{ xs: 2 }}>
      <div className={styles.SetupUserHeader}>Create Your Account</div>
      <Divider className={styles.SetupUserDivider} />
      <Stack direction={{ xs: 'column' }} className={styles.SetupUserStack}>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={styles.SetupUserInfo}
        >
          <div className={styles.SetupUserInfoLabel}>Select a Display Name</div>
          <TextField
            required
            placeholder={'Display Name *'}
            error={displayNameError ? true : false}
            helperText={displayNameError}
            value={userInputs?.displayName}
            onChange={(evt) => {
              if (evt.target.value === '') {
                setDisplayNameError('Display name is required')
              } else if (evt.target.value.length > 30) {
                setDisplayNameError(
                  'Display name must be 30 characters or less'
                )
              } else {
                setDisplayNameError(null)
              }

              updateUserInputs('displayName', evt)
            }}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
                '&.Mui-error fieldset': {
                  borderColor: '#d32f2f',
                },
              },
            }}
          />
        </Stack>
      </Stack>
      <Stack
        direction={{ xs: 'column' }}
        spacing={{ xs: 2 }}
        className={styles.SetupUserInfo}
      >
        <div className={styles.SetupUserInfoLabel}>
          Upload a Profile Picture
        </div>
        <Stack
          direction={'column'}
          spacing={3}
          alignItems={'center'}
          alignSelf={'flex-start'}
        >
          <div className={styles.SetupUserIconContainer}>
            {profilePicFile ? (
              <div>
                <Close
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '100%',
                    color: '#f1f1f1',
                    cursor: 'pointer',
                  }}
                  onClick={() => setProfilePicFile(null)}
                />
                <img
                  src={URL.createObjectURL(profilePicFile)}
                  className={styles.SetupUserIcon}
                />
              </div>
            ) : (
              <AccountCircleIcon className={styles.SetupUserIconPlaceholder} />
            )}
          </div>
          <Button
            variant="contained"
            component="label"
            style={{
              textTransform: 'capitalize',
              height: '40px',
              backgroundColor: '#c1feaf',
              color: '#2e2e2e',
            }}
            onChange={(evt) => {
              if (evt.target.files.length > 0) {
                setProfilePicFile(evt.target.files[0])
              }
            }}
          >
            Upload Image
            <input type="file" accept="image/*" hidden />
          </Button>
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'column' }} className={styles.SetupUserStack}>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={styles.SetupUserInfo}
        >
          <div className={styles.SetupUserInfoLabel}>Email</div>
          <TextField
            placeholder={'Email'}
            value={userInputs?.email}
            onChange={(evt) => updateUserInputs('email', evt)}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
                marginLeft: '10px',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
              },
            }}
            InputProps={{
              startAdornment: <EmailIcon style={{ color: '#a2a3a9' }} />,
              endAdornment: (
                <Tooltip
                  title="Email only used for notification purposes, not required to sign up"
                  arrow
                >
                  <HelpIcon
                    style={{
                      color: '#a2a3a9',
                      position: 'relative',
                      left: '3px',
                      scale: '.75',
                      cursor: 'help',
                    }}
                  />
                </Tooltip>
              ),
            }}
          />
        </Stack>
      </Stack>
      <Stack direction={{ xs: 'column' }} className={styles.SetupUserStack}>
        <Stack
          direction={{ xs: 'column' }}
          spacing={{ xs: 1 }}
          className={styles.SetupUserInfo}
        >
          <div className={styles.SetupUserInfoLabel}>Phone Number</div>
          <TextField
            placeholder={'+1 (555) 000-0000'}
            value={userInputs?.phoneNumber}
            onChange={(evt) => updateUserInputs('phoneNumber', evt)}
            sx={{
              '& .MuiInputBase-root': {
                color: '#a2a3a9',
                padding: '0 !important',
              },
              '& .MuiInputBase-input': {
                '-webkit-text-fill-color': '#a2a3a9 !important',
                // border: '1px solid #a2a3a9 !important',
                marginLeft: '10px',
              },
              'input::placeholder': {
                color: '#cac4d0 !important',
                opacity: 1,
              },
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: '#a2a3a9',
                },
              },
            }}
            InputProps={{
              startAdornment: <PhoneNumberAdornment />,
              endAdornment: (
                <Tooltip
                  title="Phone number only used for notification purposes, not required to sign up"
                  arrow
                >
                  <HelpIcon
                    style={{
                      color: '#a2a3a9',
                      marginRight: '10px',
                      scale: '.75',
                      cursor: 'help',
                    }}
                  />
                </Tooltip>
              ),
            }}
          />
        </Stack>
      </Stack>
      <div className={styles.SetupUserHeader}>Terms of Use</div>
      <Divider className={styles.SetupUserDivider} />
      <Stack
        direction={'column'}
        spacing={{ xs: 1 }}
        alignItems={'flex-start'}
        maxWidth={'100%'}
      >
        {/* <div
          style={{
            color: '#a2a3a9',
            fontSize: '18px'
          }}
        >
          Click <span
            style={{ color: '#c1feaf', cursor: 'pointer' }}
            onClick={() => navigate('/termsofuse')}
          >here</span> to view the Tipping Point Terms of Use.
        </div> */}
        <div
          style={{
            maxHeight: '250px',
            backgroundColor: '#ffffff',
            overflowY: 'scroll',
            maxWidth: '100%',
            padding: '0px',
            borderRadius: '10px',
          }}
        >
          <TermsOfUseDocx />
        </div>
        <Stack
          direction={{ xs: 'row' }}
          spacing={{ xs: 1 }}
          alignItems={'center'}
        >
          <Checkbox
            style={{
              color: termsOfUseError ? 'red' : '#c1feaf',
            }}
            checked={termsOfUse}
            onClick={() => {
              if (termsOfUseError) {
                setTermsOfUseError(null)
              }
              setTermsOfUse(!termsOfUse)
            }}
          />
          <div
            style={{
              color: '#a2a3a9',
              fontSize: '18px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (termsOfUseError) {
                setTermsOfUseError(null)
              }
              setTermsOfUse(!termsOfUse)
            }}
          >
            I have read and agree to the Tipping Point Terms of Use.
          </div>
        </Stack>
      </Stack>
      <Button
        onClick={() => submitUser(userInputs)}
        variant="contained"
        sx={{
          mt: 1,
          color: 'rgba(46, 46, 46, 1)',
          backgroundColor: 'rgba(193, 254, 175, 1)',
          textTransform: 'capitalize',
          borderRadius: '10px',
          width: '116px',
          height: '45px',
          alignSelf: 'center',
        }}
      >
        SUBMIT
      </Button>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        autoHideDuration={6000}
      >
        <Alert severity={snackbarError ? 'error' : 'success'}>
          {displayNameError
            ? displayNameError
            : termsOfUseError
            ? termsOfUseError
            : submitUserError
            ? submitUserError
            : 'Error submitting form'}
        </Alert>
      </Snackbar>
    </Stack>
  )
}
